.data-table {
    margin: 0px 20px;
    box-shadow: 0 1px 4px rgb(39 45 59 / 20%);
}

.p-datatable-scrollable-header .p-sortable-column {
    background-color: #e6f5ff !important;
    box-shadow: none !important;
}

/* table header row style */
.p-sortable-column {
    padding: 15px 20px !important;
}

/* header row title text style */
.p-column-title {
    border: 1px solid rgba(0, 0, 0, 0);
    color: #1d679e;
    font-family: 'Montserrat-SemiBold';
    font-size: 16px;
    font-weight: 600;
}

/* sorting arrows styles */
.p-sortable-column-icon {
    padding-bottom: 7px;
    margin-left: 0.1rem !important;
}

.pi-sort-amount-down:before,
.pi-sort-alt:before {
    content: url('../../../assets/images/solid_arrow.svg') !important;
}
.pi-sort-amount-up:before,
.pi-sort-amount-up-alt:before {
    content: url('../../../assets/images/solid_arrow.svg') !important;
}

.pi-sort-amount-up,
.pi-sort-amount-up-alt {
    transform: rotate(180deg);
    padding-bottom: 0px;
    margin-bottom: -11px;
}

/* table body scroll style */
.p-datatable-scrollable-header-box {
    margin-right: 0px !important;
}

.p-datatable-scrollable-body {
    overflow-y: overlay !important;
}

.p-datatable-scrollable-body::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

.p-datatable-scrollable-body::-webkit-scrollbar-track {
    background: #f1f1f1;
    margin-left: 400px;
}

.p-datatable-scrollable-body::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #c0c0c0;
}

/* table body styles */
.p-datatable-tbody tr td {
    height: 18px;
    color: #000000;
    font-family: 'Montserrat-Medium';
    font-size: 15px;
    font-weight: 500;
    padding: 11px 20px !important;
    border: 1px solid #e4e4f1 !important;
    border-width: 0 0 1px 0 !important;
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd {
    background-color: #f9fdff;
}

/* paginator styles */
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    width: 35px;
    height: 35px;
    background: #e6f5ff;
    border-color: #e6f5ff;
    color: #000000;
    font-family: 'Montserrat-SemiBold';
    font-size: 16px;
    font-weight: 600;
    min-width: 35px;
}

.p-paginator .p-paginator-pages .p-paginator-page {
    width: 35px;
    height: 35px;
    color: #343434;
    font-family: 'Montserrat-Regular';
    font-size: 16px;
    font-weight: 400;
    min-width: 35px;
}

.p-link:focus {
    box-shadow: none;
}

.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
    width: 35px;
    height: 35px;
    min-width: 35px;
}

.p-datatable .p-datatable-thead > tr > th {
    /*width: 200px;*/
    padding-left: 20px !important;
    text-align: left;
    vertical-align: top !important;
}

.flex-production-dt thead tr th:nth-child(1) {
    width: 130px !important;
}

.flex-production-dt tbody tr td:nth-child(1) {
    width: 130px !important;
}

.p-sticky {
    position: sticky;
}

.p-datatable-scrollable-body > table > .p-datatable-tbody > tr:first-child > td {
    width: 200px;
}

.p-datatable .p-datatable-tbody > tr > td {
    text-align: left;
    overflow-wrap: break-word !important;
}

.p-datatable .p-datatable-loading-overlay {
    left: 0 !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    z-index: 1 !important;
}
.loader-div {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999999 !important;
    left: 0 !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
}

.loader-div-copy {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1099 !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.table-loader-overlay {
    background-color: #000000;
    opacity: 0.05;
    bottom: 0;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
}

@keyframes p-progress-spinner-color {
    100%,
    0% {
        stroke: #1d679d;
    }
    40% {
        stroke: #1d679d;
    }
    66% {
        stroke: #1d679d;
    }
    80%,
    90% {
        stroke: #1d679d;
    }
}

.p-progress-spinner-svg {
    animation-name: p-progress-spinner-color;
}
.p-progress-spinner {
    width: 80px !important;
    height: 80px !important;
}

/* Paginator css */
.p-paginator {
    position: absolute;
    right: 20px;
    bottom: 0px;
    left: 20px;
}

.data-table .p-paginator {
    position: initial;
}

.flex-dt .table,
.flex-production-dt .table {
    margin-bottom: 0;
    /* border: 1px solid #e4e4f1; */
}

.flex-dt,
.flex-production-dt {
    overflow: overlay; /*auto;*/
    /* max-width: calc(100% + 12px); */
    border: 1px solid #e4e4f1;
}

.flex-dt tbody,
.flex-production-dt tbody {
    background-color: #fff;
}

.flex-dt {
    max-height: 200px;
    min-height: 200px;
    background: #fff;
}

.flex-dt .row,
.flex-production-dt .row {
    margin: 0;
}

.flex-dt .row div,
.flex-production-dt .row div {
    padding: 0;
}

.flex-dt .mdb-dataTables_paginate,
.flex-production-dt .mdb-dataTables_paginate {
    display: none;
}

/* .gv-datatables tfoot{
  display:none;
}

[table-foot]{
  display:none;
} */

.flex-dt::-webkit-scrollbar,
.flex-production-dt::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

.flex-dt::-webkit-scrollbar-track,
.flex-production-dt::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.flex-production-dt::-webkit-scrollbar-track {
    margin-left: 315px;
    margin-top: 70px;
}

.flex-dt::-webkit-scrollbar-track {
    margin-top: 50px;
}

.flex-dt::-webkit-scrollbar-thumb,
.flex-production-dt::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #c0c0c0;
}

.flex-dt-th-bg {
    background-color: #e6f5ff;
}

.flex-dt-th-bg tr th {
    vertical-align: middle;
    padding-top: 15px;
    padding-bottom: 15px;
}

.flex-dt .table tbody td,
.flex-production-dt .table tbody td {
    font-family: 'Montserrat-Medium';
    font-size: 15px;
    font-weight: 500;
    color: #000;
    text-align: left;
    padding-left: 21px;
}

.table-hover tbody tr:hover {
    color: initial;
    background-color: transparent;
}

/* .df-home-dt-cell{
  font-family: "Montserrat-Medium";
  font-size: 15px;
  font-weight: 500;
  color: #000;
  text-align: left;
} */

.flex-dt .table thead,
.flex-production-dt .table thead {
    top: -1px;
    position: sticky;
    z-index: 99;
}

.flex-dt .table thead th,
.flex-production-dt .table thead th {
    padding-right: 15px !important;
    padding-left: 20px !important;
}

.flex-production-dt thead tr th:nth-child(1) {
    position: sticky;
    left: 0;
    background-color: #e6f5ff;
    z-index: 9;
}

.flex-production-dt thead tr th:nth-child(2) {
    position: sticky;
    left: 135px;
    background-color: #e6f5ff;
    z-index: 9;
}

.flex-production-detailed-dt thead tr th:nth-child(2) {
    position: sticky;
    left: 130px;
    background-color: #e6f5ff;
    z-index: 9;
}

.flex-production-dt tbody tr td:nth-child(1) {
    position: sticky;
    left: 0;
    /* top: 66px; */
    background-color: #fff;
    /* z-index: 9; */
}

.flex-production-dt tbody tr td:nth-child(2) {
    position: sticky;
    left: 135px;
    /* top: 66px; */
    background-color: #fff;
    /* z-index: 9; */
}

.flex-production-dt tbody tr td:nth-child(2) {
    position: sticky;
    left: 135px;
    background-color: #fff;
}

.flex-production-detailed-dt tbody tr td:nth-child(2) {
    position: sticky;
    left: 130px;
    /* top: 66px; */
    background-color: #fff;
    /* z-index: 9; */
}

.flex-production-dt tbody tr td {
    background-color: #fff;
}

.table td {
    border-bottom: 1px solid #dee2e6;
    border-top: 0px;
}

.table th {
    border-bottom: 1px solid #dee2e6;
    /* border-bottom: 0px; */
}

/* -------------------------------Prime react column fixing css----------------- */
.busi-dic-dt .table {
    margin-bottom: 0;
}

.busi-dic-dt {
    overflow: auto;
    border: 1px solid #e4e4f1;
    position: sticky;
}

.busi-dic-dt .row {
    margin: 0;
}

.busi-dic-dt .row div {
    padding: 0;
}

.busi-dic-dt .mdb-dataTables_paginate {
    display: none;
}

.busi-dic-dt::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

.busi-dic-dt::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.busi-dic-dt::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #c0c0c0;
}

.busi-dic-dt .table tbody td {
    font-family: 'Montserrat-Medium';
    font-size: 15px;
    font-weight: 500;
    color: #000;
    text-align: left;
    padding-left: 21px;
}

.busi-dic-dt .table thead {
    top: -1px;
    position: sticky;
    z-index: 99;
}

.busi-dic-dt .table thead th {
    padding-right: 15px !important;
}

.busi-dic-dt thead tr th:nth-child(1) {
    position: sticky;
    left: 0;
    background-color: #e6f5ff;
    z-index: 9;
}

.busi-dic-dt thead tr th:nth-child(2) {
    position: sticky;
    left: 200px;
    background-color: #e6f5ff;
    z-index: 9;
}

.busi-dic-dt tbody tr td:nth-child(1) {
    position: sticky;
    left: 0;
    background-color: #fff;
    /* z-index: 9; */
}

.busi-dic-dt tbody tr td:nth-child(2) {
    position: sticky;
    left: 200px;
    background-color: #fff;
    /* z-index: 9; */
}
