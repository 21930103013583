.popup-wrapper {
    border-radius: 5px;
    box-shadow: 0px 0px 20px #00000029;
    /* min-width: 17vw;
    max-width: 25vw; */
    height: 28.5vh;
    width: 31vw;
    max-width: 900px;
    max-height: 500px;
    background: #fff;
    min-width: 300px;
}

.up-icon-success {
    top: -50px;
    left: 50%;
    transform: translate(-50%, -10%);
    position: relative;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background: #00cb5d;
    display: flex;
    align-items: center;
    justify-content: center;
}

.up-icon-error {
    top: -50px;
    left: 50%;
    transform: translate(-50%, -10%);
    position: relative;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background: red;
    display: flex;
    align-items: center;
    justify-content: center;
}

.popup-wrapper > .info-stack {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: space-evenly;
    height: 100%;
    top: -30%;
    align-items: center;
}

.popup-wrapper .info-stack > .status-popup {
    color: #707070;
    font: normal normal normal 1.25vw/2vh 'Montserrat-Medium';
}

.popup-wrapper .info-stack > .message-popup {
    color: #707070;
    font: normal normal normal 0.8vw/1.7vh 'Montserrat-Regular';
    text-align: center;
    padding: 10px 20px;
}

.popup-wrapper .info-stack > .btn-success {
    border: none;
    margin: 0 15px 18px 15px;
    border-radius: 3px;
    padding: 5px;
    background: #00cb5d;
    color: #fff;
    width: 6.2vw;
    min-width: 60px;
}

.popup-wrapper .info-stack > .btn-error {
    border: none;
    margin: 0 15px 18px 15px;
    border-radius: 3px;
    padding: 5px;
    background: red;
    color: #fff;
    width: 6.2vw;
    min-width: 60px;
}

.popup-wrapper .info-stack > .btn-warning {
    border: none;
    margin: 0 15px 18px 15px;
    border-radius: 3px;
    padding: 5px;
    background: #ffb300;
    color: #fff;
    width: 6.2vw;
    min-width: 60px;
}

/* 
warning btn color: #ffb300
success btn color: #00cb5d
error btn color: red
*/

.up-icon-warning {
    top: -50px;
    left: 50%;
    transform: translate(-50%, -10%);
    position: relative;
    /* width: 90px;
    height: 90px;
    border-radius: 50%; */
    /* background: #ffb300; */
    display: flex;
    align-items: center;
    justify-content: center;
}

/* //////////////////////////////////////////// */
/* Updated Stylesheet */
/* //////////////////////////////////////////// */
.buttonErSuWa {
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 6px 1.4rem;
    color: #fff;
    font-weight: bold;
    border-radius: 4px;
}

.button-warning {
    background-color: #ffb300;
}

.button-error,
.icon-error {
    background-color: red;
}

.button-success,
.icon-success {
    background-color: #00cb5d;
}

.iconSuWaEr {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -60%);
    height: 7rem;
    width: 7rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.iconSuWaEr img {
    height: 50%;
    width: 50%;
}

/* //////////////////////////////////////////// */
