.preview-tab-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 20px auto;
    height: 30vh;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px #00000029;
}

.preview-label {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 2.6vw;
}

.preview-label div > .label {
    font: normal normal normal 1.04vw/2.5vh 'Montserrat-Medium';
    color: #1d679e;
    margin-top: 2px;
}

.preview-label div .label-img {
    transform: rotate(-90deg);
    margin-right: 8px;
    width: 1vw;
}

.preview-label > div {
    display: flex;
    flex-direction: row;
    transform: rotate(-90deg);
}

.preview-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #f6f7fb;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    overflow: auto;
    padding: 1rem;
    position: relative;
    /* overflow: ; */
}

.preview-container .preview-table-wrap{
    width: 100%;
    overflow-x: auto;
    overflow-y: overlay;
    box-shadow: 0 0 10px #00000029;
}

.preview-container .preview-table-wrap::-webkit-scrollbar{
    height: 5px;
}

.preview-container .preview-table-wrap::-webkit-scrollbar-track{
    background: #f3f3f3;
    border: 0.8px solid #ccc;
    border-radius: 3px;
}
.preview-container .preview-table-wrap::-webkit-scrollbar-thumb{
    border-radius: 10px;
    background: #707070;
}

.preview-container > text {
    font: normal normal normal 1.04vw/2.5vh 'Montserrat-Regular';
    color: #707070;
}

.preview-container .preview-table-wrap > .preview-table {
    background: none;
    border-radius: 0;
    border-collapse: collapse;
    border: 0;
}

.preview-container .preview-table-wrap .preview-table > tbody{
    border: none;
    background: #f6f6f6;
}

.preview-container .preview-table-wrap .preview-table td,
th {
    text-align: left;
    padding-left: 1.5vw;
    padding-right: 1.5vw;
   
}

.preview-container .preview-table tr:first-child {
    border: 0;
}

.preview-container .preview-table-wrap .preview-table th {
    background: #f5fbff;
    font: normal normal normal 1.04vw/2.2vh 'Montserrat-Medium';
    color: #2c333e;
    padding-top: 0.8vh;
    padding-bottom: 0.8vh;
    min-width: 160px;
}

.preview-container .preview-table td {
    padding-top: 1.6vh;
    padding-bottom: 1.6vh;
    font: normal normal normal 1.04vw/2.2vh 'Montserrat-Medium';
    color: #707070;
    min-width: 160px;
    border: 0;
}
