.top-container {
    border: 1px solid #e8e9ee;
    border-radius: 3px;
    
    // margin-top: 12px;
    // height: 100%;
    width: 100%;
    // max-width: 66.5rem;
    // padding: 0 1rem 1rem;
    // padding-bottom: 5rem;
}

.top-container-1 {
    border: 1px solid #e8e9ee;
    border-radius: 3px;

    padding: 1rem;

    width: 100%;
}

.title-label {
    font-size: 17px;
    font-weight: 500;
    font-family: "Montserrat-Medium";
    font-style: normal;
    text-align: initial;
    line-height: 22px;
    padding: 1rem;
}

.limitation-container {
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    // background-color: #bd9696;
    // padding: 2rem;
    padding: 10px;
    text-align: initial;
    margin: 0.5rem;
    padding-bottom: 1rem;
}

.limitation-text {
    font-family: "Montserrat-Medium";
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    margin-left: 10px;
    // margin-bottom: 0;
}

.dot-icon {
    height: 9px;
    width: 9px;
}

.limitation-sub-text {
    font-family: "Montserrat-Regular";
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    margin-left: 10px;
    // margin-bottom: 0px;
}

// .top-container::-webkit-scrollbar {
//     width: 10px;
//     height: 10px;
// }

.top-container::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.top-container::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #c0c0c0;
}
