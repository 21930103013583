.df-overview-container {
    position: relative;
}

.detailed-report-title-bar {
    display: flex;
    flex-direction: row;
    margin: 18px 12px;
    align-items: center;
    justify-content: space-between;
}

.detailed-report-picker-calendar {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 20;
}

.detailed-report-title-bar > text:first-child {
    font: normal normal normal 1.45vw/3.1vh 'Montserrat-SemiBold';
}

.detailed-report-datepicker {
    display: flex;
    flex-direction: row;
    align-items: center;
    box-shadow: 0px 0px 5px #00000029;
    border-radius: 5px;
    height: 3.3vh;
    cursor: pointer;
    width: fit-content;
    text-align: right;
}

.detailed-report-title-bar > .react-datepicker-wrapper {
    width: auto !important;
}

.detailed-report-title-bar > .react-datepicker__tab-loop {
    position: absolute;
    top: 0;
    right: 0;
}

.detailed-report-title-bar .react-datepicker__tab-loop > .react-datepicker-popper {
    position: relative;
    right: 120px;
    width: max-content;
}

.detailed-report-title-bar .react-datepicker-popper .react-datepicker__triangle::before {
    border-bottom-color: #1d679e;
}

.detailed-report-title-bar .react-datepicker-popper .react-datepicker__triangle::after {
    border-bottom-color: #fff;
}

.detailed-report-title-bar .react-datepicker__month {
    margin: 0;
}

.detailed-report-title-bar .react-datepicker__month .react-datepicker__month-text {
    border: 1px solid #1d679e;
    margin: 0;
    border-collapse: collapse;
    border-top: none;
    border-left: none;
    min-height: 1.5rem;
    padding: 5px;
}

.detailed-report-title-bar .react-datepicker__month .react-datepicker__month-text:last-child {
    border-right: none;
}

.detailed-report-title-bar .react-datepicker__month-text--today {
    font-weight: normal;
}

.detailed-report-title-bar .react-datepicker-popper .react-datepicker__header {
    border-bottom: 1px solid #1d679e;
    background: #fff;
}

.detailed-report-title-bar .react-datepicker-popper .react-datepicker__navigation--previous,
.detailed-report-title-bar .react-datepicker-popper .react-datepicker__navigation--next {
    top: 5%;
}

.detailed-report-title-bar .react-datepicker__month--selected {
    border-radius: 0;
    margin: 0;
}

.detailed-report-title-bar .react-datepicker__month-text {
    border-radius: 0;
}

.detailed-report-popper {
    border: 1px solid #1d679e;
    border-bottom: none;
}

.detailed-report-popper-arrow {
    border: 1px solid #1d679e;
}

.detailed-report-picker-text {
    font: normal normal normal 0.8vw/2vh 'Montserrat-Regular';
    padding: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-width: 3.5rem;
    background-color: #fff;
    border-radius: 5px 0 0 5px;
    height: 100%;
}

.detailed-report-calendar-btn {
    background-color: #1d679e;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 1.8vw;
    height: 100%;
    border-radius: 0 5px 5px 0;
}

.detailed-report-status-cards {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 18px 12px;
}

.detailed-report-card-single {
    display: flex;
    flex-direction: column;
    width: 13.03vw;
    height: 11vh;
    background-color: #fff;
    padding: 5px;
    border-radius: 3px;
    box-shadow: 0px 0px 5px #00000029;
    justify-content: space-evenly;
    cursor: pointer;
    position: relative;
    z-index: 20;
}

.detailed-report-card-single-sidebar-open {
    display: flex;
    flex-direction: column;
    width: 11vw;
    height: 15vh;
    background-color: #fff;
    padding: 5px;
    border-radius: 3px;
    box-shadow: 0px 0px 5px #00000029;
    justify-content: space-evenly;
    cursor: pointer;
    position: relative;
    z-index: 20;
}

.detailed-report-card-single-selected {
    display: flex;
    flex-direction: column;
    width: 13.03vw;
    height: 11vh;
    background-color: #f5fbff;
    padding: 5px;
    border-radius: 3px;
    box-shadow: 0px 0px 5px #00000029;
    justify-content: space-evenly;
    border: 1px solid #5199cd;
    cursor: pointer;
    position: relative;
    z-index: 20;
}

.detailed-report-card-single-selected-sidebar-open {
    display: flex;
    flex-direction: column;
    width: 10.5vw;
    height: 15vh;
    background-color: #f5fbff;
    padding: 5px;
    border-radius: 3px;
    box-shadow: 0px 0px 5px #00000029;
    justify-content: space-evenly;
    border: 1px solid #5199cd;
    cursor: pointer;
    position: relative;
    z-index: 20;
}

.file-status-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
}

.file-status-bar > text {
    font: normal normal normal 0.8vw/1.9vh 'Montserrat-Medium';
}

.file-status-bar-numbers {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;
}

.file-status-bar-numbers > text:first-child {
    font: normal normal normal 1.25vw/4vh 'Montserrat-Medium';
}

.file-status-bar-numbers > text:last-child {
    font: normal normal normal 0.8vw/1.9vh 'Montserrat-Medium';
    text-align: left;
}

.selected-kpi-down-arrow {
    position: absolute;
    top: 8vh;
    left: 40%;
    transform: rotate(-45deg);
    width: 20px;
    height: 32px;
    z-index: 2;
    border: 1px solid #5199cd;
    background-color: #f5fbff;
}

.selected-kpi-down-arrow-sidebar-open {
    position: absolute;
    top: 12vh;
    left: 40%;
    transform: rotate(-45deg);
    width: 20px;
    height: 32px;
    z-index: 2;
    border: 1px solid #5199cd;
    background-color: #f5fbff;
}
