.wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.sectionHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
}

.title {
    font-weight: 500;
    font-family: "Montserrat-Medium";
    font-size: 1.12rem;
}

.cardsWrapper {
    margin: 1rem 0;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
}

@media screen and (max-width: 79em) {
    .cardsWrapper {
        grid-template-columns: repeat(3, 1fr);
    }
}

/*----------------- Production Report Current Status ------------------- */

.prodReportCard {
    padding: 0.8rem;
    min-height: 8rem;
    background-color: #fff;
    box-shadow: 0px 2px 8px #00000029;
    border-radius: 3px;
}

.prodReportCardTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    font-family: "Montserrat-SemiBold";
    font-size: 1.1rem;
    margin-bottom: 1rem;
}

.prodReportCardStatus {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
}

.prodReportStatusText {
    font-family: "Montserrat-Regular";
    font-size: 1.1rem;
    color: #000;
    text-align: left;
}

/*----------------- Vendor List ------------------- */
.processed,
.pending {
    display: flex;
    align-items: center;
}

.processedIndicator {
    width: 1.2rem;
    height: 1.2rem;
    background-color: #00cb5d;
    border-radius: 50%;
    border: 2px solid #fff;
    margin-right: 0.5rem;
}

.pendingIndicator {
    width: 1.2rem;
    height: 1.2rem;
    background-color: red;
    border-radius: 50%;
    border: 2px solid #fff;
    margin-right: 0.5rem;
}

.searchVendors {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.vendorCardSearchInput {
    height: 2.2rem;
    border-radius: 3px;
    border: 1px solid #ccc;
    padding: 5px 25px 5px 5px;
    position: relative;
    background-image: url("../../assets/images/search-icon.svg");
    background-size: 20px;
    background-position: right 5px center;
    background-repeat: no-repeat;
    font: normal normal normal 1.04vw/2vh "Montserrat-Regular";
}

.vendorTitle {
    color: #000000;
    font-size: 1.1rem;
    font-family: "Montserrat-SemiBold";
    font-weight: 500;
}

.vendorFileStatus {
    color: #1d679e;
    font-style: normal;
    font-weight: 500;
    font-size: 0.95rem;
    line-height: 17px;
}

.discontinuedTag {
    display: flex;
    padding: 0.25rem 0.5rem;
    font-weight: 600;
    font-size: 0.95rem;
    line-height: 17px;
    gap: 0.5rem;
    border: 2px solid red;
    border-radius: 3px;
    color: red;
}

.countStatus {
    display: flex;
    flex-direction: row;
    gap: 0.2rem;
    align-items: center;
}
/*----------------- Curated --------------------- */
.memberInfo {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.membersTag {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.25rem;
    color: #96999f;
    font-size: 1rem;
    font-weight: 500;
}

.membersCount {
    color: #000;
    text-align: right;
}

/*----------------- Activity --------------------- */

.activityCard {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0.8rem;
    min-height: 8rem;
    background-color: #fff;
    box-shadow: 0px 2px 8px #00000029;
    border-radius: 3px;
}

.activityTrackersBtn{
    padding: 5px 10px;
    color: #1d679e;
    background-color: #ffffff;
    cursor: pointer;
    border: 1px solid #1d679e !important;
    border-radius: 3px;
}

/*----------------- Generic --------------------- */
.flexCol {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.flexRowBetween {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.flexRowBetweenEnd {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
}

.flexRowLeft {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
}

.VL {
    display: inline-block;
    width: 1.2px;
    background-color: #bbb;
    height: 1.5rem;
}

.card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0.8rem;
    min-height: 10rem;
    background-color: #fff;
    box-shadow: 0px 2px 8px #00000029;
    border-radius: 3px;
}
/*------------------------------------------------*/
