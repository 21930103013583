.sub-tab-container {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06);
    background-color: #fff;
    display: flex;
    margin: 10px 0;
}

.sub-tab-view {
    display: flex;
    margin-right: 5px;
    background-color: #ffffff;
    flex-direction: column;
}

.selected-sub-tab-text {
    font-size: 15px;
    font-weight: 600;
    font-family: 'Montserrat-SemiBold';
    color: #343434;
    margin-bottom: 0px;
}

.sub-tab-text {
    font-size: 15px;
    font-weight: 500;
    font-family: 'Montserrat-Medium';
    color: #999999;
    margin-bottom: 0px;
}

.title-view {
    height: 39px;
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
    display: flex;
}

.highlight-tab-view {
    background-color: #5199cd;
    height: 3px;
    margin-left: -15px;
    margin-right: -15px;
}

.kpi-top-container {
    margin: 10px 5px 17px 5px;
}

.kpi-container {
    // height: 60px;
    // box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06);
    // background-color: #ffffff;
    display: grid;
    // text-align: left;
    // justify-content: center;
    // align-items: center;
}

.kpi-title {
    margin-top: 1.4vh;
    margin-left: 0.6vw;
    margin-bottom: 4px;
    color: #000000;
    text-align: left;
    letter-spacing: 0.4px;
    color: #000000;
    opacity: 1;
    height: 30px;
    line-height: 20px;
}

.kpi-value {
    color: #24a520;
    font-family: 'Montserrat-Medium';
    font-size: 1vw;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 7px;
    margin-left: 14px;
}
.view-report-card {
    position: relative;
    text-align: right;
    text-decoration: underline;
    font: 0.9vw Montserrat-Regular;
    letter-spacing: 0.42px;
    color: #1d679e;
    opacity: 1;
    top: 2.3vh;
    right: 0.6vw;
}
.dot {
    height: 10px;
    width: 10px;
    margin-right: 3px;
    border-radius: 50%;
    display: inline-block;
    font: 0.9vw Montserrat-Medium;
}

.kpi-status {
    height: 25px;
    width: 25px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
}
.kpi-inner-view {
    // margin-right: 14px;
    border-radius: 5px;
    border: 1px solid #bbbbbb;
    width: 100%;
    height: 15vh;
    background-color: #ffffff;
    display: grid;
    box-shadow: 0px 0px 10px #00000029;
    text-align: left;
}
.kpi-inner-view:active {
    // margin-right: 14px;
    border-radius: 10px;
    border: 1px solid #5199cd;
    width: 100%;
    height: 15vh;
    background: #fff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 5px #00000029;
    border: 1px solid #5199cd;
    border-radius: 5px;
    opacity: 1;
}

.highlight-kpi-tab-view {
    background-color: #5199cd;
}

.arrow-icon-view {
    display: flex;
    justify-content: center;
    margin-top: -3px;
}

.arrow-icon {
    width: 30px;
    height: 18px;
}

.dropdown-container {
    // margin-top: 10px;
    // margin-bottom: 10px;
    // margin-left: 20px;
    // margin-right: 20px;
    margin: 10px 5px 17px 5px;
    text-align: left;
    display: flex;
    align-items: center;
}

.dropdown-title {
    color: #000000;
    font-family: 'Montserrat-Medium';
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0px;
    margin-right: 15px;
    flex: none;
}

.desc-text {
    color: #000000;
    font-family: 'Montserrat-Regular';
    font-size: 15px;
    font-weight: 400;
}

.vendor-desc-popover {
    background-color: #fff !important;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    padding: 12px 10px;
    max-width: 319px;
}
