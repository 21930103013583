// -----------------------Sidebar Style---------------------------

.main-nav-container {
    background: #2c333e;
    height: 100%;
    display: flex;
    justify-content: center;
    top: 0;
    left: 0;
    transition: width, left, right, 0.5s ease;
    position: relative;
    z-index: 1009;
    overflow-x: hidden;
    overflow-y: scroll;
}
.main-nav-container::-webkit-scrollbar {
    display: none;
}
.nav-toggle-icon {
    background: none;
    cursor: pointer;
    color: #fff;
    text-decoration: none;
    // padding-left: 25px;
    font-size: 2rem;
    padding-top: 27px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.sidebar-div {
    display: block;
    margin-top: 20px;
    align-items: center;
}

.search-btn {
    //width: 200px !important;
    width: 100% !important;
    background: #dee2e6 !important;
    border: 1px solid #e4e4ee !important;
    border-radius: 100px !important;
    color: #6b6b6b !important;
    font-size: 14px !important;
    font-family: 'Montserrat-Medium' !important;
    padding-left: 10px !important;

    // position: absolute !important;
    // right: 130px !important;
    text-align: left !important;
    line-height: 18px !important;
    @media (max-width: 800px) {
        // width: 120px !important;
    }
    span {
        margin-left: 30px !important;
    }
    .search-btn-icon {
        position: absolute;
        width: 15px;
        height: 15px;
        margin: 0px !important;
        align-items: center !important;
    }
}

.close-sidebar-div {
    position: relative;
    display: flex;
    align-items: center;
    padding: 13px 0 13px 20px;
    cursor: pointer;
    &:nth-child(1) {
        margin-right: 10px;
        font-size: 14px;
        width: 35px;
        min-width: 35px;
        height: 33px;
        line-height: 35px;
        text-align: center;
        display: inline-block;
    }
    &:nth-child(2) {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
    }
}
.close-sidebar-div:hover {
    font-weight: 450;
}

#sidebar-title-icon {
    display: flex;
    text-decoration: none;
    color: #fff;
    justify-content: space-between;
    align-items: center;
    padding: 13px;
    list-style: none;
    height: 49px;
    #SVGDoc {
        fill: #fff;
    }
    #SVGDocRS {
        stroke: #fff;
    }
}
//=====Sidebar Ends=====

.sidebar-title-link {
    display: flex;
    width: 100%;
    color: #fff;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    padding: 10px 10px 10px 0;
    list-style: none;
    height: 49px;
    text-decoration: none;
}
.sidebar-title-link:hover {
    text-decoration: none;
    color: #fff;
}
.sidebar-title-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 15px;
    font-family: 'Montserrat-Regular';
    font-weight: 400;
    opacity: 0.9;
    #SVGDoc {
        fill: #fff;
    }
    #SVGDocRS {
        stroke: #fff;
    }
}
#active {
    background: #3D4551;
    cursor: pointer;
    font-family: 'Montserrat-Regular';
    font-weight: 400;
    #SVGDoc {
        // fill: #1d679e;
        fill: #fff;
    }
    #SVGDocRS {
        // stroke: #1d679e;
        stroke: #fff;
    }
    #hoverColor {
        color: #fff;
        font-family: 'Montserrat-Regular';
        font-weight: 400;
        font-size: 15px;
    }
}
#active2 {
    background: #2c333e;
    cursor: pointer;
    font-family: 'Montserrat-Regular';
    font-weight: 400;
    #SVGDoc {
        // fill: #1d679e;
        fill: #fff;
    }
    #SVGDocRS {
        // stroke: #1d679e;
        stroke: #fff;
    }
    #hoverColor {
        color: #fff;
        font-family: 'Montserrat-Regular';
        font-weight: 400;
        font-size: 15px;
    }
}
#hoverColor {
    color: #96999f;
}
#hoverColor:hover{
    color: #fff;
    #SVGDoc {
        // fill: #1d679e;
        fill: #fff;
    }
}
#SVGDoc {
    fill: #96999f;
}
#SVGDocRS {
    stroke: #96999f;
}

#hoverColor:hover + #inactiveIcons{
    #SVGDoc {
        fill: #fff 
    }
}

// #SVGDoc {
//   fill: #96999f;
// }
#closeActive a {
    // background: #3d4551;
    // border-radius: 50%;
    #SVGDoc {
        fill: #ffffff;
    }
    #SVGDocRS {
        stroke: #ffffff;
    }
}
// #hoverlink:hover {
//   background-color: red;
// }
#inactiveIcons a {
    #SVGDoc {
        fill: #96999f;
    }
    #SVGDocRS {
        stroke: #96999f;
    }
    
}
#inactiveIcons:hover {
    #SVGDoc {
    fill : #fff
    }
    #SVGDocRS {
        stroke: #fff;
    }
    #hoverColor {
        color: #ffff;
    }
}
// #inactiveIcons a:hover {  
//     color: #fff !important
// }
#inactiveIcons #hoverColor {
    font-size: 15px;
}
// #inactiveIcons #hoverColor:hover + #hoverColor1 {
//     #SVGDoc {
//         fill: #fff 
//     }
// }
#inactiveIcons #hoverColor:hover {
    #SVGDoc {
        fill: #fff 
    }
}
#closeActive2 a:nth-child(1) {
    background: #3d4551;
    border-radius: 50%;
}
#activeSubNav {
    opacity: 1 !important;
    font-family: 'Montserrat-Medium';
    font-weight: 400;
}

// =====SubNav Button Start========
#subNav-btn {
    display: flex;
    width: auto;
    justify-content: flex-end;
}

.sub-menu2-div {
    // max-height: 30vh;
    // overflow: auto;
    // background: red;
}

.sub-menu2-div::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

.sub-menu2-div::-webkit-scrollbar-track {
    background: #787d86;
}

.sub-menu2-div::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #c1c1c1;
}

.search-box {
    position: relative;
}

.search-box-wrapper {
    height: 2rem;
    margin: 0.8rem 0.8rem 0 0.8rem;
    border: 1px solid #96999f;
    padding: 7px;
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.search-box-wrapper > .search-box-input {
    background-color: transparent;
    border: none;
    width: 100%;
    height: 2rem;
    margin-left: 0.5rem;
    color: #fff;
}

#search-placeholder {
    color: #fff;
    display: none;
}
.search-box:hover #search-placeholder {
    display: flex;
}

.config-arrow2 {
    padding: 15px;
    position: absolute;
    /* background-color: #1d679e; */
    -ms-transform: rotate(45deg); /* IE 9 */
    -ms-transform-origin: 20% 40%; /* IE 9 */
    transform: rotate(45deg);
    transform-origin: 20% 40%;
    right: 145px;
    // top: 30px;
    background: #f5fbff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 5px #00000029;
    border: 2px solid #1d679e;
    opacity: 1;
}

// .triangleBlue {
//     position: absolute;
//     width: 0;
//     height: 0;
//     margin-left: 114px;
//     // top: -1.1rem;
//     // top: -0.375rem;
// }

// .triangleBlue::before,
// .triangleBlue::after {
//     content: '';
//     position: absolute;
//     border-left: 12px solid transparent;
//     border-right: 12px solid transparent;
//     // top: 0.2rem;
//     // left: -0.55rem;
//     // margin-left: -7px;
// }

// .triangleBlue::before {
//     border-bottom: 12px solid #1d679e;
//     margin-top: 1px;
// }

// /* The white fill of the triangle */
// .triangleBlue::after {
//     border-bottom: 12px solid white;
//     margin-top: 1px;
//     // z-index: 1;
// }
// @media only screen and (min-width: 3000px) {
//     .triangleBlue::before {
//         top: 0.38rem; //0.25rem;
//     }
//     .triangleBlue::after {
//         margin-top: 0.2rem; //0.18rem;
//         // z-index: 1;
//     }
// }


.search-placeholder-content{
    color: #000;
    border: 2px solid #1d679e;
    background-color: #fff;
    width: fit-content;
    float: right;
    margin-left: 106px;
    margin-top: 13px;
    position: absolute;
    border-radius: 5px;
    padding: 5px 3px;
    font-size: 13px;
    opacity: 2;
}

.search-occurences {
    // position: relative;
    // top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    width: 300px;
    align-items: center;
    background-color: #fff;
    background-color: rgba(255, 255, 255, 0.05);

    min-height: 1.5rem;
    margin-bottom: 0.8rem;
}

.search-occurences > text {
    color: #fff;
    margin-left: 0.2rem;
}

.search-occurences > span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1rem;
    height: 1rem;
    background-color: #ffd350;
    color: #000;
    border-radius: 3px;
    font-size: 12px;
}

.search-occurences-inline {
    // position: relative;
    // top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1rem;
    align-items: center;
    background-color: #fff;
    background-color: rgba(255, 255, 255, 0.05);
    margin-bottom: 0.8rem;
    margin-right: 5px;
    margin-top: 5px;
}

.search-occurences-inline > span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    background-color: #ffd350;
    color: #000;
    border-radius: 3px;
    font-size: 12px;
}
// =====SubNav Button End========

.highlighter {
    background-color: transparent;
    color: #fff;
    // font-weight: 800;
    font: normal normal normal 15px/21px 'Montserrat-SemiBold';
}

.half-contain {
    margin-left: 2px;
    position: relative;
    // max-height: 20vh;
    // overflow: auto;
}

.half-contain::before {
    content: '';
    position: absolute;
    left: 0;
    top: 8%;
    height: 85%;
    // bottom: 8%;
    // border-left: 1px dotted #96999f;
}

.half-contain-middle {
    margin-left: 17px;
    position: relative;
    // max-height: 30vh;
    // overflow: auto;
}

.half-contain-middle::before {
    content: '';
    position: absolute;
    left: 0;
    top: 6%;
    bottom: 5%;
    height: 100%;
    // border-left: 1px dotted #96999f;
}

.half-contain-last {
    // margin-left: 30px;
    position: relative;
    // max-height: 20vh;
    // overflow: auto;
}

.half-contain-last::before {
    content: '';
    position: absolute;
    left: 0;
    top: 5%;
    bottom: 5%;
    height: 93%;
    // border-left: 1px dotted #96999f;
}

// For file SM3

.neww {
    opacity: 0;
}

.triangle {
    position: relative;
    width: 0;
    height: 0;
    margin-left: 55%;
    top: -1.1rem;
    // top: -0.375rem;
}

// .triangle::before {
//   content: '';
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 0;
//   height: 0;
//   border-left: 0.2rem solid transparent; /* Adjust the border sizes using em units */
//   border-right: 0.2rem solid transparent;
//   border-top: 0.4rem solid red; /* Red triangle */
//   z-index: -1;
// }

// .triangle::after {
//   content: '';
//   position: absolute;
//   top: 0;
//   left: 0.044rem;
//   width: 0;
//   height: 0;
//   // top: -3px;
//   // left: 2.5px;
//   // width: 3px;
//   // height: 0;
//   border-left: 0.15rem solid transparent; /* Adjust the border sizes using em units */
//   border-right: 0.15rem solid transparent;
//   border-top: 0.34rem solid white;
//   z-index: 1;
// }

.triangle::before,
.triangle::after {
    content: '';
    position: absolute;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    top: 0.2rem;
    left: -0.55rem;
    // margin-left: -7px;
}

.triangle::before {
    border-top: 12px solid red;
    margin-top: 0.18rem;
}

/* The white fill of the triangle */
.triangle::after {
    border-top: 12px solid white;
    margin-top: 0.1rem;
    // z-index: 1;
}
@media only screen and (min-width: 3000px) {
    .triangle::before {
        top: 0.38rem; //0.25rem;
    }
    .triangle::after {
        margin-top: 0.2rem; //0.18rem;
        // z-index: 1;
    }
}

.new1 {
    opacity: 1;
}

// .sidebarlink:hover + .sidebarlabel > div{
//     color: #fff !important
// }