html {
    overflow-x: hidden;
}

@media screen and (max-width: 60em) {
    html {
        min-width: 60em;
        overflow-x: scroll;
    }
}

body {
    margin: 0;
    font-family: 'Montserrat-Regular', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Font Family */
@font-face {
    font-family: 'Montserrat-Regular';
    src: local('Montserrat-Regular'), url(assets/fonts/Montserrat-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Montserrat-Bold';
    src: local('Montserrat-Bold'), url(assets/fonts/Montserrat-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Montserrat-Medium';
    src: local('Montserrat-Medium'), url(assets/fonts/Montserrat-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'Montserrat-SemiBold';
    src: local('Montserrat-SemiBold'), url(assets/fonts/Montserrat-SemiBold.ttf) format('truetype');
}

/* //////////////////////////////////////////// */
/* Required for Data Factory Detailed Reports > custom calendar picker */
/* //////////////////////////////////////////// */
.detailed-report-popper {
    border: 1px solid #1d679e;
    border-radius: 2px;
}

.last30Active .react-datepicker__month-text {
    background-color: white !important;
    color: black !important;
}

button[aria-label='Previous Year'] span::before,
button[aria-label='Next Year'] span::before {
    border-color: initial;
    content: '<' !important;
    color: #1d679e !important;
}

button[aria-label='Previous Year']:hover span::before,
button[aria-label='Next Year']:hover span::before {
    border-color: initial;
    content: '<' !important;
    color: #1d519f !important;
}
/* //////////////////////////////////////////// */

/* //////////////////////////////////////////// */
/* Fix for unauth modal issue */
/* //////////////////////////////////////////// */
.loader-div {
    z-index: 100 !important;
}

.disableLoaderNotifier .loader-div {
    display: none !important;
}
/* //////////////////////////////////////////// */

.header-main-parent-div {
    height: 100vh;
}

.df-overview-container {
    margin: 0 !important;
}

.appLevelHeader {
    position: sticky;
    z-index: 999 !important;
}

/* //////////////////////////////////////////// */
/* CSS For Login Page */
/* //////////////////////////////////////////// */
.loginPageWrapper {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.loginPageSection {
    height: 50%;
}

.loginPageBottomSection {
    background: linear-gradient(0deg, #2c333e 0, #72767e);
}

.loginPageConsole {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    padding: 4.2rem 8rem;
    padding-bottom: 2.5rem;
    border-radius: 2.3rem;
    background-color: #fff;
    display: flex;
    flex-direction: column;
}

.loginPageLogo {
    width: 27rem;
    margin-bottom: 3rem;
}

.loginPageUser {
    height: 7.5rem;
    margin-bottom: 3rem;
}

.loginButtonWrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 4rem;
}

.loginButton {
    font-weight: bold;
    padding: 0.5rem 4.5rem;
    border-radius: 500px;
    background-color: #f3faff;
    border: 2px solid #5199cd;
    color: rgb(81, 153, 205);
    letter-spacing: 1px;
    font-size: 1.4rem;
}

.loginPageNoAccountLabel {
    font-weight: bold;
    font-size: 1.1rem;
}

/* //////////////////////////////////////////// */

/* //////////////////////////////////////////// */
/* App Seleton */
/* //////////////////////////////////////////// */
.hideScroll {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.hideScroll::-webkit-scrollbar {
    display: none;
}
/* //////////////////////////////////////////// */

/* //////////////////////////////////////////// */
/* Group Name Update Popup */
/* //////////////////////////////////////////// */
.groupNameUpdateInput::placeholder {
    font-size: 1rem;
}
/* //////////////////////////////////////////// */

/* //////////////////////////////////////////// */
/* Insights Studio Landing - Info Kiosk */
/* //////////////////////////////////////////// */
.infoButtonParent {
    border: none;
    background-color: transparent;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 5px;
    font-family: inherit;
}

.infoButtonIcon {
    position: relative;
}

.infoButtonIcon::before {
    content: 'i';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-radius: 100%;
    height: 100%;
    padding-top: 2px;
    font-family: monospace;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    transform: translateY(-2.5px);
    transition: all 0.3s ease-in-out;
}

.activatedInfoIcon::before {
    transform: translateY(0) !important;
}

.infoButtonIcon::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-radius: 100%;
    height: 100%;
    z-index: 1;
}

.infoButtonParent:hover .infoButtonIcon::before {
    transform: translateY(0);
}

/* Info Button Sizes */
.infoButtonSm {
    /* Font size of label next to i */
    font-size: 0.9rem;
}
.infoButtonSm .infoButtonIcon {
    height: 1.15rem;
    width: 1.15rem;
}
.infoButtonSm .infoButtonicon::before {
    /* Font size of i */
    font-size: 1rem;
}

.infoButtonMd {
    /* Font size of label next to i */
    font-size: 1.2rem;
}
.infoButtonMd .infoButtonIcon {
    height: 1.55rem;
    width: 1.55rem;
}
.infoButtonMd .infoButtonIcon::before {
    /* Font size of i */
    font-size: 1.2rem;
}

/* Info Button Colors */
.infoButtonBlue {
    color: rgb(81, 153, 205);
}
.infoButtonBlue .infoButtonIcon::before {
    background-color: rgb(81, 153, 205);
    color: #fff;
}
.infoButtonBlue .infoButtonIcon::after {
    background-color: rgb(42, 101, 144);
}
.infoButtonRed {
    color: rgb(235, 76, 108);
}
.infoButtonRed .infoButtonIcon::before {
    background-color: rgb(235, 76, 108);
    color: #fff;
}
.infoButtonRed .infoButtonIcon::after {
    background-color: rgb(196, 52, 76);
    color: #fff;
}

/* Info Square Button Next */
.btnBasic {
    border: none;
    cursor: pointer;
    font-size: inherit;
    font-family: inherit;
    background-color: transparent;
}

.infoRectNext {
    position: relative;
    height: 2rem;
    width: 5rem;
}

.infoRectNextTwo {
    position: relative;
    height: 1.6rem;
    width: 10rem;
}

.infoRectNextChild {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    font-size: 1rem;
    /* font-weight: 600; */
    /* font-family: 'Montserrat-SemiBold'; */
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.infoRectNextChildTwo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    font-size: 0.7rem;
    /* font-weight: 600; */
    /* font-family: 'Montserrat-SemiBold'; */
    color: rgb(235, 76, 108);
    display: flex;
    align-items: center;
    justify-content: center;
}

.infoRectText {
    transform: translateY(-0.4rem);
    background-color: rgb(235, 76, 108);
    transition: transform 0.2s ease-in-out;
    z-index: 1;
}

.infoRectTextTwo {
    transform: translateY(-0.2rem);
    border: 1px solid rgb(235, 76, 108);
    background-color: #fff;
    transition: transform 0.2s ease-in-out;
    z-index: 1;
}

.infoRectTextBG {
    background-color: rgb(196, 52, 76);
}

.infoRectTextBGTwo {
    background-color: rgb(196, 52, 76);
}

.infoRectNext:hover .infoRectText {
    transform: translateY(0);
}

.infoRectNextTwo:hover .infoRectTextTwo {
    transform: translateY(0);
}

.infoBorder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    outline: 4px solid white;
    z-index: 1199;
}

.arrow-left {
    padding: 10px;
    position: absolute;
    transform: rotate(45deg);
    left: 2px;
    top: -12px;
    background: #fff 0% 0% no-repeat padding-box;
    opacity: 1;
    border-radius: 4px;
}

.arrow-right {
    padding: 10px;
    position: absolute;
    transform: rotate(45deg);
    right: 2px;
    top: -12px;
    background: #fff 0% 0% no-repeat padding-box;
    opacity: 1;
    border-radius: 4px;
}

.arrow-bottom {
    padding: 10px;
    position: absolute;
    transform: rotate(45deg);
    left: 36px;
    top: 25px;
    background: #fff 0% 0% no-repeat padding-box;
    opacity: 1;
    border-radius: 4px;
}

.arrow-top {
    padding: 10px;
    position: absolute;
    transform: rotate(45deg);
    left: -36px;
    top: -48px;
    background: #fff 0% 0% no-repeat padding-box;
    opacity: 1;
    border-radius: 4px;
}

/* .arrow-left {
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 13px solid #fff;
} */

/* .arrow-right {
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 13px solid #fff;
} */

.leftInfoModal {
    padding: 1rem;
    background-color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(10px, -2rem);
    border-radius: 5px;
    box-shadow: 5px 0 0 rgb(235, 76, 108);
    z-index: 1211;
}

.centerInfoModal {
    padding: 1rem;
    background-color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    box-shadow: 5px 0 0 rgb(235, 76, 108);
    z-index: 1211;
}

.infoModalBodyPara div p {
    margin-bottom: 0.4rem;
}

.rightInfoModal {
    padding: 1rem;
    background-color: #fff;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-10px, -2rem);
    border-radius: 5px;
    box-shadow: -5px 0 0 rgb(235, 76, 108);
    z-index: 1211;
}

.topInfoModal {
    padding: 1rem;
    background-color: #fff;
    position: absolute;
    top: 15px;
    right: 0;
    transform: translate(70px, -21rem);
    border-radius: 5px;
    box-shadow: 5px 0 0 rgb(235, 76, 108);
    z-index: 1211;
}

.bottomInfoModal {
    padding: 1rem;
    background-color: #fff;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(70px, 2rem);
    border-radius: 5px;
    box-shadow: -5px 0 0 rgb(235, 76, 108);
    z-index: 1211;
}

/* //////////////////////////////////////////// */

/* //////////////////////////////////////////// */
/* Nice Loader */
/* //////////////////////////////////////////// */
/* Width and height need to be defined at the location of using this */
.niceLoader {
    border-radius: 50%;
    display: inline-block;
    border-top: 3px solid #1d679e;
    border-right: 3px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
/* //////////////////////////////////////////// */

.grpMgmtSearchInput,
.grpMgmtSearchInput::placeholder {
    font-size: 1rem;
}

.row-selection-checkbox-cpy {
    height: 1rem;
    width: 1rem;
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
}

.customCheckbox {
    height: 1rem;
    width: 1rem;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 2px;
    z-index: 1;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border: 2px solid #707070;
}

.row-selection-checkbox-cpy + label::after {
    opacity: 0;
    content: '✓';
    color: #707070;
}

.row-selection-checkbox-cpy:checked + label::after {
    opacity: 1;
}

/* Vendor Layout Card */
.cardMarginFix:not(:last-child) {
    margin-bottom: 1rem;
}

.insightsCardLoader {
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    animation: blinker 0.8s ease-in-out infinite;
}

@keyframes blinker {
    0% {
        background-color: #ddd;
    }

    100% {
        background-color: #eee;
    }
}
.ql-editor {
    background-color: white !important;
}

/* Query Builder Info Kiosk */

.customIKQPadding ul {
    padding-left: 1rem;
}

/* Loading Loader */
.loadingLoader {
    color: #000;
    display: inline-block;
    position: relative;
    font-size: 48px;
    font-family: Arial, Helvetica, sans-serif;
    box-sizing: border-box;
}
.loadingLoader::after {
    content: '';
    width: 5px;
    height: 5px;
    background: currentColor;
    position: absolute;
    bottom: 10px;
    right: -5px;
    box-sizing: border-box;
    animation: loadingLoaderAnim 1s linear infinite;
}

@keyframes loadingLoaderAnim {
    0% {
        box-shadow: 10px 0 rgba(255, 255, 255, 0), 20px 0 rgba(255, 255, 255, 0);
    }
    50% {
        box-shadow: 10px 0 black, 20px 0 rgba(255, 255, 255, 0);
    }
    100% {
        box-shadow: 10px 0 black, 20px 0 black;
    }
}
