.filters-top-cover {
  display: flex;
  flex-wrap: wrap;
  max-height: calc(100vh - 186px);
  overflow: auto;
}

.header-title {
  color: rgb(0, 0, 0);
  font-family: "Montserrat-SemiBold";
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  display: flex;
}

.calendar_icon {
  width: 14px;
  height: 16px;
}

::placeholder {
  color: #6b6b6b;
  font-size: 15px;
  font-family: "Montserrat-Regular";
  font-weight: 400;
}

.calendar-button {
  border-width: 0px;
  background-color: white;
}
.p-inputtext:enabled:focus {
  box-shadow: none !important;
}
.text-input {
  border-width: 0px;
  width: 200px;
  height: 38px;
  border-radius: 20px;
  align-items: center;
  border: 1px solid #9d9d9d;
  padding: 8px 8px 8px 15px;
}

.text-input:focus {
  outline: none;
}

.calDiv {
  display: flex;
  margin-top: 5px;
}
#calendar-from {
  padding-right: 5px;
  input {
    border-radius: 20px !important;
    border: 1px solid #9d9d9d;
    width: 204px;
    height: 38px;
  }

  ::placeholder {
    padding-left: 10px;
    font-size: 15px;
    font-weight: 500;
  }
  button {
    margin-left: -40px;
    background: none !important;
    border: none;
    color: #9d9d9d;
  }
}
#calendar-to {
  padding-right: 5px;
  margin-left: 5px;
  .p-inputtext {
    border: 1px solid #9d9d9d !important;
  }
  input {
    border-radius: 20px !important;
    border: 1px solid #9d9d9d;
    width: 204px;
    height: 38px;
  }
  ::placeholder {
    padding-left: 10px;
    font-size: 15px;
    font-weight: 500;
  }
  button {
    margin-left: -40px;
    background: none !important;
    border: none;
    color: #9d9d9d;
  }
  .p-inputwrapper,
  .p-calendar-w-btn,
  .p-inputwrapper-focus,
  .p-inputwrapper-filled {
    outline: none !important;
  }
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none !important;
}

.dates-top-div {
  display: grid;
  margin-bottom: 15px;
  margin-right: 5px;
}

.drop-down-div {
  margin-right: 10px;
  display: grid;
  margin-bottom: 15px;
  label {
    margin-bottom: 0px !important;
    padding: 8px !important;
  }

  .p-inputtext {
    text-align: left;
    padding-left: 15px !important;
  }
}

.filter-text {
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  padding-left: 10px;
  margin: none !important;
}

.edit-filter-button {
  border: 0 none;
  color: #5199CD;
  font-size: 15px;
  font-weight: 500;
  background-color: white;
  display: flex;
  height: 35;
  align-items: center;
}

.clear-all-button {
  background-color: white;
  height: 35px;
  color: #000000;
  border: 0 none;
  margin-right: 15px;
}

.footer-button-top-cover {
  display: flex;
  justify-content: flex-end; //space-between
}

.header-top-div {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.close-button {
  background-color: #ffffff;
  border: 0 none;
}

.sidebar-container {
  position: absolute;
  top: 0;
}
.filter-topbar {
  height: 100%;
  z-index: 1;
  position: relative;
}
@media (min-width: 1920px) {
  .sidebar-container {
    position: relative;
    top: -76px;
  }
  .filter-topbar {
    height: 100%;
    z-index: 1;
    position: absolute;
  }
}
.p-sidebar-top {
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
}

.p-sidebar {
  position: relative;
  padding: 20px !important;
}

.overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.filter-sidebar {
  box-shadow: none !important;
}
