.paginator {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    width: 100%;
    background: #e9f6ff;
    height: 43px;
    border-bottom: 1px solid #e4e4f1;
    border-left: 1px solid #e4e4f1;
    border-right: 1px solid #e4e4f1;
    justify-content: space-between;
    align-items: center;
    border-radius: 0px 0px 10px 10px;
    box-shadow: 0px -0.5px 3px #00000029;
}

.paginator-div-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 25px;
    margin: 0 30px;
}

.pagination a {
    /* border: 1px solid #e4e4f1; */
    color: #1d679e !important;
    font-size: 12px;
    padding: 4px 8px;
    width: 100%;
    height: 25px;
}

.pagination a:hover {
    text-decoration: none;
    background: #e7e8ea;
    color: #1d679e !important;
    border-radius: 3px;
}

.pagination-active a {
    /* background-color: #3b8bc6; */
    background-color: #1d679e;
    border-radius: 3px;
    color: #fff !important;
}

.previous-link {
    border-radius: 3px;
}

.next-link {
    border-radius: 3px;
}

.previous-link :hover {
    /* color: #000; */
    text-decoration: none;
}

.disabled-link-previous {
    color: #000;
    opacity: 0.35;
    border: 1px solid #e4e4f1;
    font-size: 12px;
    padding: 5px 10px;
    border: 3px;
}

.disabled-link-next {
    color: #000;
    opacity: 0.35;
    border: 1px solid #e4e4f1;
    font-size: 12px;
    padding: 5px 10px;
    border: 3px;
}

.disabled-link:hover {
    /* color: #000; */
    text-decoration: none;
}

.paginator-div-2 {
    display: flex;
    flex-direction: row;
    width: 50%;
    /* align-items: center; */
    justify-content: space-evenly;
    /* margin: auto; */
    padding: 0 20px;
    position: relative;
    align-items: center;
    left: -100px;
}

.paginator-div-2 > * {
    font-size: 12px;
}

.paginator-div-2 > div {
    display: flex;
    flex-direction: row;
}

.paginator-div-2 .items-per-page span {
    color: #000;
    opacity: 0.5;
    align-self: center;
    display: inline-block;
    margin-right: 5px;
}

.paginator-div-2 .page-jump span {
    color: #000;
    opacity: 0.5;
    align-self: center;
    display: inline-block;
    margin-right: 5px;
}

.paginator-div-2 .items-per-page .pagination-select {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-image: url('../../../assets/images/select-menu-icon/downward-arrow-grey.svg');
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: calc(100% - 8px) center;

    height: 25px;
    min-width: 59px;
    padding: 2px 6px;
    align-self: flex-start;
    font-weight: 500;
    border: 1px solid #e4e4f1;
}

.paginator-div-2 .page-jump input[type='text'] {
    height: 25px;
    width: 59px;
    padding: 2px 6px;
    align-self: flex-start;
    font-weight: 500;
    border: 1px solid #e4e4f1;
}

.paginator-div-2 .page-jump button {
    height: 25px;
    min-width: 59px;
    padding: 2px 6px;
    align-self: flex-start;
    font-weight: 500;
    border: 1px solid #e4e4f1;
    color: #3b8bc6;
    background: #fff;
}

/* .paginator-div-2 p {
    color: #000;
    opacity: 0.5;
    align-self: center;
    margin-top: 2px;
} */

/* .paginator-div-2 .pagination-select {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-image: url('../../../assets/images/select-menu-icon/downward-arrow-grey.svg');
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: calc(100% - 8px) center;

    height: 25px;
    min-width: 59px;
    padding: 2px 6px;
    align-self: flex-start;
    font-weight: 500;
    border: 1px solid #e4e4f1;
} */

.paginator-div-3 {
    background: #c0c0c0;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: auto;
    padding: 0 20px;
}

.paginator-div-3 > * {
    font-size: 12px;
}

.paginator-div-3 p {
    font-size: 14px;
    font-weight: 500;
    color: #000;
    opacity: 0.5;
}

.paginator-div-4 {
    margin: 0 30px;
}

.paginator-div-4 span {
    color: #707070;
    font: normal normal normal 0.9vw/2vh 'Montserrat-Regular';
}

@media only screen and (min-width: 1000px) {
    .paginator {
        justify-content: space-evenly;
        /* top: 5px; */
    }
    .paginator-div-2 {
        left: -20px;
    }
    /* .paginator-div-4 {
        width: 20%;
    } */
    .paginator-div-2 > * {
        font-size: 10px;
    }
    .paginator-div-4 p {
        font-size: 10px;
    }
}

@media only screen and (min-width: 1134px) {
    .paginator {
        justify-content: space-evenly;
    }
    .paginator-div-2 {
        left: -40px;
    }
    /* .paginator-div-4 {
        width: 30%;
        text-align: right;
    } */
    .paginator-div-2 > * {
        font-size: 12px;
    }
    /* .paginator-div-4 p {
        font-size: 14px;
    } */
}
@media only screen and (min-width: 1280px) {
    .paginator {
        justify-content: space-evenly;
        bottom: 0;
    }
    .paginator-div-2 {
        left: -40px;
    }
    /* .paginator-div-4 {
        width: 30%;
    } */
}
@media only screen and (min-width: 1380px) {
    .paginator {
        justify-content: space-between;
    }
}
@media only screen and (min-width: 1420px) {
    .paginator {
        justify-content: space-between;
    }
}
