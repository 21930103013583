/* .pagination {
    justify-content: center;
    position: absolute;
    bottom: 0.4vw;
    padding-left: 0.5vw;
} */

.selection-box-modal-toggle {
    width: 100%;
    height: 50%;
    min-height: 38px;
    font: normal normal normal 0.73vw/1.63vh "Montserrat-Regular";
    display: flex;
    flex-direction: row;
    border: 1px solid #1d679e;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    padding: 6px 6px;
}

.selection-modal-toggle-text {
    font: normal normal normal 0.8vw/2.3vh "Montserrat-Regular";
    color: #000;
}

.no-selection-modal-toggle-text{
    width: max-content;
    font: normal normal normal 0.8vw/2.3vh "Montserrat-Regular";
    color: #707070;
}

.modal-toggle-add-btn {
    margin-left: 10px;
    background-color: #1d679e;
    color: #fff;
    border: 0;
    box-shadow: none;
    padding: 5px 15px;
    font: normal normal normal 0.8vw/1.7vh "Montserrat-Regular";
    border-radius: 5px;
}
.selection-component {
    /* min-width: 14.5rem; */
    width: 13.8rem;
    height: auto;
    font: normal normal normal 0.7vw/1.5vh 'Montserrat-Regular';
}

.selection-button-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 0.92vh;
}

.selection-button-wrapper > div:first-child {
    display: flex;
    align-items: center;
    justify-content: center;
    font: normal normal normal 1.04vw/2.5vh 'Montserrat-Regular';
    color: #fff;
    background: #216ba1;
    height: 3.5vh;
    width: 6.25vw;
    border-radius: 5px;
    margin-right: 0.52vw;
    cursor: pointer;
    /* margin-left:0.52vw ; */
}

.selection-button-wrapper > text {
    font: normal normal normal 0.83vw/1.75vh 'Montserrat-Regular';
    color: #707070;
    margin-top: 0.92vh;
}

.selection-box {
    display: block;
    width: 100%;
    height: 11.11vh;
    width: 100%;
    overflow: auto;
    border: 1px solid #bbbbbb;
    border-radius: 5px;
    overflow-x: hidden;
    overflow-y: auto;
}

.modal .modal-dialog {
    min-width: 600px;
    width: 45.5vw;
    max-width: 45.5vw;
}

.modal .modal-header {
    padding: 0.5rem 0.5rem 0 0.5rem;
    border-bottom: 0;
}

.modal .modal-body {
    padding-top: 0;
    padding: 0 0.5rem 0 0.5rem;
}

.modal .modal-title {
    width: 100%;
}

.title-div-modal {
    width: 100%;
    height: 4.6vh;
    background: #e6f5ff;
    color: #2c333e;
    padding: 1vh 2.03vw;
    font: normal normal normal 0.83vw/2vh 'Montserrat-Medium';
}

.box-option-list {
    height: 50vh;
    width: 35%;
    overflow: hidden;
    /* overflow-y: auto;
    overflow-x: hidden; */
    /* padding-right: 1vw; */
    border-radius: 5px;
    border: 1px solid #5199CD;
    padding: 10px;
    margin-left: 20px;
    padding-bottom: 30px;
    padding-right: 0 !important;
}

.box-option-list > #options-body {
    transform: translateX(-2.5%);
    margin-top: 10px;
    padding-bottom: 52px;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    width: 102.5%;
}

.input-container {
    width: 97%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #bbb;
    border-radius: 5px;
    height: 4vh;
    font: normal normal normal 0.72vw/1.7vh 'Montserrat-Regular';
    color: #bbbbbb;
    padding: 0.6rem;
    /* margin-bottom: 1.5vh; */

    /* padding: 1.5vh auto; */
}
.input-holder{
    border: none;
    width: 90%;
    border-radius: 5px;
    font: normal normal normal 0.72vw/1.7vh 'Montserrat-Regular';
}

.box-option-list > span {
    position: absolute;
    right: 0;
    padding-right: 1.5vw;
    padding-top: 3vh;
}

.box-option {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 15px 0px 15px 10px;
    font: normal normal normal 0.73vw 'Montserrat-Regular' !important;
}

.box-option:nth-child(odd){
    background: #f6f7fb;
}

.box-option > div:first-child {
    font: normal normal normal 0.73vw 'Montserrat-Regular';
    color: #707070;
}

.box-option > div:last-child {
    font: normal normal normal 0.67vw/1.4vh 'Montserrat-Medium';
    color: #5199cd;
    cursor: pointer;
}

.box-option-added > input::placeholder {
    font: normal normal normal 0.72vw/1.7vh 'Montserrat-Regular';
    color: #bbbbbb;
}
.box-option-list #options-body::-webkit-scrollbar {
    width: 4px;
    background-color: #f2f0f0;
}
.box-option-list #options-body::-webkit-scrollbar-thumb {
    background-color: #ccc;
}
.box-option-added {
    height: 50vh;
    /* overflow-y: auto;
    overflow-x: hidden; */
    flex: 1;
    margin-right: 20px;
    overflow: hidden;
    padding:10px;
    padding-right: 0;
    border: 1px solid #5199CD;
    border-radius: 5px;
    margin-left: 10px;
}
.box-option-added > .box-option-selected-items {
    overflow: auto;
    max-height: 45vh;
    padding-left: 15px;
    padding-right: 15px;
    font: normal normal normal 0.72vw 'Montserrat-Regular';
    width: 105%;
}

.box-option-selected-items::-webkit-scrollbar {
    width: 6px;
}
.box-option-selected-items::-webkit-scrollbar-thumb {
    background-color: #ccc;
}
.box-option-added::-webkit-scrollbar {
    width: 6px;
}
.box-option-added::-webkit-scrollbar-thumb {
    background-color: #ccc;
}

.box-option-added .box-selected-title {
    display: flex;
    flex-direction: row;
    padding: 1.2vh 1vw;
    align-items: baseline;
    justify-content: space-between;
    border-bottom: 2px solid #e6f5ff;
    margin-right: 0rem;
}

.box-option-added .box-selected-title > text:first-child {
    color: #707070;
    font: normal normal normal 0.83vw/1.7vh 'Montserrat-Regular';
}

.box-option-added .box-selected-title > text:last-child {
    color: #5199cd;
    font: normal normal normal 0.67vw/1.4vh 'Montserrat-Medium';
}

/* Selected Chip */
.selected-chip {
    background: #e6f5ff;
    color: #1d679e;
    font: normal normal normal 0.73vw 'Montserrat-Regular';
    margin-right: 0.52vw;
    margin-left: 0.52vw;
    margin-top: 0.8vh;
    width: fit-content;
}

.submit-selected {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    border: none;
    background: #216ba1;
    color: #fff;
    height: 3.5vh;
    width: 6.2vw;
    min-width: 60px;
    font: normal normal normal 1.04vw/2.5vh 'Montserrat-Regular';
}

.header__text {
    font: normal normal 600 0.73vw "Montserrat-Medium";
    letter-spacing: 0.48px;
    color: #707070;
    max-width: 60%;
}
.modal-content{
    height: 70vh !important;
}
.header__button {
    letter-spacing: 0.42px;
    color: #216ba1;
    background: none;
    border: none;
    font:  normal normal normal 0.63vw 'Montserrat-Regular';
    cursor: pointer;
}

.btn.btn-primary[disabled] {
    background: #707070 0% 0% no-repeat padding-box !important;
    border: solid #707070 2px;
}
.btn-primary[disabled] {
    background: #707070 0% 0% no-repeat padding-box !important;
    border: solid #707070 2px;
}

table.table-bordered {
    border: 1px solid #5199cd;
    border-radius: 5px;
}
.dummy-data{
    height: 100%;
    overflow: hidden;
}
.dummy-data tr {
   
    letter-spacing: 0.48px;
    color: #707070;
    border: none;
}
.dummy-data tr td{
    min-width: 100%;
    min-height: 48px;
    font: normal normal normal 0.83vw 'Montserrat-Regular';
}
.dummy-data :hover{
    color: #707070;
}
.btn{
    height: 3vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4vw;
    font: normal normal normal 0.83vw 'Montserrat-Regular' !important;
}
.btn-primary, .btn-primary:active{
    background: #216ba1;
    border-color: #216ba1;
}
.btn-outline-primary, .cancel-button{
    color: #216ba1;
    border: 2px solid #216ba1;
}
.btn-outline-primary:hover{
    color: #216ba1;
    border: 2px solid #216ba1;
}
.btn-primary:hover{
    background: #216ba1;
    border-color: #216ba1;
}
.border-primary{
    border: 1px solid #216ba1 !important;
}
.btn:focus {
    outline: none;
    box-shadow: none;
  }

.analyses-tabs-wrapper-modal {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0.8rem 0;
    align-items: flex-start;
    font: normal normal normal 0.83vw 'Montserrat-Regular' !important;
    /* overflow-x: auto;
    overflow-y: hidden; */
}

.analyses-tab-modal-active {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    min-width: fit-content;
    height: 4.2vh;
    padding: 0 0.8rem;
    color: #000;
    opacity: 0.7;
    border-radius: 3px 3px 0 0;
    border-top: 1px solid #e4e4f1;
    border-bottom: 0;
    border-left: 1px solid #e4e4f1;
    border-right: 1px solid #e4e4f1;
    cursor: pointer;
    font: normal normal normal 0.83vw 'Montserrat-Medium';
}

.analyses-tab-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #e4e4f1;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    background: none;
    min-width: fit-content;
    height: 4.2vh;
    padding: 0 0.8rem;
    color: #5199cd;
    border-radius: 3px 3px 0 0;
    cursor: pointer;
    font: normal normal normal 0.83vw 'Montserrat-Regular';
}
.accelerated-insights-my-analysis-tabl-modal {
    border-radius: 10px;
    width: 100%;
    height: max-content;
    border-radius: 10px;
}
.selected-add{
    cursor: 'pointer';
    width: 8px;
 }
.header-text{
    font: normal normal medium 0.73vw 'Montserrat-Medium';
    padding-bottom: 0.3rem;
    margin-bottom: 0.5rem;
    border-bottom: 1px solid #bbb;
    color: #000;
    font-weight: 600;

}
.crossIcon{
    display: flex;
    align-items: center;
    padding: 0 5px;
    border: 1px solid #5199CD;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.search_filter_table{
    font-size: 0.83vw !important;
    font-family: 'Montserrat-Regular' !important;
    width: 100%;    
}
.input-holder-selected{
    display: flex;
    font:  normal normal normal 0.83vw 'Montserrat-Regular';
    border: 1px solid #216ba1;
    border-radius: 5px;
}
input::placeholder {
    font: normal normal normal 0.83vw 'Montserrat-Regular';
}
.accept-reject-popup-modal {
    position: absolute;
    background: #f6f7fb;
    padding: 0.4rem 0.2rem;
    border: 1px solid #5199cd;
    border-radius: 3px;
    width: 14.75vw;
    min-height: 6.03vh;
    display: flex;
    z-index: 999;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    transform: translateX(-6vw) translateY(-5.1vh);
    font: normal normal normal 0.63vw 'Montserrat-Regular' !important;
}
.accept-reject-btn-modal {
    align-self: center;
    display: flex;
    gap: 10px;
    padding-top: 0.2rem;
    
}
.accept-reject-btn-modal div:first-child {
    background-color: #1d679e;
    border-radius: 3px;
    padding: 0.1rem;
    width: 4vw;
    color: #fff;
    cursor: pointer;
    text-align: center;
}

.accept-reject-btn-modal div:last-child {
    width: 4vw;
    padding: 0.1rem;
    border-radius: 3px;
    border: 1px solid #1d679e;
    color: #1d679e;
    cursor: pointer;
    text-align: center;
}

.reset-arrow-modal {
    position: absolute;
    border: 1px solid transparent;
    background: #f6f7fb;
    top: 6.1vh;
    right: 0.9vw;
    border-bottom-color: #5199cd;
    border-right-color: #5199cd;
    width: 12px;
    height: 12px;
    transform: rotate(45deg);
    /* box-shadow: 0px 5px 5px #00000029; */
}

.header__text {
    font: normal normal 600 0.73vw "Montserrat-Medium";
    letter-spacing: 0.48px;
    color: #707070;
    max-width: 60%;
}

.header__button {
    letter-spacing: 0.42px;
    color: #216ba1;
    background: none;
    border: none;
    font:  normal normal normal 0.63vw 'Montserrat-Regular';
    cursor: pointer;
}

.btn.btn-primary[disabled] {
    background: #707070 0% 0% no-repeat padding-box !important;
    border: solid #707070 2px;
}

.options-body-new {
    height: 43vh;
    overflow-y: auto;
    font: normal normal normal 0.83vw 'Montserrat-Regular';
}
table.table-bordered {
    border: 1px solid #5199cd;
    border-radius: 5px;
}
.dummy-data{
    height: 100%;
    overflow: hidden;
}
.dummy-data tr {
   
    letter-spacing: 0.48px;
    color: #707070;
    border: none;
}
.dummy-data tr td{
    min-width: 100%;
    min-height: 48px;
    font: normal normal normal 0.83vw 'Montserrat-Regular';
}
.dummy-data :hover{
    color: #707070;
}
.btn{
    height: 3vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4vw;
    font: normal normal normal 0.83vw 'Montserrat-Regular' !important;
}
.btn-primary, .btn-primary:active{
    background: #216ba1;
    border-color: #216ba1;
}
.btn-outline-primary, .cancel-button{
    color: #216ba1;
    border: 2px solid #216ba1;
}
.btn-outline-primary:hover{
    color: #216ba1;
    border: 2px solid #216ba1;
}
.btn-primary:hover{
    background: #216ba1;
    border-color: #216ba1;
}
.border-primary{
    border: 1px solid #216ba1 !important;
}
.btn:focus {
    outline: none;
    box-shadow: none;
  }

.analyses-tabs-wrapper-modal {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0.8rem 0;
    align-items: flex-start;
    font: normal normal normal 0.83vw 'Montserrat-Regular' !important;
    /* overflow-x: auto;
    overflow-y: hidden; */
}

.analyses-tab-modal-active {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    min-width: fit-content;
    height: 4.2vh;
    padding: 0 0.8rem;
    color: #000;
    opacity: 0.7;
    border-radius: 3px 3px 0 0;
    border-top: 1px solid #e4e4f1;
    border-bottom: 0;
    border-left: 1px solid #e4e4f1;
    border-right: 1px solid #e4e4f1;
    cursor: pointer;
    font: normal normal normal 0.83vw 'Montserrat-Medium';
}

.analyses-tab-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #e4e4f1;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    background: none;
    min-width: fit-content;
    height: 4.2vh;
    padding: 0 0.8rem;
    color: #5199cd;
    border-radius: 3px 3px 0 0;
    cursor: pointer;
    font: normal normal normal 0.83vw 'Montserrat-Regular';
}
.accelerated-insights-my-analysis-tabl-modal {
    border-radius: 10px;
    width: 100%;
    height: max-content;
    border-radius: 10px;
}

.onHoverShow{
    visibility: visible;
    color: #5199cd;
 
    font-size: 0.4vw !important;
}
.table-grid-layout{
    display: grid;
    grid-template-columns: 15% 27% 30% 15% auto;
}
textarea::placeholder {
    text-align: center;
    line-height: 39vh;
    font-size: 0.83vw ;
    overflow: hidden;
    font-family: 'Montserrat-Regular';
  }
  .textarea-footer{
    font: normal normal normal 0.6vw 'Montserrat-Regular';
    color: #707070;
    margin-bottom: -4px;
  }
  .npiPlot{
    display: grid;
grid-template-columns: 2fr 3fr;
  }
  .loader {
    width: 8px;
    height: 8px;
    border: 5px solid #fff;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }
    @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
        } 
