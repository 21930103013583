.notification-tab-active {
    border-top: 1px solid #e4e4f1;
    border-left: 1px solid #e4e4f1;
    border-right: 1px solid #e4e4f1;
    font: normal normal bold 16px/20px 'Montserrat-Medium';
}

.notification-tab-inactive {
    border-bottom: 1px solid #e4e4f1;
    color: #5199cd;
    font: normal normal normal 16px/20px 'Montserrat-Regular';
}
.notification-tab {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 8rem;
    height: 4.2vh;
    padding: 0 0.8rem;
    border-radius: 3px 3px 0 0;
    cursor: pointer;
}

.notification-dropdown label {
    margin-right: 10px;
    font-size: 16px;
}
.notification-dropdown select {
    width: 80px;
    text-align: left;
    padding: 5px 15px;
    font-size: 15px;
    border: none;
    cursor: pointer;
    border: 1px solid #bbbbbb;
    border-radius: 5px;
}
.notification-dropdown select option {
    background-color: #f6f7fb;
    border-bottom: 1px solid black;
}

.notification-component {
    border-color: rgb(230, 230, 230);
    border-bottom-width: thin;
    border-style: solid;
    border-bottom-width: 1px;
    border-top-width: 0px;
    border-right-width: 0px;
    border-left-width: 0px;
}

.notifications-tabs-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0.8rem 0;
    align-items: flex-start;
    border-radius: 3px 3px 0 0;
}

.occupator-2 {
    width: 1.5vw;
    border-bottom: 1px solid #e4e4f1;
    height: 4.2vh;
}

.notification-panel-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1vh 0vw;
}

.notification-panel-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 4.6vh;
    background-color: #e6f5ff;
    box-shadow: 0px 0px 5px #00000029;
    padding: 0.8rem;
    margin-left: 1.5vw;
    margin-right: 1.5vw;
}

.arrow-buttons {
    background-color: transparent;
    border: 0px;
    color: #1d679e;
}

.arrow-buttons:disabled {
    color: #bbbbbb;
}

.page-numbers {
    padding: 0.4vw;
    font: normal normal normal 11px/6px 'Montserrat-Regular';
    letter-spacing: 0.48px;
    cursor: pointer;
}
