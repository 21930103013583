.access-management-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 1rem 1rem 0;
}

.access-management-wrapper > .access-info-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 0.5rem;
    padding-bottom: 3.2vh;
}

.access-management-wrapper .access-info-container > .access-modules-list {
    display: flex;
    flex-direction: column;
    flex: 50rem 1 auto;
    align-items: flex-start;
    height: 90vh;
}

.access-management-wrapper .access-info-container .access-modules-list > .module-list-title {
    margin-bottom: 0.5rem;
}

.access-management-wrapper .access-info-container .access-modules-list > .module-list-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    overflow-x: hidden;
    overflow-y: auto;
}

.access-management-wrapper .access-info-container .access-modules-list .module-list-wrapper > .access-module-card {
    display: flex;
    flex-direction: column;
    padding: 12px;
    width: 23.6rem;
    background-color: #fff;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
    border-radius: 3px;
}

.access-management-wrapper
    .access-info-container
    .access-modules-list
    .module-list-wrapper
    .access-module-card
    > .module-title {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 10px;
}

.access-management-wrapper
    .access-info-container
    .access-modules-list
    .module-list-wrapper
    .access-module-card
    > .module-title:hover {
    background-color: #edeef1;
}

.module-title-active {
    background-color: #edeef1;
}

.access-management-wrapper
    .access-info-container
    .access-modules-list
    .module-list-wrapper
    .access-module-card
    .module-title
    > .title-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.access-management-wrapper
    .access-info-container
    .access-modules-list
    .module-list-wrapper
    .access-module-card
    > .modules-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-top: 12px;
}

.access-management-wrapper
    .access-info-container
    .access-modules-list
    .module-list-wrapper
    .access-module-card
    .modules-info
    > .submodules-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin: 0.5rem auto;
}

.access-management-wrapper .access-info-container > .access-users-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    overflow: hidden;
    height: 95%;
    /* margin: 0 1rem; */
    margin-left: 1rem;
}

.access-management-wrapper .access-info-container .access-users-list > .user-list-title {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
}

.view-count-box {
    display: flex;
    flex-direction: row;
    padding: 2px 4px;
    min-width: 3.5rem;
    height: 1.5rem;
    background-color: rgba(222, 226, 230, 0.3);
    width: fit-content;
    align-items: center;
    justify-content: space-around;
    color: #96999f;
    font-weight: 600;
    border: 1px solid #dee2e6;
    border-radius: 3px;
}
.view-count-box-small {
    display: flex;
    flex-direction: row;
    padding: 2px 4px;
    height: 1.5rem;
    background-color: rgba(222, 226, 230, 0.3);
    width: fit-content;
    align-items: center;
    justify-content: space-around;
    color: #96999f;
    font-weight: 600;
    border: 1px solid #dee2e6;
    border-radius: 3px;
}

/* Text Styling */

.text-format-normal {
    font: normal normal normal 0.8rem/1.07rem 'Montserrat-Regular';
}

.text-format-medium {
    font: normal normal normal 1rem/1.07rem 'Montserrat-Regular';
}

.text-format-medium-bold {
    font: normal normal normal 1rem/1.07rem 'Montserrat-SemiBold';
}

.text-format-large-bold {
    font: normal normal 500 1.06rem/1.3rem 'Montserrat-SemiBold';
}

.outer-icon-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1d679e;
    border-radius: 50%;
}

.outer-icon-circle img {
    height: 100%;
    width: 100%;
}
