.data-table {
    margin: 0px 20px;
    box-shadow: 0 1px 4px rgb(39 45 59 / 20%);
}

.p-datatable-scrollable-header .p-sortable-column {
    background-color: #E6F5FF !important;
    box-shadow: none !important;
}

/* table header row style */
.p-sortable-column {
    padding: 15px 20px !important;
}

/* header row title text style */
.p-column-title {
    border: 1px solid rgba(0, 0, 0, 0);
    color: #1d679e;
    font-family: 'Montserrat-SemiBold';
    font-size: 16px;
    font-weight: 600;
}

/* sorting arrows styles */
.p-sortable-column-icon {
    padding-bottom: 7px;
    margin-left: 0.1rem !important;
}

.pi-sort-amount-down:before,
.pi-sort-alt:before {
    content: url('../../assets/images/solid_arrow.svg') !important;
}
.pi-sort-amount-up:before,
.pi-sort-amount-up-alt:before {
    content: url('../../assets/images/solid_arrow.svg') !important;
}

.pi-sort-amount-up,
.pi-sort-amount-up-alt {
    transform: rotate(180deg);
    padding-bottom: 0px;
    margin-bottom: -11px;
}

/* table body scroll style */
.p-datatable-scrollable-header-box {
    margin-right: 0px !important;
}

.p-datatable-scrollable-body::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

.p-datatable-scrollable-body::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.p-datatable-scrollable-body::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #c0c0c0;
}

/* table body styles */
.p-datatable-tbody tr td {
    height: 18px;
    color: #000000;
    font-family: 'Montserrat-Medium';
    font-size: 15px;
    font-weight: 500;
    padding: 11px 20px !important;
    /* padding-right: 25px!important; */
    border: 1px solid #e4e4f1 !important;
    border-width: 0 0 1px 0 !important;
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd {
    background-color: #f9fdff;
}

/* paginator styles */
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    width: 35px;
    height: 35px;
    background: #E6F5FF;
    border-color: #E6F5FF;
    color: #000000;
    font-family: 'Montserrat-SemiBold';
    font-size: 16px;
    font-weight: 600;
    min-width: 35px;
}

.p-paginator .p-paginator-pages .p-paginator-page {
    width: 35px;
    height: 35px;
    color: #343434;
    font-family: 'Montserrat-Regular';
    font-size: 16px;
    font-weight: 400;
    min-width: 35px;
}

.p-link:focus {
    box-shadow: none;
}

.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
    width: 35px;
    height: 35px;
    min-width: 35px;
}

.p-datatable .p-datatable-thead > tr > th {
 /* width: 200px;*/
  padding-left: 20px !important;
  text-align: center;
  vertical-align: top!important;
}

/* .p-datatable-scrollable-body > table > .p-datatable-tbody > tr:first-child > td {
  /*width: 200px;
} */

.p-datatable .p-datatable-tbody > tr > td {
    text-align: center;
    overflow-wrap: break-word !important;
}

.p-datatable .p-datatable-loading-overlay {
    left: 0 !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    z-index: 1 !important;
}
.loader-div {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99 !important;
    left: 0 !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
}

.table-loader-overlay {
    background-color: #000000;
    opacity: 0.05;
    bottom: 0;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
}

@keyframes p-progress-spinner-color {
    100%,
    0% {
        stroke: #1d679d;
    }
    40% {
        stroke: #1d679d;
    }
    66% {
        stroke: #1d679d;
    }
    80%,
    90% {
        stroke: #1d679d;
    }
}

.p-progress-spinner-svg {
    animation-name: p-progress-spinner-color;
}
.p-progress-spinner {
    width: 80px !important;
    height: 80px !important;
}

/* Paginator css */
.p-paginator {
    position: absolute;
    right: 20px;
    bottom: 0px;
    left: 20px;
}

.data-not-found-container {
    margin-top: 100px;
    position: absolute;
    width: auto;
    align-items: center;
    z-index: 99 !important;
    left: 50%;
}

.showing-record {
    color: #343434;
    font-family: 'Montserrat-SemiBold';
    font-size: 16px;
}
