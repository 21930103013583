.sub-accordian-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 2.7vh;
}

.sub-accordian-wrapper>.sub-accordian-tabs {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
}

.sub-accordian-wrapper .sub-accordian-tabs>.sub-accordian-tab {
    align-items: flex-start;
    text-align: left;
    border-bottom: 1px solid #1d679e;

    overflow: hidden;
    height: 100%;
}

/* .sub-accordian-wrapper .sub-accordian-tabs > .sub-accordian-tab::before {
    content: "";
    position: absolute;
    top: 10%;
    width: 2px;
    height: 50%;
    left: 4%;
    background: #1d679e;
} */

.sub-accordian-wrapper .sub-accordian-tabs .sub-accordian-tab>input {
    position: absolute;
    opacity: 0;
    z-index: -1;
}

.sub-accordian-wrapper .sub-accordian-tabs .sub-accordian-tab .label-wrapper>label {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    font: normal normal 600 1.14vw/2.5vh 'Montserrat-Regular';
    color: #1d679e;
    padding: 1.66vh 1.56vw 1.66vh 0.78vw;
    cursor: pointer;
}

.sub-accordian-wrapper .sub-accordian-tabs .sub-accordian-tab .label-wrapper>label::after {
    content: url('../../../../assets/images/ssa-accordian-icons/default-small.png');
    width: 0.4vw;
    height: 1.4vh;
    text-align: center;
    transition: all 0.3s;
    transform: scale(0.75);
}

.sub-accordian-wrapper .sub-accordian-tabs .sub-accordian-tab>.sub-accordian-content {
    width: 100%;
    max-height: 0;
    opacity: 0;
    background: #fff;
}

.sub-accordian-wrapper .sub-accordian-tabs .sub-accordian-tab>input:checked~.sub-accordian-content {
    width: 100%;
    opacity: 1;
    max-height: 100%;
    /* padding-right: 1.4vw; */
    border-left: 3px solid #1d679e;

    /* margin-bottom: 2.7vh; */
    /* border-left: 2px solid #1d679e; */
}

.sub-accordian-wrapper .sub-accordian-tabs .sub-accordian-tab>input:checked~.sub-accordian-content::before {
    width: 100%;
    content: '';
    position: absolute;
    left: 4%;
    max-height: 100%;
    height: auto;
    /* top: 10%; */
    width: 2px;
    background: #1d679e;
    /* margin-bottom: 2.7vh; */
    /* border-left: 2px solid #1d679e; */
}

.sub-accordian-wrapper .sub-accordian-tabs .sub-accordian-tab>input:checked~.label-wrapper label {
    width: 100%;
    font: normal normal 600 1.14vw/2.5vh 'Montserrat-SemiBold';
}

.sub-accordian-wrapper .sub-accordian-tabs .sub-accordian-tab>input:checked~.label-wrapper label::after {
    content: url('../../../../assets/images/ssa-accordian-icons/blue-small.png');
    position: relative;
    left: -0.4vw;
}

.sub-accordian-content>.reset-div {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    position: relative;
    top: 0;
    text-align: right;
    color: #5199cd;
    font: normal normal normal 0.72vw/1.3vh 'Montserrat-Medium';
    cursor: pointer;
}

.sub-accordian-content>.reset-div :hover {
    font-weight: 600;
}

.sub-accordian-content .tab-wrapper {
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 1.5vh;
    /* background: #707070; */
    margin-bottom: 2.3vh;
}

.sub-accordian-content .tab-wrapper>.tab-child {
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.sub-accordian-content .tab-wrapper .tab-child>text:first-child {
    width: 100%;
    overflow: hidden;
    font: normal normal normal 1.04vw/2.22vh 'Montserrat-Medium';
    margin-bottom: 2px;
}

.sub-accordian-content .tab-wrapper .tab-child>text:nth-child(2) {
    width: 100%;
    overflow: hidden;
    color: #707070;
    font: normal normal normal 0.62vw/1.38vh 'Montserrat-Medium';
}

.sub-accordian-content .tab-wrapper>div:nth-child(2) {
    width: 100%;
    overflow: hidden;
    height: 100%;
    align-items: center;
    justify-content: flex-start;
}

.sub-accordian-content .tab-wrapper div:nth-child(2) div>label {
    overflow: hidden;
    font: normal normal normal 1.04vw/2.22vh 'Montserrat-Regular';
    margin: auto 2vw 1.8vh 2vw;
}

.asterick-active {
    color: red;
    margin-left: 2px;
    display: inline;
}

.asterick-inactive {
    display: none;
}