.member-selection-filters-wrapper {
    background: white;
    width: 100%;
    height: 30vh;
    box-shadow: 0px 0px 10px #00000029;
    border-radius: 10px 10px 0 0;
    border: 1px solid #bbb;
}

.filters-title {
    height: 3.8vh;
    display: flex;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #bbb;
    padding: 0 1.5vw;
}

.filters-title > text {
    font: normal normal normal 1.04vw/2.2vh 'Montserrat-Medium';
    color: #1d679e;
    margin: 0 0.29vw;
}

.member-selection-filters {
    background-color: #f6f7fb;
    height: calc(100% - 3.8vh);
    /* border-radius: 0 0 10px 10px; */
    overflow-y: auto;
}

.member-selection-filters::-webkit-scrollbar {
    width: 4px;
}

.filter-accordian-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-bottom: 1px solid #bbb;
    cursor: pointer;
}

.filter-accordian-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    color: #707070;
}

.filter-accordian-header text {
    font: normal normal normal 0.9vw/2.03vh 'Montserrat-Regular';
    color: #707070;
}

.filter-accordian-body {
    border: 1px solid #1d679e;
}

.filter-accordian-body > .no-filter-text {
    font: normal normal normal 0.9vw/2.03vh 'Montserrat-Regular';
    color: #1d679e;
}

.filter-accordian-body {
    padding: 0.6rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
}

.member-filter-field {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid #1d679e;
    padding: 2px 4px;
    border-radius: 5px;
    gap: 5px;
    flex-basis: auto;
    width: auto;
    white-space: nowrap;
}

.member-filter-field text {
    font: normal normal normal 0.9vw/2.03vh 'Montserrat-Regular';
    color: #1d679e;
}

.member-filter-field > .member-filter-count {
    background-color: #1d679e;
    font: normal normal normal 0.9vw/2.03vh 'Montserrat-Regular';
    color: #fff;
    border-radius: 50%;
    height: 1.5rem;
    width: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
