.home-main-container {
    background: #fff;
    border-radius: 10px;
    margin: 20px 20px 40px 20px;
    display: grid;
    height: auto;
    padding-bottom: 40px;
    svg {
        pointer-events: none;
    }
}

.container-row1 {
    background-color: #fff;
    display: flex;
    flex-direction: row;
    margin-top: 10vh;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.home-child1 {
    background-color: #fff;
    display: flex;
    margin-bottom: 5vw;
    justify-content: flex-start;
    min-width: 27vw;
    margin-left: 2.3vw;
    margin-right: 1vw;
    min-height: 20vh;
    border-radius: 0.5rem;
    box-shadow: 1px 1px 5px #cecccc;
    cursor: pointer;
}

.home-subChild {
    background-color: #1d679e;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    min-width: 5vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.hero-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // align-self: flex-start;
    text-align: left;
}
.home-desc {
    margin-left: 1.5vw;
    color: #707070;
    font: normal normal normal 0.8vw/2.2vh 'Montserrat-Regular';
    // font-family: Montserrat;
    // font-size: 1vw;
    margin-right: 1vw;
    max-width: 20vw;
}
