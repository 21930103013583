.contact-form {
    width: 31.25vw;
    height: 92vh;
    background: #fff;
    min-width: 400px;
    overflow-y: auto;
}
.contact-form > .form-wrapper {
    width: 100%;
    height: 100%;
    padding: 0.9vh;
}

.contact-form .form-wrapper > .form-title {
    width: 100%;
    background: #e6f5ff;
    text-align: left;
    box-shadow: 0 0 5px #00000029;
    border-radius: 2px;
    padding: 1.3vh;
}

.contact-form .form-wrapper .form-title > span {
    display: inline-block;
}

.contact-form .form-wrapper .form-title span > text {
    font: normal normal normal 0.93vw/2.03vh 'Montserrat-Regular';
    letter-spacing: 0.5px;
}

.contact-form .form-wrapper > .section-divider {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 2vh;
    padding-bottom: 1vh;
}

.contact-form .form-wrapper .section-divider > div:first-child {
    height: 1px;
    background: #5199cd;
    margin: 0;
}

.contact-form .form-wrapper .section-divider > div:nth-child(2) {
    color: #5199cd;
    font: normal normal normal 0.93vw/2.03vh 'Montserrat-Regular';
    margin: 0;
}

.contact-form .form-wrapper .section-divider > div:last-child {
    height: 1px;
    background: #5199cd;
    margin: 0;
}

.contact-form .form-wrapper > .form-field {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1.3vh;
}

.contact-form .form-wrapper .form-field > label {
    font: normal normal normal 1.04vw/2.22vh 'Montserrat-Medium';
    text-align: left;
}

.contact-form .form-wrapper .form-field > input {
    width: 100%;
    height: 4.1vh;
    border-radius: 3px;
    background: #e9e9e9;
    border: 1px solid #bbb;
    padding: 2px 10px;
    color: #707070;
    font: normal normal normal 0.83vw/1.75vh 'Montserrat-Regular';
}

.contact-form .form-wrapper > .form-field-phone {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1.3vh;
}

.contact-form .form-wrapper .form-field-phone > label {
    font: normal normal normal 1.04vw/2.22vh 'Montserrat-Medium';
    text-align: left;
}

.contact-form .form-wrapper .form-field-phone > .phone-input {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.contact-form .form-wrapper .form-field-phone .phone-input > input:first-child {
    width: 3.3vw;
    height: 4.1vh;
    border-radius: 3px;
    border: 1px solid #bbb;
    margin-right: 1.5vw;
    padding: 2px 10px;
    color: #707070;
    font: normal normal normal 0.83vw/1.75vh 'Montserrat-Regular';
}

.contact-form .form-wrapper .form-field-phone .phone-input > input:nth-child(2) {
    width: 13.5vw;
    height: 4.1vh;
    border-radius: 3px;
    border: 1px solid #bbb;
    margin-right: 1.5vw;
    padding: 2px 10px;
    color: #707070;
    font: normal normal normal 0.83vw/1.75vh 'Montserrat-Regular';
}

.contact-form .form-wrapper .form-field-phone .phone-input > label {
    font: normal normal normal 1.04vw/2.22vh 'Montserrat-Regular';
    color: #707070;
}

.contact-form .form-wrapper > .form-field-desc {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1.3vh;
}

.contact-form .form-wrapper .form-field-desc > label {
    font: normal normal normal 1.04vw/2.22vh 'Montserrat-Medium';
    text-align: left;
}

.contact-form .form-wrapper .form-field-desc > textarea {
    width: 100%;
    height: 11vh;
    border-radius: 3px;
    background: #fff;
    border: 1px solid #bbb;
    font: normal normal normal 0.83vw/1.75vh 'Montserrat-Regular';
    padding: 8px;
}

.contact-form .form-wrapper > .form-submit {
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 1.5vh;
}

.contact-form .form-wrapper .form-submit > input {
    padding: 0.2vh 1.5vw;
    background: #216ba1;
    color: #fff;
    font-size: 0.83vw;
}

.contact-form .form-wrapper > .form-field-issue {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1.3vh;
}

.contact-form .form-wrapper .form-field-issue > label {
    font: normal normal normal 1.04vw/2.22vh 'Montserrat-Medium';
    text-align: left;
}

.contact-form .form-wrapper .form-field-issue > .issue-select {
    width: 100%;
    height: 4.1vh;
    border-radius: 3px;
    text-align: left;
    font: normal normal normal 0.83vw/1.75vh 'Montserrat-Regular';
    /* border: 1px solid #bbb; */
}
