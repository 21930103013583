.search-container {
    display: flex;
    justify-content: space-between;
}

.searchbar-container {
    margin-bottom: 10px;
}

.search-view {
    height: 36px;
    display: flex;
    align-items: center;
    border: 1px solid #e4e4ee;
    border-radius: 100px;
    color: #6b6b6b;
    font-size: 14px;
    font-family: 'Montserrat-Medium';
    padding-left: 16px;
    height: 36px;
    display: flex;
    align-items: center;
}

.search-text-input {
    border-width: 0px;
    margin-left: 20px;
    padding: 0px;
}

.header-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    margin-top: 20px;
    background-color: #e6f5ff;
    border-radius: 3px;
    box-shadow: 0 0 5px #00000029;
}

.title-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.title-label {
    font: normal normal normal 1.04vw/2.2vh 'Montserrat-Medium';
}

.close-icon {
    width: 26px;
    height: 26px;
    cursor: pointer;
}

.tracker-t-height {
    max-height: 60vh; //544px;
}

.activity-tracker-modal .p-dialog {
    box-shadow: none;
}

.activity-tracker-modal  .p-dialog-header {
    padding: 0.8rem;
}

.activity-tracker-modal .p-dialog-header {
    width: 16px;
    height: 16px;
    align-self: flex-end;
    align-items: center;
    bottom: 98.5%;
    position: absolute;
}

.activity-tracker-modal .p-dialog-header .p-dialog-header-icon {
    width: 0.8rem;
    height: 1rem;
    color: #000;
}

.activity-tracker-modal .pi {
    font-weight: bold;
}

.activity-tracker-modal .p-dialog-header-icon {
    font-weight: 500;
    right: 50%;
    font-size: 12px;
}
