.table-scroll {
    position: relative;
    max-width: 100%;
    overflow: hidden;
    background: #fff;
    min-height: 40vh;
}

.table-scroll-shadow {
    position: relative;
    max-width: 100%;
    overflow: hidden;
    background: #fff;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06);
    min-height: 40vh;
    border-radius: 0 0 10px 10px;
}

.rounded-top-container{
    border-radius: 10px 10px 0 0;
    /* box-shadow: 0px 0px 10px #00000029; */
}

.table-wrap {
    width: 100%;
    overflow-x: auto;
    overflow-y: overlay;
    background: #fff;
    height: 100%;
    max-height: calc(100% - 43px);
    margin-bottom: 43px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06);
}

.reusable-table-options {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding: 15px 0;
    gap: 1.5vw;
    background: #fff;
}

.table-show-items-option {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.table-show-items-option span {
    font: normal normal normal 0.9vw/2vh 'Montserrat-Regular';
    color: #707070;
}

.table-show-items-option select {
    font: normal normal normal 0.9vw/2vh 'Montserrat-Regular';
    color: #707070;
    width: 4.62vw;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #707070;
    opacity: 0.8;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-image: url('../../../assets/images/select-menu-icon/downward-arrow-grey.svg');
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: calc(100% - 8px) center;
    align-self: flex-start;
}

.table-option-separator {
    width: 1px;
    background: #707070;
    height: 2vh;
    opacity: 0.8;
}

.table-search-toggle {
    display: flex;
    flex-direction: row;
    gap: 10px;
    cursor: pointer;
}

.table-search-toggle text {
    font: normal normal normal 0.9vw/2vh 'Montserrat-Regular';
    color: #707070;
}

.table-show-items-option {
    width: 7vw;
}

.table-option-dropmenu-wrapper {
    position: relative;
}

.table-option-dropmenu {
    position: relative;
    margin-right: 20px;
    cursor: pointer;
}

.table-drop-arrow {
    position: absolute;
    transform: rotate(45deg);
    border-top: 1px solid #1d679e;
    border-left: 1px solid #1d679e;
    width: 10px;
    height: 10px;
    background: #f5fbff;
    right: 70%;
    top: 125%;
}

.table-drop-options {
    background: #f5fbff;
    position: absolute;
    min-width: 9.5vw;
    border: 1px solid #1d679e;
    z-index: 9999;
    top: 150%;
    left: 0%;
    transform: translateX(-88%);
    box-shadow: 0px 0px 10px #00000029;
    border-radius: 3px;
}

.table-drop-action-option {
    padding: 0.5vw;
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    font: normal normal normal 0.8vw/1.8vh 'Montserrat-Regular';
    border-bottom: 1px solid #1d679e;
    cursor: pointer;
}

.table-drop-action-option:hover {
    font-weight: bold;
}

.table-drop-action-option:last-child {
    border-bottom: none;
}

.expand-toggler {
    display: flex;
    align-items: center;
    justify-content: center;
}

.no-results-found {
    width: 100%;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    background: #fff !important;
}

.no-results-found::after {
    content: 'No results Found';
    margin: 50px auto;
    font-size: 15px;
    font-weight: 500;
    position: relative;
    top: 35%;
}

.table-scroll .new-table {
    width: 100%;
    border-collapse: collapse;
    color: #000;
    font-weight: 400;
}

.table-scroll .new-table:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 10px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06);
    z-index: -1;
}

.table-scroll .new-table .new-table-head,
.new-table .new-table-body .new-table-row {
    text-align: left;
    font: normal normal normal 0.9vw/2vh 'Montserrat-Regular';
}

.new-table-row-expanded {
    text-align: left;
    font: normal normal normal 0.9vw/2vh 'Montserrat-Regular';
    background-color: #f6f7fb;
    box-shadow: 0px 1px 10px #00000029;
    border-top: 2px solid #5199cd;
}

.new-table .new-table-head {
    top: -1px;
    position: sticky;
    padding-right: 10px;
    z-index: 20;
}

.new-table-row-head {
    box-shadow: 0px 1px 3px #00000029;
    height: 6.5vh;
}

.new-table-header {
    text-align: center;
    background: #e9f6ff;
    padding: 10px 20px;
    color: #1d679e;
    font: normal normal normal 1.04vw/2.2vh 'Montserrat-SemiBold';
    border: 0.8px solid #e9e9e9;
}

.new-table-header-less-padding {
    text-align: center;
    background: #e9f6ff;
    padding: 5px;
    color: #1d679e;
    font: normal normal normal 1.04vw/2.2vh 'Montserrat-SemiBold';
    border: 0.8px solid #e9e9e9;
}

.new-table-header-filters {
    text-align: center;
    padding: 0;
    color: #1d679e;
    font: normal normal normal 0.9vw/2vh 'Montserrat-Regular';
    border-left: 0.8px solid #e9e9e9;
    border-right: 0.8px solid #e9e9e9;
    background-color: #fafafc;
}

.new-table-row {
    /* border-top: 0.8px solid #e9e9e9; */
    /* border-bottom: 0.8px solid #e9e9e9; */
    border-left: none;
    border-right: none;
    background: #fff;
}

.new-table-cell {
    border: 0.8px solid #e9e9e9;
    color: #707070;
}

.new-table-cell-less-padded{
    padding: 0 0 0 12px !important;
    vertical-align: middle !important;
    border: 0.8px solid #e9e9e9;
    color: #707070;
}

.new-table-cell-expanded {
    padding: 10px 1.4vh;
    border-top: 1px solid #5199cd;
    border: 0.5px solid #e9e9e9;
    border-bottom: 0.8px solid #5199cd;
    color: #000;
    font: normal normal normal 0.9vw/2vh 'Montserrat-Medium';
}

.new-table-cell-expanded:first-of-type {
    border-left: 0.8px solid #5199cd;
}

.new-table-cell-expanded:last-of-type {
    border-right: 0.8px solid #5199cd;
}

.new-table-row:last-child {
    border-bottom: none;
}

::-webkit-scrollbar {
    width: 5px;
    height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f3f3f3;
}

.table-wrap::-webkit-scrollbar-track {
    margin-top: 85px;
    background: #f1f1f1;
    border: 0 2px 6px rgba(0, 0, 0, 0.06);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #c0c0c0;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgb(123, 123, 123);
}

/*  Sort Icon Style */
.icon-sort {
    display: inline-block;
    vertical-align: 2px;
    margin-top: -2px;
    padding: 0 10px;
}

/* Input Filter Styles */

.column-filter-search {
    border: none;
    width: 100%;
    height: 34px;
    padding: 10px;
    color: #6b6b6b;
    background-color: #fafafc;
}

input[type='search']::-webkit-search-cancel-button {
    -webkit-appearance: none;
    height: 15px;
    width: 15px;
    background-image: url('../../../assets/images/cross-icons/clear-input.svg');
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}

.filter-table-search-icon {
    -webkit-appearance: none;
    height: 15px;
    width: 15px;
    background-image: url('../../../assets/images/search-icon.svg');
    position: absolute;
    right: 8px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    top: 50%;
    transform: translateY(-50%);
    /* right: 10px; */
}

.column-filter-search::placeholder {
    color: #6b6b6b;
    font: normal normal normal 0.9vw/2vh 'Montserrat-Regular';
    /* font-size: 14px; */
}

.custom-select-filter {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-image: url('../../../assets/images/select-menu-icon/downward-arrow-grey.svg');
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: calc(100% - 8px) center;

    border: none;
    width: 100%;
    height: 34px;
    padding: 10px;
    color: #6b6b6b;
    background-color: #fafafc;
}

.expand-cell-icon {
    visibility: hidden;
    cursor: pointer;
}

.new-table-body .new-table-row:hover .new-table-cell .expand-cell-icon {
    visibility: visible;
    transition: 0.5s ease;
}

.expanded-tr-container-row {
    border-top: 0.8px solid #5199cd;
    border-left: 1px solid #5199cd;
    border-right: 1px solid #5199cd;
    border-bottom: 1px solid #5199cd;
    background-color: #f6f7fb;
    box-shadow: 0px 1px 8px #00000029;
}

.expanded-tr {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.expanded-tr-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 1.2vh;
    color: #707070;
    border-bottom: 1px solid #e7e8ea;
    word-break: break-all;
}

.expanded-tr-item:last-of-type {
    border-bottom: none;
}

.expanded-tr-item > :first-child {
    flex: 1 0 15%;
    text-align: left;
    font: normal normal normal 0.9vw/2vh 'Montserrat-Medium';
}

.expanded-tr-item > :last-child {
    flex: 1 0 85%;
    text-align: left;
    font: normal normal normal 0.9vw/2vh 'Montserrat-Regular';
}
/* @media (min-width: 768px) and (max-width: 1024px) {
    .table-wrap {
        height: 60vh;
    }
}

@media (min-width: 1025px) and (max-width: 1280px) {
    .table-wrap {
        height: 75vh;
    }
}

@media (min-width: 1281px)  and (max-width: 1280px) {
    .table-wrap {
        height: 82vh;
    }
} */
