.share-panel-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1vh 1.5vw;
}

.share-panel-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 4.6vh;
    background-color: #e6f5ff;
    box-shadow: 0px 0px 5px #00000029;
    padding: 0.8rem;
}

.analysisrequestheader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 4.6vh;
    /* width: 35vw; */
    width: 100%;
    margin-bottom: 12px;
    border-radius: 3px;
    background-color: #e6f5ff;
    box-shadow: 0px 0px 5px #00000029;
    padding: 0.8rem;
}

.owneranalysistext {
    text-align: right;
    font: normal normal normal 1.04vw/2.22vh 'Montserrat-Medium';
    letter-spacing: 0.54px;
    color: #2c333e;
    opacity: 1;
}

.share-panel-name {
    font: normal normal normal 1.04vw/2.22vh 'Montserrat-Medium';
    letter-spacing: 0.6px;
}

.share-panel-name text:last-child {
    color: #1d679e;
}

.share-panel-content {
    display: flex;
    flex-direction: row;
    gap: 1.2vw;
    /* background: coral; */
    padding: 2vh 1.8vw;
}

.share-panel-left-wrapper {
    width: 35vw;
    /* height: 60vh; */
    box-shadow: 0px 0px 10px #00000029;
    border: 1px solid #5199cd;
    border-radius: 5px;
    position: relative;
}

.share-panel-right-wrapper {
    flex: 1;
    min-width: 0;
    /* height: 60vh; */
    box-shadow: 0px 0px 10px #00000029;
    border: 1px solid #5199cd;
    border-radius: 5px;
    position: relative;
}

.add-users-header,
.current-users-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 1.2vh 1vw;
}

.share-panel-search-table {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1vw;
    border: 1px solid #1d679e;
    padding: 0.4rem 0.3rem;
    border-radius: 5px;
}

.share-panel-search-input {
    width: 100%;
    border: none;
    font: normal normal normal 0.9vw/2vh 'Montserrat-Medium';
}
.share-panel-search-input::placeholder {
    font: normal normal normal 0.9vw/2vh 'Montserrat-Medium';
    color: #bbb;
}

.share-panel-left-footer,
.share-panel-right-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 1vw;
    width: 100%;
    position: sticky;
    bottom: 0;
    height: 4.6vh;
    background-color: #f5fbff;
    box-shadow: 0px -1px 3px #00000029;
    border-radius: 0 0 5px 5px;
}

.text-grey-style {
    color: #707070;
    font: normal normal normal 0.8vw/1.7vh 'Montserrat-Regular';
}

/* Button Styles */
.share-panel-buttons-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1.5vw;
}

.share-panel-submit {
    background-color: #707070;
    border-radius: 5px;
    width: fit-content;
    padding: 0.2rem 1vw;
    color: #fff;
    cursor: pointer;
    pointer-events: none;
    border: 3px solid #707070;
    min-width: 80px;
}

.share-panel-submit-active {
    background-color: #1d679e;
    border-radius: 5px;
    width: fit-content;
    padding: 0.2rem 1vw;
    color: #fff;
    cursor: pointer;
    border: 3px solid #1d679e;
}

.share-panel-cancel {
    width: fit-content;
    padding: 0.2rem 1vw;
    border-radius: 5px;
    border: 3px solid #1d679e;
    color: #1d679e;
    cursor: pointer;
    min-width: 80px;
}

.share-text-blue-style {
    font: normal normal normal 0.9vw/2vh 'Montserrat-Medium';
    color: #1d679e;
}

.share-text-style {
    font: normal normal normal 0.9vw/2vh 'Montserrat-Medium';
    /* color: #1d679e; */
}

.loading-no-current {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    font: normal normal normal 0.9vw/2vh 'Montserrat-Medium';
}

.analysis-request {
    /* width: 35vw; */
    width: 100%;
    /* height: 62vh; */
    box-shadow: 0px 0px 10px #00000029;
    border: 1px solid #5199cd;
    border-radius: 5px;
    position: relative;
}

.requestanalysis-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 4.6vh;
    background-color: #e6f5ff;
    box-shadow: 0px 0px 5px #00000029;
    padding: 0.8rem;
}

.requestanalysis-name-background {
    position: 'fixed';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    padding: auto;
    height: 100%;
    width: 100%;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.6);
}
.requestanalysis-name-background > div {
    z-index: 999;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 35%;
    height: fit-content;
    padding: 1.2rem;
    background-color: white;
    border-radius: 7px;
}
.requestanalysis-name-background > div > .edit-analysis-cancel-button > .edit-analysis-submit-button {
    z-index: 999;
    position: absolute;
    top: 0px;
    right: 100px;
    /* width: 40%; */
    height: fit-content;
    background-color: white;
    border-radius: 10px;
}
.requestanalysis-name-background > div > p {
    float: left;
    text-align: left;
    margin: 0px;
    width: fit-content;
    /* font-size: 1rem;
    font-weight: 600; */

    letter-spacing: 0.72px;
}
.requestanalysis-name-background > div > p > span {
    float: left;
    width: fit-content;
    text-align: left;
    margin-top: 2vh;

    color: #000000;
    opacity: 1;
    margin-right: 30px;
    font: normal normal 600 1.25vw/2.5vh 'Montserrat-Regular';
}

.requestanalysis-name-background > div {
    float: left;

    /* font-weight: 500;
    font-size: 20px; */

    box-shadow: 0px 0px 10px #00000029;
    border: 0px;
    border-radius: 5px;

    cursor: pointer;
}
.requestanalysis-name-background .edit-analysis-cancel-button  > .edit-analysis-submit-button{
    position: absolute;
    right: 0px;
    top: 0px;
    width: fit-content;
    cursor: pointer;
}

.analysis-submit {
    padding: 0.2rem 1rem !important;
    border-radius: 5px !important;
    border: 3px solid #1d679e;
    background-color: #1d679e !important;
    color: #fff !important;
    margin-left: 5%;
    margin-top: 5%;
    cursor: pointer;
    padding: 1.2rem;
    background-color: white;
    border-radius: 7px;
    /* background-color: #1d679e !important;
    border: 1px solid #fff;
    border-radius: 5px !important;
    padding: 0.2rem 1rem !important;
    height: 3.8vh;
    width: 7.2vw;
    color: #fff !important;
    cursor: pointer !important; */
}

.analysis-cancel {

    padding: 0.2rem 1rem !important;
    border-radius: 5px !important;
    border: 3px solid #1d679e;
    background-color: #fff !important;
    color: #1d679e !important;
    margin-left: 5%;
    margin-top: 5%;
    cursor: pointer;

    padding: 1.2rem;
    background-color: white;
    border-radius: 7px;
}

.request-cell-btn {
    position: relative;
    display: flex;
    align-items: center;
    height: 1.7vh;
    padding: 0 2px;
    border-radius: 2px;
    border: 1px solid #1d679e;
    color: #1d679e;
    font: normal normal normal 0.7vw/1.7vh 'Montserrat-Regular';
    cursor: pointer;
}

.accept-reject-wrapper {
    position: absolute;
    left: 50%;
    transform: translateX(-10%);
    bottom: 22px;
    z-index: 110;
}

.accept-reject-wrapper-zeroth{
    position: absolute;
    left: 50%;
    transform: translateX(-10%);
    bottom: -72px;
    z-index: 110;
}

.accept-reject-popup {
    position: relative;
    background: #f6f7fb;
    padding: 0.4rem 0.2rem;
    border: 1px solid #5199cd;
    border-radius: 3px;
    min-width: 13.5vw;
    min-height: 7.03vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
}

.accept-reject-arrow {
    position: absolute;
    border: 1px solid transparent;
    background: #f6f7fb;
    top: 7.2vh;
    left: 10px;
    border-bottom-color: #5199cd;
    border-right-color: #5199cd;
    width: 12px;
    height: 12px;
    transform: rotate(45deg);
    /* box-shadow: 0px 5px 5px #00000029; */
}

.accept-reject-arrow-zeroth{
    position: absolute;
    border: 1px solid transparent;
    background: #f6f7fb;
    bottom: 7.2vh;
    left: 10px;
    border-top-color: #5199cd;
    border-left-color: #5199cd;
    width: 12px;
    height: 12px;
    transform: rotate(45deg);
}

.accept-reject-rqst-by,
.accept-reject-access-type,
.accept-reject-btn {
    display: flex;
    flex-direction: row;
}

.accept-reject-rqst-by text:first-child,
.accept-reject-access-type text:first-child {
    color: #000;
    font: normal normal normal 0.6vw/1.8vh 'Montserrat-Regular';
}

.accept-reject-rqst-by text:last-child,
.accept-reject-access-type text:last-child {
    color: #000;
    font: normal normal normal 0.6vw/1.8vh 'Montserrat-SemiBold';
}

.accept-reject-btn {
    align-self: center;
    display: flex;
    gap: 10px;
    padding-top: 0.2rem;
}

.accept-reject-btn div:first-child {
    background-color: #1d679e;
    border-radius: 3px;
    padding: 0.1rem;
    width: 4vw;
    color: #fff;
    cursor: pointer;
    text-align: center;
}

.accept-reject-btn div:last-child {
    width: 4vw;
    padding: 0.1rem;
    border-radius: 3px;
    border: 1px solid #1d679e;
    color: #1d679e;
    cursor: pointer;
    text-align: center;
}
.table_header_saved_filter{
    background-color: #f5fbff;
    color: #1D679E !important;
    font: normal normal medium 0.83vw Montserrat !important;
}

.search_filter_table>tbody>tr{
    background-color: transparent !important;
}