.accordian-content > .report-duration {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 4vh;
    margin-top: 4vh;
}

.accordian-content > .date-criteria {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1.5vh;
}

.accordian-content > .reset-div {
    position: relative;
    top: 0;
    text-align: right;
    color: #5199cd;
    font: normal normal normal 0.72vw/1.3vh 'Montserrat-Medium';
    cursor: pointer;
}

.accordian-content > .reset-div:hover {
    font-weight: 600;
}

.accordian-content .report-duration div:first-child > text {
    font: normal normal normal 1.04vw/2.22vh 'Montserrat-Medium';
}

.accordian-content .report-duration div:nth-child(2) {
    align-items: center;
}

.accordian-content .report-duration div:nth-child(2) > text {
    color: #bbbbbb;
    font: normal normal normal 1.04vw/2.22vh 'Montserrat-Regular';
}

.accordian-content .date-criteria div:first-child > text {
    font: normal normal normal 1.04vw/2.22vh 'Montserrat-Medium';
}

.accordian-content .date-criteria > div:nth-child(2) {
    align-items: center;
}

.radio-custom-ssa {
    width: 15px;
    height: 15px;
}

.accordian-content .date-criteria div:nth-child(2) .form-group-radio > label {
    font: normal normal normal 1.04vw/2.22vh 'Montserrat-Regular';
    margin: auto 0.57vw;
}

.select-ssa > select {
    position: relative;
}

.select-ssa > select {
    width: 18.7vw;
    height: 4.1vh;
    margin-right: 1.5vw;

    border: 1px solid #bbbbbb;
    border-radius: 5px;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-image: url('../../../../assets/images/ssa-misc/select-icons/grey-down.svg');
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: calc(100% - 1.04vw) center;
    padding-left: 1.04vw;
    color: #bbb;
    font: normal normal normal 0.83vw/1.7vh 'Montserrat-Regular';
    cursor: pointer;
}

.select-ssa select > option {
    background-color: #e6f5ff;
    padding: 10px !important;
    border-bottom: 2px solid #fff;
    color: #1d679e;
}

.select-ssa > select:hover {
    border: 1px solid #1d679e;
    color: #1d679e;
    background-image: url('../../../../assets/images/ssa-misc/select-icons/blue-down.svg');
}

.date-input-ssa {
    height: 4.1vh;
    margin-right: 1.5vw;
    color: #bbb;
    font: normal normal normal 0.83vw/1.7vh 'Montserrat-Regular';
    cursor: pointer;
    border: 1px solid #bbbbbb;
    border-radius: 5px;
    padding: auto 1.04vw;
    appearance: none;
}

.date-input-ssa:hover {
    border: 1px solid #1d679e;
    color: #1d679e;
}

/* Granularity */

.accordian-content .tab-row:first-child {
    margin-top: 1.5vh;
}

.accordian-content .tab-row:last-child {
    margin-bottom: 1.5vh;
}

.tab-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 4vh;
    margin-bottom: 4vh;
}

.tab-row .tab-row-child {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.tab-row .tab-row-child > text:first-child {
    font: normal normal normal 1.04vw/2.22vh 'Montserrat-Medium';
    margin-bottom: 2px;
}

.tab-row .tab-row-child > text:nth-child(2) {
    color: #707070;
    font: normal normal normal 0.62vw/1.38vh 'Montserrat-Medium';
}

.accordian-content .tab-row > div:nth-child(2) {
    height: 100%;
    align-items: center;
    justify-content: flex-start;
    gap: 1.1vh;
}
.accordian-content .tab-row div:nth-child(2) div > label {
    font: normal normal normal 1.04vw/2.22vh 'Montserrat-Regular';
    margin: auto 2vw;
}

/* Checkbox Styles */

.form-group {
    display: block;
}

.form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

.form-group label {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.form-group label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 1px solid #216ba1;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    display: inline-block;
    cursor: pointer;
    height: 1.1rem;
    width: 1.1rem;
    margin-right: 5px;
}

.form-group label::before input:checked {
    border: 1px solid #216ba1;
}

.form-group input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 6px;
    width: 6px;
    height: 14px;
    border: 1px solid #216ba1;
    border-width: 0 2px 2px 0;
    transform: rotate(40deg);
}

@media (min-width: 2068px) and (max-width: 2220px) {
    .form-group label:before {
        height: 1.3rem;
        width: 1.3rem;
    }

    .form-group input:checked + label:after {
        top: -1px;
        left: 6px;
        width: 8px;
        height: 17px;
    }
}

@media (min-width: 2220px) and (max-width: 2567px) {
    .form-group label:before {
        height: 1.3rem;
        width: 1.3rem;
    }

    .form-group input:checked + label:after {
        top: 2px;
        left: 6px;
        width: 8px;
        height: 17px;
    }
}

@media (min-width: 2567px) and (max-width: 3000px) {
    .form-group label:before {
        height: 1.3rem;
        width: 1.3rem;
    }

    .form-group input:checked + label:after {
        top: 2px;
        left: 6px;
        width: 8px;
        height: 17px;
    }
}
@media (min-width: 3000px) and (max-width: 3500px) {
    .form-group label:before {
        height: 1.5rem;
        width: 1.5rem;
    }

    .form-group input:checked + label:after {
        top: 4px;
        left: 6px;
        width: 9px;
        height: 19px;
    }
}
@media (min-width: 3500px) {
    .form-group label:before {
        height: 1.7rem;
        width: 1.7rem;
    }

    .form-group input:checked + label:after {
        top: 3px;
        left: 8px;
        width: 11px;
        height: 21px;
    }
}

/* Radio Styles */

.form-group-radio {
    display: block;
}

.form-group-radio input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

.form-group-radio label {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.form-group-radio label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 1px solid #216ba1;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    display: inline-block;
    cursor: pointer;
    height: 1.1rem;
    width: 1.1rem;
    border-radius: 100%;
    margin-right: 5px;
}

.form-group-radio label::before input:checked {
    border: 1px solid #216ba1;
}

.form-group-radio input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    /* top: calc(0.18rem +1%); */
    left: 0.194rem;
    width: 0.7rem;
    height: 0.7rem;
    border: 1px solid #216ba1;
    background-color: #216ba1;
    border-radius: 100%;
    transform: rotate(45deg);
}

@media (min-width: 2068px) and (max-width: 2567px) {
    .form-group-radio label:before {
        height: 1.2rem;
        width: 1.2rem;
        border: 2px solid #216ba1;
    }
    .form-group-radio input:checked + label:after {
        width: 0.8rem;
        height: 0.8rem;
        border: 2px solid #216ba1;
    }
}
@media (min-width: 2568px) and (max-width: 3000px) {
    .form-group-radio label:before {
        height: 1.4rem;
        width: 1.4rem;
        border: 2px solid #216ba1;
    }
    .form-group-radio input:checked + label:after {
        width: 1rem;
        height: 1rem;
        border: 2px solid #216ba1;
        /* left: 0.22rem; */
    }
}
@media (min-width: 3000px) {
    .form-group-radio label:before {
        height: 1.7rem;
        width: 1.7rem;
        border: 2px solid #216ba1;
    }
    .form-group-radio input:checked + label:after {
        width: 1.2rem;
        height: 1.2rem;
        border: 2px solid #216ba1;
        left: 0.25rem;
    }
}

/* Button Checkbox Row Styles */

.button-form-group label {
    min-width: 6.5rem;
    padding: 5px 10px;
    text-align: center;
    border: 1px solid #216ba1;
    display: flex;
    align-items: center;
    justify-content: center;
    font: normal normal normal 1.04vw/2.5vh 'Montserrat-Regular';
    cursor: pointer;
}

.button-form-group input:checked ~ label {
    background-color: #216ba1;
    color: #fff;
}

.slider-input {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 3px;
    width: 13.02vw;
    font: normal normal normal 1.04vw/2.5vh 'Montserrat-Regular';
}

.slider-input:hover {
    border: 1px solid #1d679e;
}
