.login-outer-div {
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  // height: 317px;
  // width: 470px;
}

.login-wrapp {
  min-width: 33vw;
  //height: 70vh;
  padding: 70px 0px 50px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  border-radius: 30px;
  background-color: #ffffff;

  .app-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      //width: 24vw;
      height: 10vh;
      padding: 0 125px 0 125px;
    }
  }
}
.position-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.thumbnail {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  padding: 60px 0 50px 0;
}
.thumbnail img {
  display: flex;
  width: 100%;
  height: 15vh;
}
.message {
  margin: 53px 20px 0px 20px;
  color: #103e30;
  font-family: "Montserrat-Medium";
  font-size: 18px;
  font-weight: 500;
}
.message a {
  text-decoration: none;
  font-weight: 600;
  font-family: "Montserrat-SemiBold";
  color: #005b96;
}
.bg {
  background: linear-gradient(to top, #2c333e 0%, #72767e 100%);
}

.login_btn {
  // width: 13vw;
  height: 7vh;
  padding: 0 80px 0 80px;
  border-radius: 500px;
  border: 2px solid #5199CD;
  background-color: #f3faff;
  color: #5199CD;
  font-size: 22px;
  font-weight: 600;
  font-style: normal;
}

// ----------------------iPad Potrait Css Starts------------------------
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .login-wrapp {
    min-width: 60vw;
    height: 60vh;
    .app-logo {
      img {
        height: 9vh;
        padding: 0px;
      }
    }
  }
  .login_btn {
    height: 5vh;
    padding: 0 70px 0 70px;
  }
}
// ----------------------iPad Potrait Css Ends------------------------
// ----------------------iPad Lanscape Css Starts------------------------
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .login-wrapp {
    min-width: 45vw;
    height: 65vh;
    .app-logo {
      img {
        height: 10vh;
        padding: 0px;
      }
    }
  }
}
// ----------------------iPad Lanscape Css Ends------------------------
// ----------------------iPad Pro Potrait Css Starts------------------------
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
}

// ----------------------iPad Pro Potrait Css Ends------------------------
// ----------------------iPad Pro Lanscape Css Starts------------------------
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .thumbnail {
    .login-wrapp {
      height: 60vh;
      .app-logo {
        img {
          height: 9vh;
          padding: 0px;
        }
      }
    }
    padding: 50px 0 50px 0;
  }
}
// ----------------------iPad Lanscape Css Ends------------------------

.container-fluid {
  .sub-container {
    height: 100vh;
    width: 100vw;
    position: relative;
    .top-section,
    .bottom-section {
      height: 50vh;
      width: 100vw;
    }
    .login-wrapper {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      .login-sub-wrapper {
        // height: 50vh;
        width: 516px;
        max-width: 516px;
        border-radius: 15px;
        box-shadow: 0 0 60px 0px rgba(0, 0, 0, 0.3);
        background-color: #ffffff;
        .app-logo {
          // max-width: 300px;
          // max-height: 88px;
          padding: 55px 108px 50px 108px;
          img {
            width: 50%;
            height: 100%;
          }
        }
        .input-field-wrapper {
          width: 100%;
          padding: 0px 43px;
          .input-form {
            width: 100%;
            margin-bottom: 20px;
            position: relative;
            .p-inputtext {
              width: 100% !important;
              border-radius: 50px !important;
              padding-left: 28px;
              padding-right: 43px;
              font-family: "Montserrat-Regular";
              font-size: 16px !important;
              color: #000 !important;
              border-color: #9d9d9d !important;
            }
            .p-inputtext:focus {
              box-shadow: none;
              outline: none !important;
              border-color: #9d9d9d !important;
            }
            .p-inputtext:hover {
              box-shadow: none;
              outline: none !important;
              border-color: #9d9d9d !important;
            }
            .p-float-label > label {
              padding-left: 20px !important;
            }
            .p-float-label > label:focus {
              color: #6b6b6b;
              font-size: 16px !important;
              font-family: "Montserrat-Regular";
            }
            .p-float-label input:focus ~ label,
            .p-float-label input.p-filled ~ label,
            .p-float-label textarea:focus ~ label,
            .p-float-label textarea.p-filled ~ label,
            .p-float-label .p-inputwrapper-focus ~ label,
            .p-float-label .p-inputwrapper-filled ~ label {
              top: 2px !important;
              left: 20px;
              font-size: 12px;
              font-family: "Montserrat-Regular";
              background-color: #ffffff;
              color: #6b6b6b;
              padding: 0px 4px !important;
            }
            .eye-icons {
              position: absolute;
              top: 7px;
              right: 17px;
              img {
                cursor: pointer;
              }
            }
          }
          .btn-wrapper {
            padding: 20px 0px 20px 0px;
            .p-button {
              width: 100% !important;
              background: #5199CD;
              font-size: 16px !important;
              font-weight: 600;
              border-radius: 50px !important;
            }
          }
        }
        .bottom-message-wrapper {
          font-size: 18px;
          font-family: "Montserrat-Medium";
          padding-bottom: 53px;
          .normal-message-text {
            color: #000;
            margin-right: 8px;
          }
          .blue-message-text {
            color: #5199CD;
            cursor: pointer;
          }
        }
      }
    }
  }
}

#login-bg {
  overflow: auto;
  img {
    position: fixed;
  }
}
#login-bg::-webkit-scrollbar {
  display: none;
}

// Loader====>

.loader-overlay {
  background-color: #000000;
  //opacity: 0.05;
  bottom: 0;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;
}
.loader2 {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1110 !important;
  left: 0 !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
}

.loader2-parent {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
}
