.popup-background {
    position: absolute;
    margin: auto;
    padding: auto;
    top: 0px;
    height: 100%;
    width: 100%;
    z-index: 10000;
    background-color: rgba(0, 0, 0, 0.6);
}
.popup-background > .popup-content {
    z-index: 99999;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* width: min-content; */
    height: fit-content;
    padding: 1.2rem;
    border-radius: 7px;
}
.popup-background > .popup-content > .edit-analysis-field {
    background-color: rgb(134, 26, 26);
    width: 30%;
    height: 50%;
    background-color: white;
   
}
.popup-background > .popup-content > .edit-analysis-field > p {
    background-color: #e6f5ff;
}

.popup-background > .popup-content > .requestanalysis-field {
    background-color: rgb(134, 26, 26);
    width: 30%;
    height: 50%;
    background-color: white;
   
}
.popup-background > .popup-content > .requestanalysis-field > p {
    background-color: #e6f5ff;
}