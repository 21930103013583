.App {
    text-align: center;
    height: 100%;
    overflow-x: hidden;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

body > iframe {
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}
.popup_menu {
    z-index: 1000 !important;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

html,
body {
    height: 100%;
    width: 100%;
}
#root {
    height: 100%;
}
.app-bg {
    padding: 0;
    box-shadow: 0 3px 6px rgba(39, 45, 59, 0.2);
}
.app-white-bg {
    background-color: #ffffff;
    padding: 2rem 1rem;
}
.app-blue-bg {
    background-image: linear-gradient(180deg, #349cdf 0%, #005b96 100%);
    padding: 2rem 1rem;
}

.common-outline-btn {
    padding: 15px 30px;
    border-radius: 10px;
    border: 2px solid #005b96;
    background-color: #f3faff;
    color: #005b96;
    font-family: 'Montserrat-Regular';
    font-size: 25px;
    font-weight: 600;
}
.lg-btn-cls {
    width: 250px;
}

/* Chart modebar */
.modebar {
    top: 28px !important;
    right: 10px !important;
}
/* ----------Spinner Css start----------------*/
@keyframes ui-progress-spinner-color {
    100%,
    0% {
        stroke: #3d3635;
    }
    40% {
        stroke: #3d3635;
    }
    66% {
        stroke: #3d3635;
    }
    80%,
    90% {
        stroke: #3d3635;
    }
}

.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
    box-shadow: none !important;
}
.p-dialog-mask .p-component-overlay {
    z-index: 0 !important;
}
.p-sidebar-mask.p-component-overlay {
    z-index: 0 !important;
}
/* ----------Spinner Css end---------------- */

/* ----------Chart Css start---------------- */

.chart-title {
    text-align: left !important;
    font-size: 16px;
    font-weight: 600;
    position: absolute;
    top: 15px;
    left: 20px;
    line-height: 16px;
    font-family: 'Montserrat-SemiBold' !important;
    z-index: 3;
}

.modal-backdrop {
    z-index: 9999;
}

.modal {
    z-index: 9999;
}

.modal-title {
    text-align: left !important;
    font-size: 22px;
    font-weight: 600;
    top: 15px;
    left: 20px;
    font-family: 'Montserrat-SemiBold' !important;
    z-index: 3;
}

.graph-title-modal {
    top: 70px !important;
    left: 24px !important;
}

#chartDiv {
    overflow: hidden;
}

.show-modal .modal-dialog {
    top: 50% !important;
    transform: translateY(-50%) !important;
}
/* ----------Chart Css end---------------- */
