.share-panel-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1vh 1.5vw;
}

.share-panel-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 4.6vh;
    background-color: #e6f5ff;
    box-shadow: 0px 0px 5px #00000029;
    padding: 0.8rem;
}

.share-panel-name {
    font: normal normal normal 1.04vw/2.22vh 'Montserrat-Medium';
    letter-spacing: 0.6px;
}

.share-panel-name text:last-child {
    color: #1d679e;
}

.share-panel-content {
    display: flex;
    flex-direction: row;
    gap: 1.2vw;
    /* background: coral; */
    padding: 2vh 1.8vw;
}

.share-panel-left-wrapper {
    width: 35vw;
    height: 60vh;
    box-shadow: 0px 0px 10px #00000029;
    border: 1px solid #5199cd;
    border-radius: 5px;
    position: relative;
    padding-bottom: 4.6vh;
}

.share-panel-right-wrapper {
    flex: 1;
    min-width: 0;
    height: 60vh;
    padding-bottom: 4.6vh;
    box-shadow: 0px 0px 10px #00000029;
    border: 1px solid #5199cd;
    border-radius: 5px;
    position: relative;
}
.groupTableHeight {
    height: calc(100% - 60px);
}

.add-users-header,
.current-users-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 1.2vh 1vw;
}

.share-panel-search-table {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1vw;
    border: 1px solid #1d679e;
    padding: 0.4rem 0.3rem;
    border-radius: 5px;
}

.share-panel-search-input {
    width: 100%;
    border: none;
    font: normal normal normal 0.9vw/2vh 'Montserrat-Medium';
}
.share-panel-search-input::placeholder {
    font: normal normal normal 0.9vw/2vh 'Montserrat-Medium';
    color: #bbb;
}

.share-panel-left-footer,
.share-panel-right-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 1vw;
    width: 100%;
    position: sticky;
    bottom: 0;
    height: 4.6vh;
    background-color: #f5fbff;
    box-shadow: 0px -1px 3px #00000029;
    border-radius: 0 0 5px 5px;
}

.text-grey-style {
    color: #707070;
    font: normal normal normal 0.8vw/1.7vh 'Montserrat-Regular';
}

/* Button Styles */
.share-panel-buttons-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1.5vw;
}

.share-panel-submit {
    background-color: #707070;
    border-radius: 5px;
    padding: 0.2rem 1rem;
    width: 7vw;
    color: #fff;
    cursor: pointer;
}

.share-panel-cancel {
    width: 7vw;
    padding: 0.2rem 1rem;
    border-radius: 5px;
    border: 3px solid #1d679e;
    color: #1d679e;
    cursor: pointer;
}

.share-text-blue-style {
    font: normal normal normal 0.9vw/2vh 'Montserrat-Medium';
    color: #1d679e;
}

.share-text-style {
    font: normal normal normal 0.9vw/2vh 'Montserrat-Medium';
    /* color: #1d679e; */
}

.current-group-list {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #00000029;
    border: 1px solid #5199cd;
    border-radius: 5px;
    opacity: 1;
    margin: 1.5vw 1.4vw 1vw 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 70vh;
    overflow-y: auto;
    overflow-x: hidden;
    /* padding: 0.8vw; */
}

.division-line {
    box-shadow: inset 0px 3px 10px #00000029;
    border: 1px solid #e6f5ff;
    border-radius: 5px;
    opacity: 1;
    width: 0.2vw;
    margin-top: 4vw;
    margin-bottom: 4vw;
}

.group-details {
    background: #ffffff 0% 0% no-repeat;
    box-shadow: 0px 0px 10px #00000029;
    border-radius: 10px;
    opacity: 1;
    margin: 1.5vw 0px 1vw 1.5vw;
    width: 100%;
    flex: 3;
    /* display: flex;
    justify-content:space-around;
    align-items: center; */
    /* align-self: center; */
}
.group-mgt-name {
    font: normal normal normal 1.04vw/2.22vh 'Montserrat-Medium';
    letter-spacing: 0.6px;
    color: #000;
}

.group-mgt-content {
    display: flex;
    min-height: 25vw;
}

.div-line {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #bbbbbb;
    opacity: 1;
}

.create-group-heading {
    text-align: left;
    font: normal normal medium 18px/22px 'Montserrat-Regular';
    letter-spacing: 0.54px;
    color: #000000;
    opacity: 1;
    padding: 1vw;
    margin: 0px;
    font-weight: bold;
}
.current-groups {
    background: #f6f7fb 0% 0% no-repeat padding-box;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5vw;
    /* height:300px; */
    /* overflow-y:hidden; */
}

.group-member-count {
    background: #5199cd 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 0.5;
    color: #ffffff;
    /* width: 2vw; */
    height: 1.5vw;
    margin-right: 0.5vw;
    font-size: 1vw;
    padding-inline: 0.5vw;
}

.group-name {
    text-align: left;
    font: normal normal medium 16px/19px 'Montserrat-Regular';
    letter-spacing: 0.48px;
    color: #1d679e;
    opacity: 1;
    font-size: 1vw;
}

.create-group-footer {
    background: #f5fbff 0% 0% no-repeat padding-box;
    box-shadow: 0px -1px 3px #00000029;
    border-radius: 0px 0px 5px 5px;
    opacity: 1;
    text-align: left;
    font: normal normal normal 16px/19px 'Montserrat-Regular';
    letter-spacing: 0.48px;
    color: #707070;
    opacity: 1;
    padding: 0.5vw;
    height: 4vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.create-btn {
    background: #1d679e 0% 0% no-repeat padding-box;
    border-radius: 3px;
    opacity: 1;
    border-color: #1d679e;
    box-shadow: 0px #ffffff;
    text-align: center;
    font: normal normal normal 14px/18px 'Montserrat-Regular';
    letter-spacing: 0.42px;
    color: #ffffff;
    opacity: 1;
    border: 0px;
    padding: 2px;
}

.create-btn-cpy {
    background: #1d679e 0% 0% no-repeat padding-box;
    border-radius: 3px;
    opacity: 1;
    border-color: #1d679e;
    box-shadow: 0px #ffffff;
    text-align: center;
    font: normal normal normal 14px/18px 'Montserrat-Regular';
    letter-spacing: 0.42px;
    color: #ffffff;
    opacity: 1;
    border: 0px;
    padding: 4px 6px;
    font-weight: bold;
}

.group-name-container {
    width: fit-content;
    /* float: left; */
    font-weight: 600;
    position: relative;
    margin-top: 16px;
    margin-left: 1vw;
}

.group-name-container p {
    /* margin-bottom: 0px !important; */
    font: normal normal 600 'Montserrat-Regular';
}
.group-name-container span {
    position: absolute;
    right: -20px;
    top: -20px;
}

.edit-group-name {
    text-align: left;
    font: normal normal medium 18px/22px 'Montserrat-Regular';
    letter-spacing: 0.54px;
    color: #2c333e;
    opacity: 1;
}

.grp-mgt-right-container {
    display: flex;
    /* justify-content: space-evenly; */
    /* align-items: center; */
}

.group-members-container {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #00000029;
    border: 1px solid #5199cd;
    border-radius: 5px;
    opacity: 1;
}

.analysis-list-container {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #00000029;
    border: 1px solid #5199cd;
    border-radius: 5px;
    opacity: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.group-popup-heading {
    text-align: left;
    font: normal normal 600 20px/24px 'Montserrat-Regular';
    letter-spacing: 0.6px;
    opacity: 1;
}

.create-group-container {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #00000029;
    border: 1px solid #5199cd;
    border-radius: 5px;
    opacity: 1;
    width: 100%;
    /* height: 60.8vh; */
}

.popup-container {
    position: 'fixed';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    padding: auto;
    height: 100%;
    width: 100%;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.6);
}
.popup-container > div {
    z-index: 999;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 45%;
    height: fit-content;
    padding: 1.2rem;
    background-color: white;
    border-radius: 7px;
}
.popup-container > div > .edit-analysis-cancel-button {
    z-index: 999;
    position: absolute;
    top: 0px;
    right: 100px;
    /* width: 40%; */
    height: fit-content;
    background-color: white;
    border-radius: 10px;
}
.popup-container > div > p {
    float: left;
    text-align: left;
    margin: 0px;
    width: fit-content;
    /* font-size: 1rem;
    font-weight: 600; */

    letter-spacing: 0.72px;
}
.popup-container > div > p > span {
    float: left;
    width: fit-content;
    text-align: left;
    margin-top: 2vh;
    /* font-size: 1rem;
    font-weight: 600;
    letter-spacing: 0.72px; */
    color: #000000;
    opacity: 1;
    margin-right: 30px;
    font: normal normal 600 1.25vw/2.5vh 'Montserrat-Regular';
}

.popup-container > div button {
    float: left;
    padding: 5px 32px;
    /* font-weight: 500;
    font-size: 20px; */
    background-color: #1d679e;
    color: white;
    box-shadow: 0px 0px 10px #00000029;
    border: 0px;
    border-radius: 5px;
    /* margin-top: 1vh; */
    cursor: pointer;
}
.popup-container .edit-analysis-cancel-button {
    position: absolute;
    right: 0px;
    top: 0px;
    width: fit-content;
    cursor: pointer;
}

.filter-dropdown-arrow {
    padding: 5px;
    position: absolute;
    /* background-color: #1d679e; */
    -ms-transform: rotate(45deg);
    /* -ms-transform-origin: 20% 40%; IE 9 */
    transform: rotate(45deg);
    /* transform-origin: 20% 40%; */
    right: 1.2vw;
    top: 50px;
    /* background: #f5fbff 0% 0% no-repeat padding-box; */
    box-shadow: 0px 0px 5px #00000029;
    border: 1px solid #5199cd;
    opacity: 1;
}

.filter-dropdown-list {
    position: absolute;
    z-index: 100;
    right: 0.5vw;
    top: 55px;
    width: auto;
    height: fit-content;
    background: #f5fbff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #00000029;
    border: 1px solid #5199cd;
    border-radius: 3px;
    opacity: 1;
}
.filter-dropdown-list p {
    /* width: 180px; */
    height: fit-content;
    margin: 0px;
    padding: 10px;
    font-size: 15px;
    font-weight: 500;
    /* color: #1d679e; */
    cursor: pointer;
    text-align: center;
    font: normal normal normal 16px/20px 'Montserrat-Regular';
    letter-spacing: 0px;
    color: #000000;
    display: flex;
    align-items: center;
}
.filter-dropdown-list p p {
    /* background-color: 'cyan'; */
    text-align: center;
    font: normal normal normal 10px/13px 'Montserrat-Regular';
    letter-spacing: 0.3px;
    color: #ffffff;
    opacity: 1;
    border-radius: 3px;
    height: 2vh;
    margin-right: 0.5vw;
    /* display: flex; */
    /* padding-inline: 0.5vw; */
    /* font-size: 0.9vw; */
    align-items: center;
    width: 2.5vw;
    justify-content: center;
    max-width: fit-content;
}
/* .filter-dropdown-list p:hover {
    background-color: #e6f5ff;
    width: 99%;
} */

.button-container-cpy {
    padding: 6px 12px;
    border-radius: 5px;
    color: #fff;
    background-color: #1d679e;
}

.button-container-cpy:disabled {
    background-color: #808080;
    pointer-events: none;
    box-shadow: 0 0 0 1px #808080;
}

.createGrpPH::placeholder {
    font-size: 1rem;
}
