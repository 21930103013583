/* style for AppHeader.js start */
header {
    height: 70px;
    box-shadow: 0 1px 6px rgba(39, 45, 59, 0.2);
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    //margin-bottom: 8px;
}
/* App company img */

/* xyz company img */
.company-logo {
    height: 47px;
    // border-radius: 24px;
    margin-right: 10px;
}
// he

header h4 {
    color: #000000;
    font-family: 'Montserrat-SemiBold';
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 0px;
}

header img {
    text-align: left;
    height: 40px;
    margin: 0px 20px;
}
/* style for AppHeader.js End */
/* style for inner container ends */
.main-container {
    display: flex;
    width: 100%;
    height: 100%;
    height: -webkit-calc(100% - 70px);
    height: -moz-calc(100% - 70px);
    height: calc(100% - 70px);
    background-color: #f6f7fb;
}

.inner-container {
    flex-grow: 1;
    width: 100%;
    // background: #000000;
    // display: flex;
    // flex-direction: column;
    // overflow: auto;
    overflow-y: auto;
    overflow-x: hidden;
}
.inner-container::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

.inner-container::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.inner-container::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #c0c0c0;
}

.sub-header {
    margin-top: 10px;
    padding: 5px 0 5px 0;
    font-family: 'Montserrat-SemiBold';
    font-size: 16px;
    font-weight: 600;
    background-color: #ffffff;
}

.header-contents {
    height: auto;
    background: #f6f7fb;
    box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.2) !important;
    position: sticky;
    top: 0;
    position: initial;
    background: #f6f7fb;
}

.header-main-parent-div {
    overflow: auto;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}
// need to be moved on UAT
.header-main-parent-div .site-footer {
    position: fixed;
    bottom: -5px;
    width: 100%;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #bbbbbb;
    opacity: 1;
    text-align: left;
    float: left;
    z-index: 20;
}
.header-main-parent-div .site-footer span {
    float: left;
    margin-left: 30px;

    font-size: 16px;
    font-weight: 600;
    width: fit-content;
}
.header-main-parent-div .site-footer text {
    width: fit-content;
    float: left;
    margin: 0px;
    vertical-align: middle;
    font-size: 10px;
    text-align: left;
    margin-left: 10px;
    margin-top: 4px;
}
// need to be moved on UAT
.header-main-parent-div .site-footer {
    position: fixed;
    bottom: -5px;
    width: 100%;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #bbbbbb;
    opacity: 1;
    text-align: left;
    float: left;
}
.header-main-parent-div .site-footer span {
    float: left;
    margin-left: 30px;

    font-size: 16px;
    font-weight: 600;
    width: fit-content;
}
.header-main-parent-div .site-footer text {
    width: fit-content;
    float: left;
    margin: 0px;
    vertical-align: middle;
    font-size: 10px;
    text-align: left;
    margin-left: 10px;
    margin-top: 4px;
}
/* style for inner container ends */
/* style for profile container starts */
.p-card .p-card-content {
    padding: 0 !important;
}
.p-card .p-card-body {
    padding: 0 !important;
}
.profile-card {
    padding: 23px;
    z-index: 99999;
    background-color: white;
    position: absolute;
    right: 18px;
    top: 59px;

    .p-avatar-text {
        font-family: 'Montserrat-SemiBold';
        font-size: 25px;
    }
    .user-name {
        padding-bottom: 6px;
        font-family: 'Montserrat-SemiBold';
        font-size: 15px;
    }
    .user-email {
        padding-bottom: 30px;
        font-family: 'Montserrat-Medium';
        font-size: 13px;
        color: #9d9d9d;
    }
    .profile-links {
        padding-left: 11px;
        color: #3b8bc6;
        font-family: 'Montserrat-Medium';
        font-size: 16px;
        text-decoration: none;
        a:hover {
            text-decoration: none;
        }
    }
    .profile-links:hover {
        text-decoration: none;
    }
    .profile-links-parent {
        padding-bottom: 20px;
        display: flex;
        align-items: center;
    }
}
.link-container {
    text-align: left;
    :last-child {
        display: flex;
        align-items: center;
    }
}

/* style for profile container ends */

/* style for Breadcrum.js Start */
.breadcrum-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 36px;
    //padding: 10px 0;
}
.breadcrum {
    height: 28px;
    display: flex;
    align-items: center;
    //padding-bottom: 4px;
    margin: 0px 20px;
}
.breadcrum > div {
    color: #3b8bc6 !important;
}

.breadcrum > :last-child div {
    font-family: 'Montserrat-SemiBold' !important;
    font-weight: 600 !important;
    color: #000000 !important;
}

.breadcrum span {
    display: inline-flex;
    align-items: center;
}

.breadcrum span div {
    color: #000000;
    font-family: 'Montserrat-Medium';
    font-size: 14px;
    font-weight: 500;
}

.breadcrum span img {
    transform: rotate(270deg);
    height: 15px;
    margin-top: 2px;
    margin: 0px 7px 0px 6px;
}

.breadcrumb-link {
    cursor: pointer;
    color: #3b8bc6 !important;
}
.breadcrumb-link2 {
    pointer-events: none;
}

.contact-container {
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    span {
        color: #3b8bc6;
        font-family: 'Montserrat-SemiBold';
        font-size: 14px;
        font-weight: 600;
        &:hover {
            text-decoration: none !important;
        }
    }
}
#contact-us-icon {
    margin-right: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2px;
}
/* style for Breadcrum.js End */

/* style for Carousel.js Start */
.carousel {
    //height: 90px !important;
    padding: 10px 0 10px 0;
    margin: 0px 20px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06);
    background-color: #ffffff;
    display: flex;
}
/* .c-card-view {
  } */
.c-card {
    min-width: 225px; //250px;//300px;
    max-width: 300px;
    display: inline-block;
    /* flex-direction: column; */
    /* justify-content: space-between; */
    border-left: 2px solid #f1f1f1;
    //margin-right: 10px;
    padding-left: 15px;

    /* overflow-x: scroll; */
}
.c-card-view :first-child {
    border: none !important;
}

.c-card-view {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
}
.span1 {
    background-color: #3b8bc6;
    width: 20px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    display: flex;
    transform: rotate(180deg);
    cursor: pointer;
}
.span2 {
    width: 20px;
    height: 100%;
    box-shadow: -2px 0 6px rgba(0, 0, 0, 0.06);
    background-color: #3b8bc6;
    position: absolute;
    right: 0;
    height: 100%;
    top: 0;
    border: 0px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    display: flex;
}

.c-card-view :last-child {
    border-right: 0px;
}

.scrollbar {
    overflow: auto;
}

.scrollbar::-webkit-scrollbar {
    height: 0px;
}

.scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.scrollbar::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #c0c0c0;
}
/* style for Carousel.js Start */

/* style for Filter.js Start */
.filter-parent-container {
    position: relative;
}
.filter-container {
    margin: 5px 20px;
    display: flex;
    width: fit-content;
    /* margin-left: auto; */
    justify-content: flex-end;
    align-items: center;
}

.filter-container img {
    cursor: pointer;
}

.std-rpt-card-icon {
    vertical-align: top;
    width: 50px;
    height: 50px;
}
.std-rpt-card-icon-cardsList {
    text-align: left !important;
    float: left;
    vertical-align: top;
    width: 35px;
    height: 35px;
}
.std-rpt-new-card-icon-cardsList {
    width: 65px;
    height: 30px;
}
.export-container {
    margin: 10px 10px 10px 0px;
    display: flex;
    width: fit-content;
    justify-content: flex-end;
}

.export-container img {
    cursor: pointer;
}

.header-title {
    color: #000000;
    font-family: 'Montserrat-Medium';
    font-size: 18px;
    text-align: left;
}

.subtitle {
    font-family: 'Montserrat-Regular';
    color: #3b8bc6;
    font-size: 15px;
}

.font-weight {
    font-weight: 500;
}

button:focus {
    outline: none;
}

.filter-count {
    color: #343434;
    font-size: 14px;
    font-family: 'Montserrat-Regular';
    text-align: right;
    margin-left: 40px;
}

.subheader-top-cover {
    display: flex;
    justify-content: space-between;
}

.p-col-12 {
    display: flex;
    align-items: center;
}

.checkbox {
    height: 20px;
    width: 20px;
    color: #3b8bc6;
    border-radius: 3px;
    background-color: #3b8bc6;
}

.p-checkbox-label {
    color: #000000;
    font-size: 14px;
    font-family: 'Montserrat-SemiBold';
    margin-left: 15px;
    text-align: center;
    height: 13px;
}

.button-top-cover {
    justify-content: flex-end;
    display: flex;
    margin-top: 15px;
}

.submit-button {
    width: 90px;
    height: 35px;
    border-radius: 50px;
    background-color: #3b8bc6;
    color: #ffffff;
    margin-left: 20px;
}

.button-label {
    font-family: 'Montserrat-Medium';
    font-size: 15px;
    border-width: 0px;
}

.cancel-button {
    /* color: #000000;
  background-color: #ffffff; */
    background-color: white;
    height: 35px;
    color: #000000;
    font-family: 'Montserrat-Medium';
    border: 0 none;
    margin-right: 15px;
}

.dialog {
    padding: 20px;
    width: 300px;
    position: absolute;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    top: 50%;
    background: white;
    transform: translate(-50%, -50%);
    z-index: 2;
}

input[type='checkbox']:checked:after {
    background-color: #3b8bc6 !important;
}

.overlay {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
}

/* style for Filter.js Start */
/* style for Filter.js Start */

/* -------------RoundDropDown-------- */
#DDStyle {
    height: 30px;
}

/* dropdown options scroll style */
.p-dropdown-items-wrapper::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
.p-dropdown-items-wrapper::-webkit-scrollbar-track {
    background: #f1f1f1;
}
.p-dropdown-items-wrapper::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #c0c0c0;
}

.p-dropdown:not(.p-disabled).p-focus {
    box-shadow: none !important;
}

/* --------------- Filter chip---------*/
.p-chip {
    border-radius: 25px !important;
    border: 1px solid #9d9d9d !important;
    background-color: #fff !important;
    padding-left: 10px !important;
}

.p-chip .p-chip-text {
    font-size: 12px;
    font-family: 'Montserrat-Medium';
    font-weight: 500;
    color: #343434 !important;
    margin-top: 2px !important;
    margin-bottom: 2px !important;
}
.p-chip-text {
    font-size: 10px !important;
    font-family: 'Montserrat-Medium' !important;
}

.p-chip .p-chip-remove-icon:focus {
    box-shadow: none !important;
}

.filter-chips-container {
    display: flex;
}

.filter-chips-container .filter-chip-item {
    padding-right: 15px;
}

.filter-chips-container .filter-chip-item .chip-label {
    font-size: 13px;
    font-weight: 500;
    color: #000000;
    text-align: start;
    padding-left: 7px;
    padding-bottom: 2px;
}

.main-filter-wrapper {
    display: flex;
}

.filter-chip-wrapper {
    margin: 10px 0px;
    display: flex;
    width: fit-content;
    margin-left: auto;
    min-height: 54px;
}

.p-chip {
    float: left;
}

.pi-times-circle {
    color: white;
}
.pi-times-circle:before {
    color: white;
    background-color: #b8b8b8;
    border-radius: 50%;
    /* width: 20px;
  height: 20px; */
    font-size: 18px;
}
.p-chip {
    color: #b8b8b8;
}

/* error confirm modal style */
.error-confirm-modal {
    max-width: 26em !important;
    text-align: center !important;
}
.error-confirm-modal .p-dialog-header {
    display: none !important;
}
.error-confirm-modal .p-dialog-content {
    padding: 2rem 1.5rem 1.5rem 1.5rem !important;
    flex-direction: column !important;
}
.error-confirm-modal.p-confirm-dialog .p-confirm-dialog-message {
    margin-top: 0.5em !important;
    margin-left: 0px !important;
}
.p-button:focus {
    box-shadow: none !important;
}
.confirm-reject-btn {
    display: none !important;
}
.confirm-accept-btn {
    padding: 5px 30px !important;
    background: #5b51c7 !important;
    border: 1px solid #5b51c7 !important;
}
.p-dialog-mask.p-component-overlay {
    z-index: 99999 !important;
}

.sidebar-content {
    nav {
        overflow-y: auto;
    }
}

.title-logo {
    //width: 200px !important;
    width: 35px !important;
    background: #e9f6ff !important;
    border: 1px solid #e4e4ee !important;
    border-radius: 100px !important;
    color: #6b6b6b !important;
    font-size: 14px !important;
    font-family: 'Montserrat-Medium' !important;
    padding-left: 12px !important;

    // position: absolute !important;
    // right: 130px !important;
    text-align: left !important;
    line-height: 18px !important;
    @media (max-width: 800px) {
        // width: 120px !important;
    }
    .get-title-logo {
        position: absolute;
        width: 15px;
        height: 15px;
        margin: 0px !important;
        align-items: center !important;
    }
}

.applogo-btn {
    //width: 200px !important;

    width: 100% !important;

    background: #e9f6ff !important;

    border: 1px solid #e9f6ff !important;

    border-radius: 100px !important;

    color: #6b6b6b !important;

    font-size: 14px !important;

    font-family: 'Montserrat-Medium' !important;

    padding-left: 10px !important;

    // position: absolute !important;

    // right: 130px !important;

    text-align: left !important;

    line-height: 18px !important;

    @media (max-width: 800px) {
        // width: 120px !important;
    }

    span {
        margin-left: 30px !important;
    }

    .applogo-btn-icon {
        position: absolute;
        left: 50.5%;
        transform: translateX(-50%);
        width: 3rem;
        height: 3rem;
        // max-height: 36px;
        color: #1d679e;
        margin: 0px !important;

        align-items: center !important;
    }
}

.title-logo {
    //width: 200px !important;
    width: 35px !important;
    background: #e9f6ff !important;
    border: 1px solid #e4e4ee !important;
    border-radius: 100px !important;
    color: #6b6b6b !important;
    font-size: 14px !important;
    font-family: 'Montserrat-Medium' !important;
    padding-left: 12px !important;

    // position: absolute !important;
    // right: 130px !important;
    text-align: left !important;
    line-height: 18px !important;
    @media (max-width: 800px) {
        // width: 120px !important;
    }
    .get-title-logo {
        position: absolute;
        width: 15px;
        height: 15px;
        margin: 0px !important;
        align-items: center !important;
    }
}

.applogo-btn {
    //width: 200px !important;

    width: 100% !important;

    background: #e9f6ff !important;

    border: 1px solid #e9f6ff !important;

    border-radius: 100px !important;

    color: #6b6b6b !important;

    font-size: 14px !important;

    font-family: 'Montserrat-Medium' !important;

    padding-left: 10px !important;

    // position: absolute !important;

    // right: 130px !important;

    text-align: left !important;

    line-height: 18px !important;

    @media (max-width: 800px) {
        // width: 120px !important;
    }

    span {
        margin-left: 30px !important;
    }

    .applogo-btn-icon {
        position: absolute;

        width: 1.5vw;

        color: #1d679e;

        height: 1.5vw;

        margin: 0px !important;

        align-items: center !important;
    }
}

// --------------------Search Button CSS Start-----------------------
.search-btn {
    //width: 200px !important;
    width: 100% !important;
    background: #dee2e6 !important;
    border: 1px solid #e4e4ee !important;
    border-radius: 100px !important;
    color: #6b6b6b !important;
    font-size: 14px !important;
    font-family: 'Montserrat-Medium' !important;
    padding-left: 10px !important;

    // position: absolute !important;
    // right: 130px !important;
    text-align: left !important;
    line-height: 18px !important;
    @media (max-width: 800px) {
        // width: 120px !important;
    }
    span {
        margin-left: 30px !important;
    }
    .search-btn-icon {
        position: absolute;
        width: 15px;
        height: 15px;
        margin: 0px !important;
        align-items: center !important;
    }
}

.search-dialog {
    width: calc(100vw - 100px) !important;
    height: calc(100vh - 100px) !important;
    .p-dialog-content {
        height: 100% !important;
    }
    .p-dialog-header {
        padding: 20px !important;
        width: 100% !important;
        text-align: center !important;
        .p-dialog-title {
            width: 100% !important;
            line-height: 27px !important;
            font-size: 22px !important;
            font-weight: 600 !important;
            font-family: 'Montserrat-SemiBold' !important;
            z-index: 3 !important;
            margin-left: 52px !important;
        }
        .p-dialog-header-close {
            background: #4e4e4e !important;
            border-radius: 50% !important;
            width: 26px !important;
            height: 26px !important;
            .p-dialog-header-close-icon {
                color: #fff !important;
            }
        }
    }
    .p-dialog-content {
        padding: 0px !important;
    }
}
.p-dropdown-panel {
    z-index: 99999 !important;
    margin-left: 10.5px !important;
    font-family: 'Montserrat-Regular' !important;
    @media (max-width: 800px) {
        margin-left: 0px !important;
    }
}

// --------------------Search Bar CSS Start-----------------------
.searchbar-div {
    display: flex;
    width: 50vw;
    max-width: 700px;
    margin: auto;
    .p-dropdown-label,
    .p-inputtext {
        display: block !important;
    }
}
#search-dropdown {
    .pi {
        display: flex;
        align-items: center;
    }
    .p-dropdown {
        // border: none !important;
        border-top: 1px solid #ced4da !important;
        border-bottom: 1px solid #ced4da !important;
        border-left: 1px solid #ced4da !important;
        border-right: none !important;
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
        background: #eeeeee !important;
        font-family: 'Montserrat-Medium' !important;
        color: #000000 !important;
        .p-dropdown-label {
            font-family: 'Montserrat-Medium' !important;
        }
    }
    .p-inputtext {
        display: flex;
        align-items: center;
        font-size: 15px;
        font-family: 'Montserrat-Medium' !important;
        border-left: none !important;
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
    }
    .p-inputtext:enabled:focus {
        border-color: none !important;
    }
    *:focus,
    *:hover {
        border-color: #d8d8d8 !important;
        box-shadow: none !important;
    }
}

.no-search-div {
    height: calc(100% - 38px);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    .graphic-wrapper {
        .graphic-img {
            display: flex;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .text {
        width: 100%;
        text-align: center;
        font-family: 'Montserrat-Medium';
        font-size: 20px;
        color: #363636;
        line-height: normal;
        padding-top: 20px;
    }
}

.search-results {
    width: 100%;
    height: calc(100% - 125px);
    overflow-y: auto;
    margin-top: 38px;
    .result-div {
        width: 100%;
        .result-header {
            padding: 12px 25px;
            width: 100%;
            background: #f9fdff;
            border: 1px solid #e4e4ee;
            font-family: 'Montserrat-SemiBold';
            font-size: 16px;
            color: #000;
        }
        .result-options {
            padding: 20px 25px;
            font-family: 'Montserrat-Medium';
            .option {
                display: flex;
                .option-text {
                    color: #3b8bc6;
                    font-size: 15px;
                    font-family: 'Montserrat-Medium';
                    padding-left: 7px;
                    cursor: pointer;
                    text-decoration: none !important;
                }
                &:not(:last-child) {
                    padding-bottom: 20px;
                }
            }
        }
    }
}

.search-results::-webkit-scrollbar {
    width: 12px;
    height: 10px;
}

.search-results::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.search-results::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #c0c0c0;
}
// --------------------Search Bar CSS End-----------------------

// ------------------ Tab-View CSS ---------------------------
.p-button-label {
    transition: none !important;
}
#tab_div {
    .p-col {
        padding-bottom: 0px !important;
        font-family: 'Montserrat-Medium' !important;
    }
}
.no-border-bottom {
    border-bottom: none !important;
}
.tab-btn {
    background-color: #ffffff !important;
    color: #343434 !important;
    border: 1px solid #9d9d9d !important;
    width: 100% !important;
    padding: 10px !important;
    font-size: 15px !important;
    line-height: 17px !important;
    span {
        font-family: 'Montserrat-Medium' !important;
    }
}
.selected-tab-btn {
    background-color: #3b8bc6 !important;
    color: #ffffff !important;
    border: 1px solid #3b8bc6 !important;
    width: 100% !important;
    padding: 10px !important;
    font-size: 15px !important;
    line-height: 17px !important;
    span {
        font-family: 'Montserrat-SemiBold' !important;
    }
}
.radio-chip-wrapper {
    display: flex;
    padding-top: 12px;
    .radio-chip {
        display: flex;
        align-items: center;
        width: 200px;
        background-color: #ffffff;
        border: 1px solid #9d9d9d;
        border-radius: 25px;
        padding: 5px 10px;
        font-size: 15px;
        margin-right: 10px;
        input[type='radio'] {
            margin-right: 9px;
            width: 19px;
            height: 19px;
        }
        label {
            margin-bottom: 0px !important;
            font-family: 'Montserrat-Medium';
            color: #343434;
        }
        .selectedSubOptionLabel {
            font-family: 'Montserrat-SemiBold';
        }
    }
}
.no-padding {
    padding: 0px;
}

.analyses-tab-action-icon {
    height: 4vh;
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    position: relative;
}

.analyses-tab-action-icon .analyses-tab-action-icon-hover {
    visibility: hidden;
    position: absolute;
    bottom: -2px;
    right: -1.5rem;
    font: normal normal normal 0.6vw/1.2vh 'Montserrat-Regular';
}

.analyses-tab-action-icon:hover .analyses-tab-action-icon-hover {
    visibility: visible;
    position: absolute;
    bottom: -2px;
    right: -1.5rem;
    font: normal normal normal 0.6vw/1.2vh 'Montserrat-Regular';
}

.config-dropdown-heading {
    text-align: left;
    font: normal normal 600 16px/20px Montserrat-SemiBold;
    letter-spacing: 0px;
    opacity: 1;
    color: #000;
    padding: 0.5vw;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 5px #00000029;
    border: 1px solid #5199cd;
    border-radius: 3px 3px 0px 0px;
    opacity: 1;
}

.config-dropdown-content {
    background-color: #fff;
    // background: #f5fbff 0% 0% no-repeat padding-box;
    // box-shadow: 0px 0px 10px #00000029;
    border: 1px solid #5199cd;
    border-top-width: 0px;
    border-radius: 0px 0px 3px 3px;
    opacity: 1;
}
.config-arrow1 {
    padding: 15px;
    position: absolute;
    /* background-color: #1d679e; */
    -ms-transform: rotate(45deg); /* IE 9 */
    -ms-transform-origin: 20% 40%; /* IE 9 */
    transform: rotate(45deg);
    transform-origin: 20% 40%;
    right: 7px;
    top: 30px;
    background: #f5fbff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 5px #00000029;
    border: 1px solid #5199cd;
    opacity: 1;
}
.dropdown-list {
    position: absolute;
    z-index: 100;
    right: 100px;
    min-width: 180px;
    width: fit-content;
    height: fit-content;
}
.dropdown-list p {
    min-width: 180px;
    height: fit-content;
    margin: 0px;
    padding: 5px;
    font-size: 15px;
    font-weight: 500;
    // color: #1d679e;
}
.dropdown-list p:hover {
    background-color: #e6f5ff;
    width: 99%;
}
