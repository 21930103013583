.accordian-card-container {
    width: 100%;
    height: fit-content;
    background-color: white;
}
.cccordian-card-container .inputs-selector {
    float: left;
    width: 100%;
}
.cccordian-card-container .inputs-selector {
    float: left;
}