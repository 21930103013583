.contact-form {
    width: 55vw;
    height: auto;
    background: #fff;
    overflow-y: scroll;
}
@media screen and (min-width: 500px) and (max-width: 1490px){
    .contact-form {
        overflow-y: scroll;
        height: 95vh;
    }
}

@media screen and (min-width: 4000px) {
    .contact-form {
        overflow-y: scroll;
        width: 100%; 
    }
}
.contact-form > .form-wrapper {
    width: 100%;
    height: 100%;
    padding: 0.9vh;
}

.contact-form .form-wrapper > .form-title {
    width: 100%;
    background: #e6f5ff;
    text-align: left;
    box-shadow: 0 0 5px #00000029;
    border-radius: 2px;
    padding: 1.3vh;
    display: flex;
    justify-content: space-between;
}

.contact-form .form-wrapper .form-title > span {
    display: inline-block;
}

.contact-form .form-wrapper .form-title span > text {
    font: normal normal normal 0.93vw/2.03vh 'Montserrat-Regular';
    letter-spacing: 0.5px;
}

.contact-form .form-wrapper > .section-divider {
    width: 100%;
    display: flex;
    /* flex-direction: row; */
    align-items: center;
    padding-top: 2vh;
    padding-bottom: 1vh;
}

.contact-form .form-wrapper .section-divider > div:first-child {
    height: 1px;
    background: #5199cd;
    margin: 0;
}

.contact-form .form-wrapper .section-divider > div:nth-child(2) {
    color: #5199cd;
    font: normal normal normal 0.93vw/2.03vh 'Montserrat-Regular';
    margin: 0;
}

.contact-form .form-wrapper .section-divider > div:last-child {
    height: 1px;
    background: #5199cd;
    margin: 0;
}

.contact-form .form-wrapper .form-fields-same-line > .form-field {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1.3vh;
}

.contact-form .form-wrapper .form-field > label {
    font: normal normal normal 1.04vw/2.22vh 'Montserrat-Medium';
    text-align: left;
}

.contact-form .form-wrapper .form-field > input {
    width: 100%;
    height: 4.1vh;
    border-radius: 3px;
    background: #e9e9e9;
    border: 1px solid #bbb;
    /* padding: 2px 10px; */
    color: #707070;
    font: normal normal normal 0.83vw/1.75vh 'Montserrat-Regular';
}

.contact-form .form-wrapper > .form-field-desc1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px 10px 0 10px;
    position: relative;
}

.contact-form .form-wrapper .form-field-desc1 > label {
    font: normal normal normal 1.04vw/2.22vh 'Montserrat-Medium';
    text-align: left;
}

.contact-form .form-wrapper .form-field-desc1 > textarea {
    width: 100%;
    height: 11vh;
    border-radius: 3px;
    background: #fff;
    border: 1px solid #bbb;
    font: normal normal normal 0.83vw/1.75vh 'Montserrat-Regular';
    padding: 8px;
}

.contact-form .form-wrapper > .form-submit {
    width: 100%;
    align-items: center;
    justify-content: center;
    padding-top: 18px;
    padding-bottom: 8px;
}

.contact-form .form-wrapper .form-submit > input {
    padding: 0.2vh 1.5vw;
    border-radius: 4px;
    background: #216ba1;
    color: #fff;
    font-size: 0.83vw;
}

.contact-form .form-wrapper .form-fields-same-line > .form-field-issue {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1.3vh;
}

.contact-form .form-wrapper .form-fields-same-line .form-field-issue > label {
    font: normal normal normal 1.04vw/2.22vh 'Montserrat-Medium';
    text-align: left;
}

.contact-form .form-wrapper .form-fields-same-line .form-field-issue  > .issue-select {
    width: 100%;
    border-radius: 3px;
    text-align: left;
    font: normal normal normal 0.83vw/1.75vh 'Montserrat-Regular';
    /* border: 1px solid #bbb; */
}

.contact-form .form-fields-same-line{
    display: flex;
    justify-content: flex-start;
}
.bug-info-div{
    width: 100%;
    margin: 10px 0 10px 0;
    padding: 0px 20px 0 20px;
    border: 1px solid #e7e7e7;
    background-color: #FAFDFF;
    display: flex;
    justify-content: space-evenly;
    border-radius: 5px;
}
.bug-info-wrapper{
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;

}
.bug-info-div .bug-info-wrapper > label{
    
    font: normal normal normal 1.04vw/2.22vh 'Montserrat-Medium';
    /* text-align: left; */
    font-weight: 700;
    /* font-size: small; */
}
.bug-info-div .bug-info-wrapper > p{
    
    font: normal normal normal 0.83vw/1.75vh'Montserrat-Medium';
    /* font-size: small; */
    display: flex;
    justify-content: center; /* Center text horizontally */
    align-items: center; /* Center text vertically */
    white-space: no-wrap; /* Allow text to wrap properly */
    padding-left: 20px;
    max-width: 90%;
}
.attachment-container1 {
    display: flex;
    width: 100%;
    background-color: #ffffff;
    margin-right: -8px;
    /* padding-bottom: 10px; */
    padding-top: 3px;
    /* padding-right: 14px; */
    border-radius: 10px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    /* border: 1px solid #ccc; */
    border-top: none;
    flex-wrap: wrap;
}

.attchment-view1 {
    border-radius: 5px;
    background-color: #ebebeb;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 10px;
    margin-right: 20px;
    max-width: 250px;
    /* height: 33px; */
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.file-name {
    color: #000000;
    font-family: "Montserrat-Regular";
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
