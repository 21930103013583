
.tab-container {
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    flex-wrap: nowrap;
    margin-bottom: 0;
}

.tab-top-view {
    margin-right: 5px;
    padding: 0px;
}

.tab-scroll-top-view {
    margin-right: 5px;
    padding: 0px;
    min-width: 132px;
}

.tab-text {
    color: #ffffff;
    font-family: 'Montserrat-Medium';
    font-weight: 500;
    font-size: 15px;
    margin-bottom: 0px;
}

.tab-view {
    height: 45px;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;
    // padding: 0 12px;
}

.selected-tab-view {
    background-color: #ffffff;
}

.tab {
    background-color: #5199CD;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.selected-tab-text {
    color: #5199CD;
    font-family: 'Montserrat-SemiBold';
    font-weight: 600;
    margin-bottom: 0px;
    font-size: 15px;
}

.tab-top-line {
    height: 6px;
}

.highlight-tab {
    height: 6px;
    background-color: #5199CD;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}
.scrollbar {
    overflow: auto;
  }
  
  .scrollbar::-webkit-scrollbar {
    height: 0px;
  }
  
  .scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  .scrollbar::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #c0c0c0;
  }

  .next-button {
    width: 30px;
    height: 45px;
    box-shadow: -2px 0 6px rgba(0, 0, 0, 0.06);
    background-color: #5199CD;
    // position: absolute;
    // right: 20px;
    // top: 0;
    border: 0px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-top: 25px;
    margin-right: 20px;
  }

  .previous-button {
    background-color: #5199CD;
    width: 30px;
    height: 45px;
    // position: absolute;
    // top: 0;
    // left: 20px;
    align-items: center;
    justify-content: center;
    display: flex;
    transform: rotate(180deg);
    cursor: pointer;
    margin-top: 25px;
    margin-left: 20px;
    // margin-left: 20px;
  }