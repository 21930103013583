.standard-rpt-nav-home-grid-container {
    min-height: 50px;
    width: 101%;
    overflow: auto;
}

.standard-rpt-nav-home-grid-container::-webkit-scrollbar {
    display: none;
}

.standard-rpt-subsection-div {
    padding-bottom: 20px;
    border-bottom: 1px solid #e4e4f1;
    margin-bottom: 20px;
}

.standard-rpt-subsection-head {
    color: #000000;
    font-family: "Montserrat-Medium";
    font-size: 17px;
    line-height: 22px;
    font-weight: 500;
    padding-bottom: 20px;
}

.standard-rpt-subsection-desc {
    color: #000000;
    font-family: "Montserrat-Regular";
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
}

.standard-rpt-subsection-nav {
    font-family: "Montserrat-SemiBold";
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    a {
        color: #3b8bc6;
    }
    a:hover {
        text-decoration: none;
    }
}

.standard-rpt-subsection-last-refresh {
    color: #000;
    font-family: "Montserrat-Medium";
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    float: right;
}

.std-rpt-subdiv {
    border-radius: 3px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
    height: 340px;
    // width: 565px;
    padding: 30px;
    margin-right: 20px;
    margin-bottom: 20px;
    position: relative;
}

.std-rpt-subdiv-txt {
    color: #fff;
    font-size: 25px;
    font-family: "Montserrat-SemiBold";
    font-weight: 600;
    margin-left: 30px;
}
