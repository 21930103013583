.query-builder-wrapper .query-builder-col > .insights-studio-title {
    display: flex;
    flex-direction: row;
    align-items: baseline;
}
.query-builder-wrapper .query-builder-col > .insights-studio-title text:first-child {
    font: normal normal 600 1.45vw/3.14vh 'Montserrat-SemiBold';
    color: #000;
    margin-right: 1.04vw;
}

.query-builder-wrapper .query-builder-col > .insights-studio-title text:last-child {
    font: normal normal normal 1.04vw/2.2vh 'Montserrat-Regular';
    color: #000;
    margin-left: 1vw;
}

.hide {
    display: none;
}

.notifi-arrow {
    padding: 15px;
    position: absolute;
    /* background-color: #1d679e; */
    -ms-transform: rotate(45deg); /* IE 9 */
    -ms-transform-origin: 20% 40%; /* IE 9 */
    transform: rotate(45deg);
    transform-origin: 20% 40%;
    right: -12px;
    top: 40px;
    background: #f5fbff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 5px #00000029;
    border: 1px solid #5199cd;
    opacity: 1;
}
.notifidropdown-content {
    background: #fff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #00000029;
    color: #000;
    border-bottom-width: 0px;
    border-top-width: 0px;
    opacity: 1;
}
.notifidropdown-heading {
    text-align: left;
    font: normal normal 600 16px/20px Montserrat-SemiBold;
    letter-spacing: 0px;
    opacity: 1;
    color: #000;
    padding: 0.5vw;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 5px #00000029;
    border: 1px solid #5199cd;
    border-radius: 3px 3px 0px 0px;
    opacity: 1;
}
.notifidropdown-footer {
    text-align: center;
    font: normal normal 16px/20px Montserrat-Regular;
    letter-spacing: 0px;
    opacity: 1;
    color: #000;
    text-decoration: underline !important;
    padding: 0.5vw;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 5px #00000029;
    border: 1px solid #5199cd;
    border-radius: 0px 0px 3px 3px;
    opacity: 1;
}

.notifydropdownlist {
    position: absolute;
    z-index: 999;
    right: 10px;
    width: 380px;
    height: fit-content;
}
.notifydropdownlist p {
    width: 180px;
    height: fit-content;
    margin: 0px;
    padding: 5px;
    font-size: 15px;
    font-weight: 500;
    color: #1d679e;
}

.mydiv:hover + .hide {
    display: block;
    margin-left: 8.4vw;
    color: #1d679e;
}
.insights-header {
    text-align: start;
    color: gray;
    font-size: 1rem;
    font-weight: bold;
    margin-left: 10px;
}
@media (max-width: 1163px) {
    .insights-header {
        font-size: 0.75rem; /* Set the height to 158px for screens wider than 2000px */
    }
}
@media (min-width: 2000px) {
    .insights-header {
        font-size: 1.25rem; /* Set the height to 158px for screens wider than 2000px */
    }
}
@media (min-width: 4000px) {
    .insights-header {
        font-size: 2.81rem; /* Set the height to 158px for screens wider than 2000px */
    }
}

.insights-studio-actions {
    box-shadow: 0px 8px 0px 0px rgba(29, 103, 158, 1);
    border: 1px solid #1d679e;
    border-radius: 7px;
    display: flex;
    align-items: center;
    /* transition: box-shadow 0.9; */
    transition: transform 0.3s, box-shadow 0.3s, border 0.3s, margin 0.3s;
    height: 6.9rem;
    /* transition-delay: 0.1s; */

    flex: 13%;
}
.insights-studio-actions:hover {
    box-shadow: none;
    border: 1px solid #1d679e;
    transform: translateY(6.5px);
}
@media (min-width: 4000px) {
    .insights-studio-actions {
        height: 14.31rem; /* Set the height to 158px for screens wider than 2000px */
    }
}

.insights-studio-actions .custom-insights {
    padding: 0.375rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    cursor: pointer;
    color: #1d679e;
    font-weight: 700;
}
.insights-studio-actions .custom-insights:hover {
    box-shadow: none;
}
.insights-studio-custom {
    position: relative;
}
.insights-studio-actions .custom-insights p {
    font-size: 0.875rem;
}
@media (max-width: 1163px) {
    .insights-studio-actions .custom-insights p {
        font-size: 0.675rem; /* Set the height to 158px for screens wider than 2000px */
    }
}
@media (min-width: 1164px) {
    .insights-studio-actions .custom-insights img {
        margin-top: 10px;
    }
}
@media (min-width: 2000px) {
    .insights-studio-actions .custom-insights p {
        font-size: 1.275rem; /* Set the height to 158px for screens wider than 2000px */
    }
}
@media (min-width: 4000px) {
    .insights-studio-actions .custom-insights p {
        font-size: 2.175rem; /* Set the height to 158px for screens wider than 2000px */
    }
}

.insights-container {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
    border: 1px solid transparent;
    background-color: white;
    padding: 20px 10px 10px 10px;
    /* margin-bottom: 20px; */
    border-radius: 7px;
    margin: 0 5px 20px 5px;
}

.insights-hr {
    margin-bottom: '10px';
    margin-left: '0px';
}
.insights-studio-wrapper {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 30px;
    margin-bottom: 20px;
}

.insights-studio-actions .custom-insights-btn,
.insights-studio-actions .accelerated-insights-btn {
    /* width: fit-content; */
    float: left;
    padding: 8px 15px;
    background: transparent
        linear-gradient(
            180deg,
            var(--unnamed-color-1d679e) 0%,
            var(--unnamed-color-1d679e) 50%,
            var(--unnamed-color-1d679e) 100%
        )
        0% 0% no-repeat padding-box;
    background: transparent linear-gradient(180deg, #1d679e 0%, #1d679e 50%, #1d679e 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #00000029;
    border-radius: 7px;
    margin-right: 30px;
}
.insights-studio-actions .custom-insights-btn p,
.insights-studio-actions .accelerated-insights-btn p {
    text-align: left;
    font-weight: 600;
    letter-spacing: 0.6px;
    color: #ffffff;
    margin: 0px;
}
.hide-block {
    display: none;
    height: 0px;
}

.accelerated-insights-cards-container {
    flex: 90%;
    position: relative;
    border: 1px solid #1d679e; /* Adjust the border properties as needed */
    border-radius: 7px;
}

.accelerated-insights-cards-container::before {
    content: 'Accelerated Insights';
    position: absolute;
    top: -12px; /* Adjust the distance above the top border */
    left: 75px;
    background-color: white;
    color: #1d679e;
    font-weight: 900;
}
@media (max-width: 1163px) {
    .accelerated-insights-cards-container {
        font-size: 1rem;
    }
}
@media (min-width: 2000px) {
    .accelerated-insights-cards-container {
        font-size: 1.175rem;
    }
}
@media (min-width: 4000px) {
    .accelerated-insights-cards-container::before {
        font-size: 2.175rem;
        padding: 30px 10px 20px 10px;
        position: absolute;
        top: -50px;
    }
}
@media (min-width: 4000px) {
    .accelerated-insights-cards-container {
        font-size: 2.175rem;
        padding: 30px 10px 20px 10px;
    }
}

.accelerated-insights-cards {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    padding: 20px 10px;
    gap: 1rem;
    height: 100%;
    transition: height 3s;
}
@media (max-width: 768px) {
    .accelerated-insights-cards {
        grid-template-columns: repeat(3, 1fr); /* Change the number of columns when screen is small */
    }
}

.accelerated-insights-cards .accelerated-insights-cards-item {
    width: 18.9%;
    box-shadow: 0px 8px 0px 0px rgba(29, 103, 158, 1);
    border-radius: 7px;
    padding: 10px;
    border: 1px solid #1d679e;
    margin-bottom: 10px;
    background-color: inherit;
    position: relative;
    /* transition: box-shadow 0.9; */
    transition: transform 0.3s, box-shadow 0.3s, border 0.3s, margin 0.3s;
}
.accelerated-insights-cards .accelerated-insights-cards-item:hover {
    /* margin-top: 5px; */
    box-shadow: none; /* Remove the box shadow on hover */
    border: 1px solid #1d679e; /* Set the border to transparent on hover */
    transform: translateY(5px);
    /* Shift the element down on hover */
}

@media (max-width: 1502px) {
    .accelerated-insights-cards .accelerated-insights-cards-item {
        width: 22.9%; /* Adjusted width for screens wider than 3500px */
    }
}

.accelerated-insights-cards .accelerated-insights-cards-item p {
    margin: 0px;
    color: #2c333e;
    font-weight: 600;
    /* margin-bottom: 10%; */
    font-size: 1.1vw;
    margin-left: 0.375rem;
    width: 100%;
    float: left;
}
.accelerated-insights-cards .accelerated-insights-cards-item p .icon {
    float: left;
}
.accelerated-insights-cards .accelerated-insights-cards-item p .text {
    text-align: left;
    margin-top: 3px;
    margin-left: 10px;
    float: left;
    font-size: 0.9vw;
}
.accelerated-insights-cards .accelerated-insights-cards-item p span .acc-card-icon {
    float: left;
}
.accelerated-insights-cards .accelerated-insights-cards-item .create-query-btn {
    width: fit-content;
    float: left;
}
.accelerated-insights-cards .accelerated-insights-cards-item .create-query-btn button {
    font-size: 0.9vw;
    background-color: white;
    width: fit-content;
    padding: 3px 5px;
    border: 1px solid #1d679e;
    color: #1d679e;
    border-radius: 7px;
    position: absolute;
    bottom: 10px;
    left: 10px;
}
/* .sharedwithmeanalysisname {
        padding-left: '10px';
        text-align: 'left';
} */
.ssa-hover {
    opacity: 1;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2), 0px 0px 1px rgba(0, 0, 0, 0.31);
    background-color: '#F5FBFF';
    border: 1px solid #1d679e;
    min-width: 15vw;
    max-height: fit-content;
    padding: 1;
    overflow-wrap: break-word;
    font-size: small;
    margin-bottom: 10px;
    left: 5%;
    position: relative;
}

.analyses-name-icon .analyses-name-icon-hover {
    visibility: hidden;
    position: absolute;
    transform: translateY(-2.5vh);
    font-weight: 600;
    text-align: center;
    font-size: 0.83vw !important;
}

.analyses-name-icon:hover .analyses-name-icon-hover {
    visibility: visible;
    position: absolute;
    transform: translateY(-2.5vh);
    font-weight: 600;
    text-align: center;
    font-size: 0.83vw !important;
    color: #1d679e;
}

.analyses-share-icon .analyses-share-icon-hover {
    display: none;
}

.analyses-share-icon:hover .analyses-share-icon-hover {
    display: block;
    position: relative;
    font-weight: 600;
    text-align: center;
    font-size: 0.83vw !important;
    color: #1d679e;
}
.analyses-share-icon:hover {
    transform: translateY(-9px);
}
.tooltip-arrow {
    position: absolute;
    top: 100%;
    left: 50%;
    border-width: 8px;
    border-style: solid;
    border-color: #1d679e transparent transparent;
}

.analyses-tabs-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0.8rem 0;
    align-items: flex-start;
    border-radius: 3px 3px 0 0;
    /* overflow-x: auto;
    overflow-y: hidden; */
}

.analyses-tab-active {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    min-width: 8rem;
    height: 4.2vh;
    padding: 0 0.8rem;
    color: #000;
    opacity: 0.7;
    border-radius: 3px 3px 0 0;
    border-top: 1px solid #e4e4f1;
    border-bottom: 0;
    border-left: 1px solid #e4e4f1;
    border-right: 1px solid #e4e4f1;
    cursor: pointer;
    font: normal normal normal 0.93vw 'Montserrat-Medium';
}

.analyses-tab {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #e4e4f1;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    background: none;
    min-width: 8rem;
    height: 4.2vh;
    padding: 0 0.8rem;
    color: #5199cd;
    border-radius: 3px 3px 0 0;
    cursor: pointer;
    font: normal normal normal 1.04vw/2.2vh 'Montserrat-Regular';
}

.analyses-tab-actions {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-bottom: 1px solid #e4e4f1;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    background: none;
    min-width: 8rem;
    height: 4.2vh;
    /* padding: 0 0.8rem 0.6rem 0.8rem; */
    /* padding-bottom: 1rem; */
    color: #5199cd;
    border-radius: 3px 3px 0 0;
    font: normal normal normal 1.04vw/2.2vh 'Montserrat-Regular';
}

.analyses-tab-action-icon {
    height: 4vh;
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    position: relative;
}

.analyses-tab-action-icon .analyses-tab-action-icon-hover {
    visibility: hidden;
    position: absolute;
    bottom: -2px;
    right: -1.5rem;
    font: normal normal normal 0.6vw/1.2vh 'Montserrat-Regular';
}

.analyses-tab-action-icon:hover .analyses-tab-action-icon-hover {
    visibility: visible;
    position: absolute;
    bottom: -2px;
    right: -1.5rem;
    font: normal normal normal 0.6vw/1.2vh 'Montserrat-Regular';
}

.analyses-tab-separator {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    bottom: -4px;
    width: 1px;
    height: 3vh;
    background: #ccc;
}

.notification-count-wrapper {
    position: absolute;
    transform: translateY(-10px) translateX(10px);
    height: 0.9vw;
    max-height: 24px;
    max-width: 24px;
    width: 0.8vw;
    border-radius: 100%;
    background-color: #ffd350;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 7px;
}
.share-count-wrapper {
    position: absolute;
    transform: translateY(-6px) translateX(13px);
    height: 0.8vw;
    max-height: 24px;
    max-width: 24px;
    width: 0.8vw;
    border-radius: 100%;
    background-color: #ffd350;
    display: flex;
    align-items: center;
    justify-content: center;
}
@media screen and (max-width: 820px) {
    .share-count-wrapper {
        transform: translateY(-0.25vh) translateX(1vw);
    }
    .notification-count-wrapper {
        transform: translateY(-0.4vh) translateX(1vw);
    }
}

.notification-count {
    font: normal normal normal 0.5vw 'Montserrat-Regular';
    color: #000;
}
@media screen and (min-width: 1440px) {
    .notification-count {
        font: normal normal normal 10px 'Montserrat-Regular' !important;
    }
}

.occupator {
    flex-grow: 1;
    border-bottom: 1px solid #e4e4f1;
    height: 4.2vh;
}
.occupator-1 {
    /* flex-grow: 1; */
    width: 1rem;
    border-bottom: 1px solid #e4e4f1;
    height: 4.2vh;
}

.accelerated-insights-my-analysis-table {
    border-radius: 10px;
    width: 100%;
    height: max-content;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #00000029;
}
.accelerated-insights-my-analysis-table .my-analysis-table-header {
    width: 100%;
    height: max-content;
    /* background: #ffffff 0% 0% no-repeat padding-box; */
    margin-bottom: 70px;
}
.accelerated-insights-my-analysis-table .my-analysis-table-header .title {
    width: fit-content;
    font: normal normal 600 1.14vw/2.5vh 'Montserrat-SemiBold';
    color: #2c333e;
    float: left;
    margin-top: 25px;
    margin-left: 25px;
}
.accelerated-insights-my-analysis-table .my-analysis-table-header .actions {
    width: fit-content;
    float: right;
    margin-top: 20px;
    margin-left: 20px;
}
.accelerated-insights-my-analysis-table .my-analysis-table-header .actions .search-analysis-reports {
    width: fit-content;
    float: right;
}
.accelerated-insights-my-analysis-table .my-analysis-table-header .actions .search-analysis-reports {
    width: fit-content;
    float: right;
    margin-right: 20px;
}
.accelerated-insights-my-analysis-table .my-analysis-table-header .actions .search-icon {
    position: relative;
}
.accelerated-insights-my-analysis-table .my-analysis-table-header .actions .search-icon:before {
    content: '';
    position: absolute;
    right: -2%;
    top: 50%;
    transform: translate(-50%, -50%);
    bottom: 0;
    width: 20px;
    background: url('../../assets/images/search-icon.svg') center / contain no-repeat;
    background-size: 1rem;
}

.accelerated-insights-my-analysis-table .my-analysis-table-header .actions .search-analysis-reports input {
    width: fit-content;
    padding: 4px 8px;
    border: 1px solid #bbbbbb;
    border-radius: 5px;
}
.accelerated-insights-my-analysis-table .my-analysis-table-header .actions .search-analysis-reports input::placeholder {
    font-size: 0.6vw;
}
.accelerated-insights-my-analysis-table table {
    /* border-collapse: collapse;
    border-spacing: 0;
    margin-top: 10px;
    height: 52vh;
    overflow-y: auto;
    display: block; */
    background: #ffffff 0% 0% no-repeat padding-box;
    opacity: 1;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    margin-top: 10px;
}
.accelerated-insights-my-analysis-table thead {
    /* position: sticky;
    top: 0px;
    width: 100vw; */

    /* border: 1px solid #1d679e; */
    /* box-shadow: 1px 1px 1px #1d679e; */
    background: white;
    width: 100vw;
    /* position: sticky; */
    /* display: block; */
    /* top: 0px; */
    /* z-index: 10; */
}
.accelerated-insights-my-analysis-table tbody {
    border: 1px solid #e0e0e0;
}
.accelerated-insights-my-analysis-table tr th {
    /* border-top: 1px solid #e0e0e0; */
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    color: #1d679e;
    /* font: normal normal medium 20px/24px Montserrat; */
    font-size: 0.83vw;
    /* border: 1px solid #1d679e; */
    background-color: #f5fbff;
}
.accelerated-insights-my-analysis-table tbody tr,
.accelerated-insights-my-analysis-table thead tr {
    width: 100%;
}
.accelerated-insights-my-analysis-table tbody td {
    font-size: 0.8vw;
    text-align: center;
    /* border-right: 1px solid #e0e0e0; */
    /* border-bottom: 1px solid #e0e0e0; */
    color: #707070;
    padding-top: 16px;
    padding-bottom: 10px;
}

.accelerated-insights-my-analysis-table tbody .OnHoverActions {
    display: inline-block;
    transition: all 0.5 ease-in-out;
    position: relative;
    /* background: chartreuse; */
    /* margin-top: 10px; */
}
.accelerated-insights-my-analysis-table tbody .OnHoverActions img {
    width: 1.5vw;
    max-height: 20px;
    max-width: 20px;
}
.accelerated-insights-my-analysis-table tbody .OnHoverActions .OnHoverShowNote {
    transition: all 0.6 ease-in-out;
    position: absolute;
    /* right: 0px;
    bottom: -25px; */
    white-space: nowrap;
    /* transform: translate(-72%, 105%); */
    transform: translate(-72%, -105%);
    text-align: center;
    visibility: hidden;
}

.cohort {
    visibility: hidden;
    transition: all 0.6 ease-in-out;
    position: absolute;
}

.cohort:hover {
    color: #1d679e;
    font-weight: 600;
    font-size: 12px !important;
    white-space: nowrap;
    transform: translate(-72%, -105%);
    text-align: center;
    visibility: visible;
}
.cohort:hover {
    visibility: visible;
    color: #1d679e;
    font-size: 12px !important;
}

.accelerated-insights-my-analysis-table tbody .OnHoverActions:hover .OnHoverShowNote {
    visibility: visible;
    color: #1d679e;
    font-weight: 600;
    font-size: 12px !important;
}
.OnHoverActions:hover .onHoverView {
    visibility: visible;
    color: #bbbbbb;
    font-weight: 600;
    font-size: 12px !important;
}

.cohort-icon:hover .cohort-icon-hover {
    visibility: visible;
    position: relative;
    bottom: -2px;
    /* right: 0rem; */
    font: normal normal normal 0.6vw/1.2vh 'Montserrat-Regular';
    color: #1d679e;
}

.sorting {
    width: 100% !important;
    background: #dee2e6 !important;
    border: 1px solid #e4e4ee !important;
    border-radius: 100px !important;
    color: #6b6b6b !important;
    font-size: 14px !important;
    font-family: 'Montserrat-Medium' !important;
    padding-left: 10px !important;
    text-align: left !important;
    line-height: 18px !important;
}

.accelerated-insights-my-analysis-table .table-footer {
    width: 100%;
}
.accelerated-insights-my-analysis-table .table-footer .analysis-id-name {
    float: left;
    font-size: 13px;
    color: #707070;
    padding: 10px 5px;
    margin-left: 10px;
}
.accelerated-insights-my-analysis-table .table-footer .analysis-id-name p {
    margin-bottom: 0px;
}
.accelerated-insights-my-analysis-table .table-footer .table-pagination {
    padding-bottom: 10px;
    float: right;
}
.accelerated-insights-steps-to-follow {
    width: 100%;
    height: 550px;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0px 0px 10px #00000029;
    margin-top: 1.5rem;
}
.accelerated-insights-steps-to-follow > .heading {
    width: 100%;
    float: left;
    padding: 15px;
    text-align: left;
    border-bottom: 1px solid #bbbbbb;
}
.accelerated-insights-steps-to-follow > .heading > p {
    width: fit-content;
    font: normal normal 600 1.14vw/2.5vh 'Montserrat-SemiBold';
    letter-spacing: 0.66px;
    color: #2c333e;
    margin: 0;
}
.accelerated-insights-steps-to-follow > .content {
    width: 100%;
    float: left;
    padding-top: 15px;
    text-align: left;
}
.accelerated-insights-steps-to-follow > .content > .accelerated-insights-transScript {
    width: 45%;
    padding-right: 15px;
    padding-left: 15px;
    height: 450px;
    float: left;
    overflow-y: auto;
    font-size: 14px;
}
.accelerated-insights-steps-to-follow > .content > .accelerated-insights-transScript p {
    margin-bottom: 5px !important;
}
.accelerated-insights-steps-to-follow > .content > .accelerated-insights-transScript::-webkit-scrollbar {
    background-color: #f2f0f0;
    border-radius: 5px;
    width: 5px;
}
.accelerated-insights-steps-to-follow > .content > .accelerated-insights-transScript::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 5px;
    width: 5px;
}
.accelerated-insights-steps-to-follow > .content > .accelerated-insights-transScript::-webkit-scrollbar-track {
    /* border: 1px solid #bbbbbb; */
    border-radius: 5px;
}
.accelerated-insights-transScript ul {
    padding-inline-start: 20px !important;
}

.accelerated-insights-steps-to-follow > .content > .accelerated-insights-videoplayer {
    width: 55%;
    height: 450px;
    float: right;
    padding-right: 2%;
}
.accelerated-insights-steps-to-follow > .content > .accelerated-insights-videoplayer iframe {
    border: 0px;
}
.accelerated-insights-steps-to-follow > .content > .accelerated-insights-videoplayer > iframe {
    float: right;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
}

/* select.selection-arrow {
    background-image: linear-gradient(45deg, transparent 50%, #707070 50%),
        linear-gradient(135deg, #707070 50%, transparent 50%), linear-gradient(to right, #707070, #707070);
    background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 0em;
    background-repeat: no-repeat;
}
select.selection-arrow:focus {
    background-image: linear-gradient(45deg, #707070 50%, transparent 50%),
        linear-gradient(135deg, transparent 50%, #707070 50%), linear-gradient(to right, #707070, #707070);
    background-position: calc(100% - 15px) 2em, calc(100% - 20px) 1em, calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 0em;
    background-repeat: no-repeat;
    border-color: #707070;
    outline: 0;
} */
/* select.selection-arrow {
    position: relative;
} */
select.selection-arrow {
    background-position: 85% 50%;
    background-repeat: no-repeat;
    background-image: url('../../assets/images/select-option-arrow.svg');
    background-size: 0.8rem;
    z-index: 999999;
}

.config-dropdown-heading {
    text-align: left;
    font: normal normal 600 16px/20px Montserrat-SemiBold;
    letter-spacing: 0px;
    opacity: 1;
    color: #000;
    padding: 0.5vw;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 5px #00000029;
    border: 1px solid #5199cd;
    border-radius: 3px 3px 0px 0px;
    opacity: 1;
}

.config-dropdown-content {
    /* background: #f5fbff 0% 0% no-repeat padding-box; */
    /* box-shadow: 0px 0px 10px #00000029; */
    border-bottom: 1px solid #5199cd;
    border-top-width: 0px;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
}
.config-arrow {
    padding: 15px;
    position: absolute;
    /* background-color: #1d679e; */
    -ms-transform: rotate(45deg); /* IE 9 */
    -ms-transform-origin: 20% 40%; /* IE 9 */
    transform: rotate(45deg);
    transform-origin: 20% 40%;
    right: 65px;
    top: 30px;
    background: #f5fbff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 5px #00000029;
    border: 1px solid #5199cd;
    opacity: 1;
}
.dropdown-list {
    position: absolute;
    z-index: 100;
    right: 100px;
    min-width: 180px;
    width: fit-content;
    height: fit-content;
}
.dropdown-list p {
    min-width: 180px;
    height: fit-content;
    margin: 0px;
    padding: 5px;
    font-size: 15px;
    font-weight: 500;
    color: #1d679e;
}
.dropdown-list p:hover {
    background-color: #e6f5ff;
    width: 99%;
}

.row-tooltip-wrapper {
    position: relative;
    z-index: 99999;
}

.row-tooltip {
    position: absolute;
    bottom: 135%;
    background: #3d4551;
    color: #fff;
    border-radius: 3px;
    width: 534px;
    right: -50px;
    padding: 15px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    border: 1px solid #e4e4f1;

    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
}

.row-tooltip:after {
    position: absolute;
    content: '';
    top: 100%;
    right: 47px;
    margin-bottom: 15px;
    border-top: 18px solid #3d4551;
    border-bottom: 12px solid transparent;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
}
.analyses-icons {
    /* height: 4vh; */
    /* margin-bottom: 4px; */
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
    min-height: 60px;
}
.analyses-name-icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.analyses-name-icon img {
    width: 1.5vw;
    max-width: 20px;
}
.analyses-share-icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 48px;
    cursor: pointer;
}
.analyses-share-icon img {
    width: 2.5vw;
    max-width: 20px;
    margin: 0 1vw;
    position: static;
}
.icon-analytics svg {
    width: 1.2vw;
    max-width: 16px;
}

.coonwer:hover .coowner-hover {
    visibility: visible;
    position: absolute;
    transform: translateY(-2.5vh);
    font-weight: 600;
    text-align: center;
    font-size: 0.83vw !important;
    color: #1d679e;
}

.analyses-name-icon .analyses-name-icon-hover-new {
    visibility: hidden;
    position: absolute;
    transform: translateY(-2.5vh);
    font-weight: 600;
    text-align: center;
    font-size: 0.83vw !important;
}

.analyses-name-icon:hover .analyses-name-icon-hover-new {
    visibility: visible;
    position: absolute;
    transform: translateY(-2.5vh);
    font-weight: 600;
    text-align: center;
    font-size: 0.83vw !important;
    color: #1d679e;
}

.new-tag {
    transform: translateX(-7px);
}
@media screen and (max-width: 1080px) {
    .new-tag {
        scale: 0.5;
        transform: translateX(-27px);
    }
    .serial-row {
        transform: translateY(8px);
    }
}

.coowner .coowner-hover {
    visibility: hidden;
    position: relative;
    bottom: -2px;
    /* right: 10.5rem; */
    font: normal normal normal 0.6vw/1.2vh 'Montserrat-Regular';
    /* left:7.5rem; */
}

.coonwer:hover .coowner-hover {
    visibility: visible;
    position: relative;
    bottom: -2px;
    /* right: 0rem; */
    font: normal normal normal 0.6vw/1.2vh 'Montserrat-Regular';
    color: #1d679e;
}

.coowner .coowner-hover {
    visibility: hidden;
    position: relative;
    bottom: -2px;
    /* right: 10.5rem; */
    font: normal normal normal 0.6vw/1.2vh 'Montserrat-Regular';
    /* left:7.5rem; */
}

.coonwer:hover .coowner-hover {
    visibility: visible;
    position: relative;
    bottom: -2px;
    /* right: 0rem; */
    font: normal normal normal 0.6vw/1.2vh 'Montserrat-Regular';
    color: #1d679e;
}
