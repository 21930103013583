.top-request-container {
    margin-right: 40px;
    margin-left: 40px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.to-continer {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.label-text {
    color: #000000;
    font-family: "Montserrat-Medium";
    font-size: 16px;
    font-weight: 500;
    margin-right: 12px;
    margin-bottom: 0px;
    width:70px;
    text-align: end;
}

.to-text-input {
    border-width: 0px;
    color: #000000;
    font-family: "Montserrat-Regular";
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    background-color: 'red';
}


.to-email-container {
    border-radius: 10px;
    border: 1px solid #ccc;
    background-color: #ffffff;
    min-height: 47px;
    width:100%;
    text-align: initial;
    display: flex;
    align-items: center;
    padding: 10px;
    overflow-x: auto;
}

.chip-item {
    border-radius: 5px;
    border: 1px solid #9d9d9d;
    background-color: #e4e4f1;
    display: flex;
    align-items: center;
    padding-right: 5px;
    padding-left: 5px;
    margin-right: 8px;
    padding-top: 2px;
    padding-bottom: 2px;
}

.remove-email-icon {
    width: 9px;
    height: 9px;
    margin-left: 5px;
    cursor: pointer;
}

.editor-container1 {
    background-color: #fff;
    padding: 5px;
    height: 50vh;
    border-radius: 10px;
    border: 1px solid #ccc;
    max-height: 50vh;
    overflow-y: auto;
}

.editor-container {
    margin-left: 80px;
}

.attachment-icon {
    width: 19px;
    height: 19px;
}

.add-attachment-text {
    color: #5199CD;
    font-family: "Montserrat-Medium";
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
}

.bottom-view {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.send-button {
    width: 90px;
    height: 35px;
    border-radius: 50px;
    background-color: #5199CD;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.send-button-text {
    color: #ffffff;
    font-family: "Montserrat-Medium";
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 0px;
    cursor: pointer;
}

.clear-button-text {
    color: #000000;
    font-family: "Montserrat-Medium";
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 0px;
    cursor: pointer;
}

.clear-button-container {
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 25px;
    cursor: pointer;
}

.bottom-button-container {
    display: flex;
}

.attachmentdf-container {
    display: flex;
    background-color: #ffffff;
    padding-bottom: 10px;
    margin-top: -5px;
    padding-top: 10px;
    padding-right: 14px;
    border-radius: 10px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border: 1px solid #ccc;
    border-top: none;
    flex-wrap: wrap;
}

.attchmentdf-view {
    border-radius: 5px;
    background-color: #ebebeb;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 10px;
    // min-width: 250px;
    max-width: 250px;

    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.file-name {
    color: #000000;
    font-family: "Montserrat-Regular";
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.tox .tox-notification--warn, .tox .tox-notification--warning{
    display: none !important;
}

.tox-tinymce {
    border: none !important;
    border-top: 1px solid #ccc !important;
    border-left: 1px solid #ccc !important;
    border-right: 1px solid #ccc !important;
    border-radius: 10px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    
}

.scrollable-body::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
 
.scrollable-body::-webkit-scrollbar-track {
  background: #f1f1f1;
}
 
.scrollable-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #c0c0c0;
}

.subject-container {
    border-radius: 10px;
    border: 1px solid #ccc;
    background-color: #ffffff;
    min-height: 47px;
    width:100%;
    text-align: initial;
    display: flex;
    align-items: center;
    padding: 10px;
    overflow-x: auto !important;
}

.success-popup-container {
    display: grid;
    justify-content: center;
    margin-top: 15px;
    margin-bottom: -15px;
}

.ok-button-container {
    display:flex;
    justify-content: center;
    margin-top: 10px;
}

.ok-button {
    width: 80px;
    height: 30px;
    border-radius: 50px;
    background-color: #5199CD;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.wrapper-class {
    padding: 1rem;
    border: 1px solid #ccc;

    background-color: #fff;
    padding: 5px;
    height: 62vh;
    border-radius: 10px;
  }
  .editor-class {
    padding: 1rem;
    max-height: 50vh;
    overflow-y: auto;
    .public-DraftStyleDefault-block{
        line-height: normal;
        margin: 0;
    }
}
    
  .toolbar-class a{
    border: 0px solid #ccc;
    color: black;
    text-decoration: none;
  }
  .toolbar-class {
    .rdw-link-modal{
        height: inherit;
        left: inherit;
        right: -80px;
    }
    .rdw-embedded-modal{
        height: inherit;
        left: inherit;
        right: -80px;
    }
    .rdw-emoji-modal{
        left: inherit;
        right: -80px;
    }
    .rdw-image-modal{
        left: inherit;
        right: -80px;
    }
  }