.top-container-qa {
    border: 1px solid #e8e9ee;
    border-radius: 3px;
    // background-color: #edeef1;
    padding: 1rem;

    width: 100%;
}

.from-vendor-container {
    margin: 20px 0 20px 0;
    border: 0;
    padding: 10px 12px;
}

.qna-div {
    padding: 10px 0px 10px 10px;
    margin-bottom: 15px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #5199cd;
    border-radius: 5px;
    opacity: 1; // width: 62.5rem;
}

.qna-ques-div {
    font-size: 15px;
    font-weight: 600;
    font-family: 'Montserrat-SemiBold';
    font-style: normal;
    text-align: initial;
    line-height: 22px;
    color: #000;
    margin-bottom: 10px;
}

.qna-title {
    text-align: left;
    font: normal normal 600 18px/22px Montserrat;
    letter-spacing: 0.54px;
    color: #5199cd;
    opacity: 1;
}

.qna-ans-div {
    font-size: 15px;
    font-weight: 400;
    font-family: 'Montserrat-Regular';
    font-style: normal;
    text-align: initial;
    line-height: 22px;
    color: #000;
}
