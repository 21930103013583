.query-builder-wrapper {
    height: auto;
    /* background: #ccc; */
}

.query-builder-wrapper .query-builder-col {
    margin: 20px 20px 60px 20px;
    display: flex;
    flex-direction: column;
}

.query-builder-wrapper .query-builder-col > .query-title {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
}

.query-builder-wrapper .query-builder-col .query-title > .help-center {
    color: #5199cd;
    font: normal normal 600 0.83vw/1.75vh 'Montserrat-SemiBold';

    cursor: pointer;
}

.query-builder-wrapper .query-builder-col > .stat-cards-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin: 20px auto;
    width: 100%;
}

.query-builder-wrapper .query-builder-col .stat-cards-wrapper > .stat-card {
    width: 32.5%;
    /* height: 190px; */
    background: #fff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #00000029;
    border-radius: 10px;
    padding-bottom: 1.8vh;
}

.query-builder-wrapper .query-builder-col .stat-cards-wrapper .stat-card > .stat-card-title {
    position: relative;
    top: 2.3vh;
    padding-left: 1.56vw;
    padding-right: 1.56vw;
    padding-bottom: 1.01vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.query-builder-wrapper .query-builder-col .stat-cards-wrapper .stat-card .stat-card-title > div:nth-child(2) {
    color: #5199cd;
    font: normal normal normal 0.72vw/1.6vh 'Montserrat-Regular';
    align-self: center;
}

.query-builder-wrapper .query-builder-col .stat-cards-wrapper .stat-card .stat-card-title > div:nth-child(2):hover {
    cursor: pointer;
    font-weight: bold;
}

.query-builder-wrapper .query-builder-col .stat-cards-wrapper .stat-card > .stat-card-title text {
    font: normal normal 600 1.14vw/2.5vh 'Montserrat-SemiBold';
}

hr {
    margin-left: 1.56vw;
    display: block;
    border-top: 1px solid #ccc;
    padding: 0 !important;
}

.query-builder-wrapper .query-builder-col > .query-title text:first-child {
    font: normal normal 600 1.45vw/3.14vh 'Montserrat-SemiBold';
    color: #000;
    margin-right: 1.04vw;
}

.query-builder-wrapper .query-builder-col > .query-title text:last-child {
    font: normal normal normal 1.04vw/2.2vh 'Montserrat-Regular';
    color: #000;
    margin-left: 1vw;
}
.query-builder-wrapper .query-builder-col > .query-title text:last-child span {
    font: normal normal normal 1.04vw/2.2vh 'Montserrat-Regular';
    font-weight: 600;
    color: #000;
    padding: 6px 10px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #e6e6e6;
    /* margin-left: 1vw; */
}
.stat-card > .stat-details-holder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
}

.stat-card .stat-details-holder > .stat-details {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    padding: 0 1.56vw;
    margin: 1.85vh auto;
}

.stat-card .stat-details-holder .stat-details > div:first-child {
    font: normal normal normal 1.04vw/2.2vh 'Montserrat-Medium';
    text-align: left;
}

.stat-card .stat-details-holder .stat-details > div:nth-child(2) {
    font: normal normal normal 0.93vw/2.03vh 'Montserrat-Regular';
    text-align: left;
}

.stat-card .stat-details-holder .stat-details > div:last-child {
    display: flex;
    flex-direction: column;
    text-align: right;
}

.stat-card .stat-details-holder .stat-details div:last-child .stat-card-status {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: flex-end;
    justify-content: space-between;
}

.stat-card .stat-details-holder .stat-details div:last-child .stat-card-status > text {
    color: #000;
    font: normal normal normal 0.72vw/1.66vh 'Montserrat-Regular' !important;
}

.stat-card .stat-details-holder .stat-details div:last-child > text {
    color: #707070;
    font: normal normal normal 0.67vw/1.48vh 'Montserrat-Regular';
}

/* Accordian Styles */
.query-builder-col > .accordian-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
}

.query-builder-col .accordian-wrapper > .accordian-bundle {
    background: #f6f7fb;
}

/*  Info Panel Styles */
.query-builder-col .accordian-wrapper > .info-panel-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.query-builder-col .accordian-wrapper .info-panel-wrapper .accordian-info > .info-panel {
    position: relative;
}

.query-builder-col .accordian-wrapper .info-panel-wrapper .accordian-info .info-panel hr {
    margin-left: 1.56vw;
    display: block;
    border-top: 1px solid #ccc;
    padding: 0 !important;
    width: calc(100% - 1.56vw);
}

.query-builder-col .accordian-wrapper .info-panel-wrapper .accordian-info .info-panel > .info-panel-title {
    padding-left: 1.51vw;
    padding-right: 1.51vw;
    padding-top: 3.05vh;
    color: #1d679e;
    font: normal normal normal 1.14vw/2.5vh 'Montserrat-Medium';
}

.query-builder-col .accordian-wrapper .info-panel-wrapper .accordian-info > .info-panel {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 0px 10px #00000029;
    align-items: flex-start;
    /* height: 42vh; */
    /* overflow: auto; */
}

.query-builder-col .accordian-wrapper .info-panel-wrapper .accordian-info .info-panel > .info-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    padding-left: 1.51vw;
    margin-right: 0.52vw;
    margin-bottom: 3.05vh;
    /* height: 42vh; */
    overflow: auto;
}

.query-builder-col
    .accordian-wrapper
    .info-panel-wrapper
    .accordian-info
    .info-panel
    > .info-details::-webkit-scrollbar {
    width: 5px;
    background-color: #f2f0f0;
}

.query-builder-col
    .accordian-wrapper
    .info-panel-wrapper
    .accordian-info
    .info-panel
    > .info-details::-webkit-scrollbar-thumb {
    /* background: #707070; */
    color: #707070;
    background-color: #ccc;
}

.query-builder-col
    .accordian-wrapper
    .info-panel-wrapper
    .accordian-info
    .info-panel
    > .info-details::-webkit-scrollbar-track {
    /* border: 1px solid #707070; */
    border-radius: 5px;
}

.query-builder-col .accordian-wrapper .info-panel-wrapper .accordian-info .info-panel .info-details > .info-panel-desc {
    color: #707070;
    font: normal normal normal 1.04vw/2.5vh 'Montserrat-Regular';
    font-size: 14px;
    line-height: 1.5;
}

.query-builder-col .accordian-wrapper .info-panel-wrapper .accordian-info .info-panel .info-details > .info-panel-desc {
    margin-bottom: 1.5vh;
    padding-right: 1.04vw;
}

.query-builder-col
    .accordian-wrapper
    .info-panel-wrapper
    .accordian-info
    .info-panel
    .info-details
    > .info-details-wrapper {
    padding-right: 1.04vw;
    margin-bottom: 0.5vh;
}

.query-builder-col
    .accordian-wrapper
    .info-panel-wrapper
    .accordian-info
    .info-panel
    .info-details
    .info-details-wrapper
    > .info-details-title {
    font: normal normal normal 1.04vw/2.22vh 'Montserrat-Medium';
    margin-bottom: 0.46vh;
    font-size: 15px;
}

.query-builder-col
    .accordian-wrapper
    .info-panel-wrapper
    .accordian-info
    .info-panel
    .info-details
    .info-details-wrapper
    > .info-details-info {
    color: #707070;
    font: normal normal normal 1.04vw/2.5vh 'Montserrat-Regular';
    font-size: 14px;
    line-height: 1.5;
}

/* Accordian Tabs */
.query-builder-col .accordian-wrapper .accordian-bundle > .accordian-tabs {
    display: flex;
    flex-direction: column;
    overflow: initial;
}

.query-builder-col .accordian-wrapper .accordian-bundle .accordian-tabs .faux-wrapper .accordian-tab > input {
    position: absolute;
    opacity: 0;
    z-index: -1;
}

.query-builder-col .accordian-wrapper .accordian-bundle .accordian-tabs .faux-wrapper > .accordian-tab {
    align-items: flex-start;
    text-align: left;
    border-bottom: 1px solid #2c333e;

    overflow: hidden;
}

.query-builder-col .accordian-wrapper .accordian-bundle .accordian-tabs .faux-wrapper .accordian-tab > label {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    font: normal normal normal 1.14vw/2.5vh 'Montserrat-Regular';
    color: #2c333e;
    padding: 1.66vh 1.56vw;
    cursor: pointer;
}

.query-builder-col .accordian-wrapper .accordian-bundle .accordian-tabs .faux-wrapper .accordian-tab > label::after {
    content: url('../../../assets/images/ssa-accordian-icons/default-arrow.png');
    width: 0.52vw;
    transform: scale(0.75);
    text-align: center;
    transition: all 0.3s;
}

.query-builder-col
    .accordian-wrapper
    .accordian-bundle
    .accordian-tabs
    .faux-wrapper
    .accordian-tab
    > .accordian-content {
    max-height: 0;
    opacity: 0;
    background: #fff;
}

.query-builder-col
    .accordian-wrapper
    .accordian-bundle
    .accordian-tabs
    .faux-wrapper
    .accordian-tab
    > input:checked
    ~ .accordian-content {
    opacity: 1;
    max-height: 100%;
    padding: 0.5vh 1.37vw 0.5vh 2.6vw;
    margin-bottom: 2vh;
}

.query-builder-col
    .accordian-wrapper
    .accordian-bundle
    .accordian-tabs
    .faux-wrapper
    .accordian-tab
    > input:checked
    ~ label {
    font: normal normal 600 1.14vw/2.5vh 'Montserrat-SemiBold';
}

.query-builder-col
    .accordian-wrapper
    .accordian-bundle
    .accordian-tabs
    .faux-wrapper
    .accordian-tab
    > input:checked
    ~ label::after {
    content: url('../../../assets/images/ssa-accordian-icons/activeExpand.png');
    position: relative;
    left: -0.4vw;
    transform: scale(0.75);
}

/* Preview Tab */

.preview-tab-aside > text {
    position: absolute;
    top: 11vh;
    left: -10px;
    transform: rotate(-90deg);
    font: normal normal normal 1.04vw/2.5vh 'Montserrat-Medium';
    color: #1d679e;
}

.preview-tab-aside > img {
    height: 18px;
    width: 18px;
    position: absolute;
    top: 17vh;
    left: 12px;
}

.final-buttons {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 40px auto 20px auto;
    align-items: center;
    justify-content: center;
}

.final-buttons > div {
    display: flex;
    flex-direction: row;
    /* width: 50%; */
}

.final-buttons div > div:first-child {
    height: 4.1vh;
    width: 9.1vw;
    padding: 0.92vh 1vw;
    margin-right: 1.5vw;
    margin-left: 1.5vw;
    background: #1d679e;
    border-radius: 5px;
    color: #fff;
    font: normal normal normal 1.04vw/2.2vh 'Montserrat-Regular';
    cursor: pointer;
}

.final-buttons div > div:first-child:hover {
    background: #214c6c;
}
.final-buttons div > .disabled {
    cursor: default !important;
    background-color: #00000029 !important;
}
.final-buttons div > .disabled:hover {
    background-color: #00000029 !important;
}

.final-buttons div > div:nth-child(2) {
    height: 4.1vh;
    width: 9.1vw;
    padding: 0.62vh;
    margin-right: 1.5vw;
    margin-left: 1.5vw;
    color: #1d679e;
    background: #fff;
    border: 3px solid #216ba1;
    font: normal normal normal 1.04vw/2.2vh 'Montserrat-Regular';
    border-radius: 5px;
    cursor: pointer;
}

.information-bar-container {
    background-color: #fff;
    /* width:100px;
    height:20px; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    width: 100%;
    height: 2.5rem;
    border-width: 0.08rem;
    border-style: solid;
    border-color: #1d679e;
    border-radius: 0.2rem;
    font-size: 18px;
}
.status-container {
    padding: 0px 10px;
    width: auto;
    height: 2.4rem;
    border-right-width: 0.08rem;
    border-width: 0 0.08rem 0 0;
    border-style: solid;
    border-color: #1d679e;
    display: flex;
    justify-content: center;
    align-self: center;
    align-items: center;
    color: #707070;
    font-weight: bold;
    font-size: 1.1vw;
}
.analysis-name-container {
    margin-left: 1.6rem;
    color: #1d679e;
    text-overflow: ellipsis;
    font-size: 1.1vw;
}
.left-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.1vw;
}
.right-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.1vw;
}
.vertical-bar {
    border-width: 0 0.08rem 0 0;
    border-style: solid;
    border-color: #1d679e;
    width: 0.5rem;
    height: 1rem;
    margin-right: 1.6rem;
    font-size: 1.1vw;
}
.edit-text {
    color: #707070;
    font-size: 1.1vw;
}
.edit-user-details {
    color: #707070;
    font-weight: 600;
    font-size: 1.1vw;
}

.reset-div-query-builder {
    display: inline-block;
    border: 1px solid #e3e3e3;
    padding: 8px 10px 8px 8px;
    border-radius: 5px;
}
