.files-container-view {
    display: flex;
    width: 98%;
    background-color: #fff;
    flex-direction: column;
    margin: 12px auto;
    border: 1px solid #e4e4f1;
    border-radius: 0 0 10px 10px;
    /* padding-bottom: 2%; */
}

.tab-container-view {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0.8rem 0;
    align-items: flex-start;
    border-radius: 3px 3px 0 0;
    overflow-x: auto;
    overflow-y: hidden;
    /* white-space: nowrap; */
}

.selected-active-tab {
    background: none;
    min-width: 4rem;
    height: 2.8rem;
    text-align: left;
    align-items: flex-start;
    padding: 0 1rem;
    color: #000;
    opacity: 0.7;
    border-radius: 3px 3px 0 0;
    border-top: 1px solid #e4e4f1;
    border-bottom: 0;
    border-left: 1px solid #e4e4f1;
    border-right: 1px solid #e4e4f1;
    cursor: pointer;
    /* white-space: nowrap; */
}

.tab-container-view > .file-tab {
    border-bottom: 1px solid #e4e4f1;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    background: none;
    min-width: 4rem;
    height: 2.8rem;
    text-align: left;
    align-items: flex-start;
    padding: 0 1rem;
    color: #3b8bc6;
    border-radius: 3px 3px 0 0;
    cursor: pointer;
}

.tab-separator {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    bottom: -10px;
    width: 1px;
    height: 1.5rem;
    background: #ccc;
}

.occupator-layout {
    flex-grow: 1;
    border-bottom: 1px solid #e4e4f1;
    height: 2.8rem;
}
.occupator-1-layout {
    /* flex-grow: 1; */
    width: 1rem;
    border-bottom: 1px solid #e4e4f1;
    height: 2.8rem;
}

.tab-text-style {
    font: normal normal 500 0.9rem/1.06rem 'Montserrat-Regular';
}
