.df-detailed-rpt-link-btn {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    font-family: 'Montserrat-Medium';
    border-radius: 3px;
}

.df-detailed-rpt-link-btn:hover {
    color: #1d679e;
    background-color: #e6f5ff;
    border: 1px solid #1d679e;
    font-family: 'Montserrat-Medium';
}

.df-detailed-rpt-card-info {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-top: 7.5vh;
}

.df-detailed-rpt-btn {
    width: fit-content;
    padding: 5px 10px;
    color: #1d679e;
    background: none;
    cursor: pointer;
    border: 1px solid #1d679e;
    border-radius: 3px;
    content: fit-content;
    font-size: 14px;
    font-family: 'Montserrat-Regular';
    white-space: nowrap;
}

.df-detailed-vendor-info {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.datafactory-nav-home-grid-container {
    width: 100%;
    overflow: auto;
    margin-bottom: 20px;
}

.dQqvnT,
.dmKetv,
.hUiCcF {
    margin: 0px 0px !important;
}

.dfcardsListContainer {
    border: 1px solid #dfe0e3;
    background-color: #edeef1;
    margin: 1vw;
    border-radius: 5px;
    color: #000000;
    min-height: 50px;
    overflow: auto;
    padding: 1rem;
    overflow: hidden !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.datafactory-nav-home-grid-container::-webkit-scrollbar {
    display: none;
}

.datafactory-subsection-div {
    padding-bottom: 20px;
    border-bottom: 1px solid #e4e4f1;
    margin-bottom: 20px;
}
.datafactory-subsection-head {
    text-align: left;
    color: #000000;
    font-family: 'Montserrat-Medium';
    font-size: 18px;

    font-weight: 500;
}
.datafactory-subsection-cardhead {
    text-align: left;
    color: #000000;
    font-family: 'Montserrat-Medium';
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
    margin-bottom: 15px;
}
.datafactory-subsection-cardhead {
    margin-left: 10px;
}

.datafactory-subsection-desc {
    color: #000000;
    font-family: 'Montserrat-Regular';
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
}

.datafactory-subsection-nav {
    font-family: 'Montserrat-SemiBold';
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    a {
        color: #5199cd;
    }
    a:hover {
        text-decoration: none;
    }
}

.datafactory-subsection-last-refresh {
    color: #000;
    font-family: 'Montserrat-Medium';
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    float: right;
}

.datafactory-subdiv:focus {
    background-color: #1d679e;
}

.datafactory-subdiv {
    margin-left: 30px;
    border-radius: 3px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
    height: 340px;
    min-width: 26.5vw;
    // max-width: 408px;
    padding: 30px;
    margin-right: 20px;
    margin-bottom: 20px;
    // box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
    background-color: #ffffff;
    // align-items: center;
    // cursor: pointer;
    position: relative;
}
.datafactory-subdiv-lastcard {
    border-radius: 3px;
    // box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
    height: 340px;
    width: 425px;
    padding: 30px;
    position: relative;
    margin-right: 0px;
    margin-bottom: 0px;
}
.df-sidebutton-sub {
    padding: 5px 10px;
    color: #1d679e;
    background-color: #ffffff;
    cursor: pointer;
    border: 1px solid #1d679e !important;
    border-radius: 3px;
}

.datafactory-cardsList {
    border-radius: 3px;
    min-height: 8rem;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
    height: auto;
    width: 22vw;
    border-radius: 3px;
    background-color: #ffffff;
    padding: 10px;
    position: relative;
}
.single-liner-info {
    margin-bottom: 0.8rem;
}
.new-card-icon {
    position: absolute;
    top: 10px;
    right: -15px;
}
.datafactory-bookmark {
    position: absolute;
    bottom: 10px;
    right: 15px;
}

.datafactory-subCard-bottom-txt {
    float: left;
    // display: table;
    height: 55px;
    top: 30px;
}

.datafactory-subCard-bottom-txt-activity {
    // position: absolute;
    display: table;
    bottom: 10px;
    text-align: left;
}

.datafactory-text {
    display: flex;
    float: left;
    margin-bottom: 60px;
    position: absolute;
    width: 213px;
    height: 17px;
    left: 52px;
    top: 36px;
    color: #5199cd;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
}

.datafactory-discontinued {
    display: flex;
    float: left;
    margin-bottom: 60px;
    position: absolute;
    border: 2px solid red;
    border-radius: 3px;
    padding: 3px;
    //    padding-bottom:  2px;
    left: 52px;
    top: 36px;
    color: #5199cd;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    // line-height: 17px;
}

.datafactory-productionReport-subheading-text {
    display: flex;
    float: left;
    margin-bottom: 60px;
    margin-top: 8px;
    position: absolute;
    width: 213px;
    height: 17px;
    left: 53px;
    top: 36px;
    color: #000000;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
}
.df-top-status {
    align-items: center;
    display: flex;
    flex-direction: row;
    position: absolute;
    right: 8px;
}

.datafactory-vendor-bookmark {
    width: auto;
    align-items: center;
    display: flex;
    flex-direction: row;
    position: absolute;
    bottom: 10px;
    right: 10px;
}
@media only screen and (min-width: 2500px) {
    .datafactory-subdiv {
        height: 300px;
        max-width: 31.3vw;
    }
    .datafactory-cardsList {
        height: 120px;
        max-width: 250px;
    }
}
@media only screen and (min-width: 1134px) {
    .datafactory-subdiv {
        height: 365px;
        max-width: 31.3vw;
    }
    .df-detailed-rpt-card-info {
        margin-top: 7vh;
    }
}
@media only screen and (min-width: 1220px) {
    .datafactory-subdiv {
        height: 360px;
        max-width: 31.3vw;
    }
    .datafactory-cardsList {
        height: 130px;
    }
}

@media only screen and (min-width: 1250px) {
    .datafactory-subdiv {
        height: 360px;
        max-width: 31.3vw;
    }
    .datafactory-cardsList {
        height: 130px;
    }
}
@media only screen and (min-width: 1420px) {
    .datafactory-subdiv {
        height: 310px;
        max-width: 31.3vw;
    }
    .datafactory-cardsList {
        height: 150px;
    }
}
@media only screen and (min-width: 1520px) {
    .datafactory-subdiv {
        height: 310px;
        max-width: 31.3vw;
    }
    .datafactory-cardsList {
        height: 150px;
    }
    .df-detailed-rpt-card-info {
        margin-top: 8.5vh;
    }
}
@media only screen and (min-width: 1680px) {
    .datafactory-subdiv {
        height: 300px;
        max-width: 31.3vw;
    }
    .datafactory-cardsList {
        height: 120px;
    }
    .df-detailed-rpt-card-info {
        margin-top: 7vh;
    }
}
@media only screen and (min-width: 1750px) {
    .datafactory-subdiv {
        height: 300px;
        max-width: 31.3vw;
    }
    .datafactory-cardsList {
        height: 120px;
    }
    .df-detailed-rpt-card-info {
        margin-top: 7vh;
    }
}
@media only screen and (min-width: 1840px) {
    .datafactory-subdiv {
        height: 300px;
        max-width: 31.3vw;
    }
    .datafactory-cardsList {
        height: 120px;
    }
    .df-detailed-rpt-card-info {
        margin-top: 6vh;
    }
}
@media only screen and (min-width: 2040px) {
    .datafactory-subdiv {
        height: 300px;
        max-width: 31.3vw;
    }
    .datafactory-cardsList {
        height: 120px;
    }
}

.datafactory-subdiv-txt {
    // color: #fff;
    font-size: 20px;
    font-family: 'Montserrat-SemiBold';
    font-weight: 500;
    margin-left: 15px;
    margin-top: 10px;
}
.datafactory-subdiv-txt-cardsList {
    margin-left: 10px;
    width: 75%;
    float: left;
    text-align: left;
    vertical-align: middle;
    color: #000000;
    font-size: 0.9em;
    font-family: 'Montserrat-SemiBold';
    font-weight: 500;
}

.datafactory-subCard-txt {
    margin-top: 5px;
    margin-left: 10px;
    margin-bottom: 12px;
    width: 80%;
    float: left;
    text-align: left;
    vertical-align: middle;
    color: #000000;
    font-size: 17px;
    font-family: 'Montserrat-SemiBold';
    font-weight: 500;
}

.datafactory-subCard-txt-activity-tracker {
    margin-top: 5px;
    margin-left: 10px;
    width: 80%;
    float: left;
    text-align: left;
    vertical-align: middle;
    color: #000000;
    font-size: 17px;
    font-family: 'Montserrat-SemiBold';
    font-weight: 500;
}

.datafactory-subCard-txt-regular {
    margin-block: auto;
    margin-left: 10px;
    width: 95%;
    float: left;
    text-align: left;
    vertical-align: middle;
    color: #000000;
    font-size: 17px;
    font-family: 'Montserrat-Regular';
}

.datafactory-act-subdiv-txt-cardsList {
    margin-left: 10px;
    width: 75%;
    float: left;
    text-align: left;
    vertical-align: left;
    color: #000000;
    font-size: 16px;
    font-family: 'Montserrat-Regular';
}
.top-margin-10 {
    margin-top: 5px;
}
.datafactory-subdiv-desc {
    // color: #fff;
    text-align: left;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    font-family: 'Montserrat-Regular';
    margin-top: 15px;
    margin-bottom: 15px;
}

/* Inside auto layout */

.datafactory-card-icon {
    vertical-align: top;
    width: 50px;
    height: 50px;
}

.members {
    width: 73px;
    height: 18px;

    font-family: 'Montserrat-Regular';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: right;

    color: #96999f;

    flex-direction: row;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
}

.datafactory-card-icon-cardsList {
    text-align: left !important;
    margin-right: 6px;
    width: 15px;
    height: 15px;
    margin-bottom: 2px;
}

.datafactory-new-card-icon-cardsList {
    width: 65px;
    height: 30px;
}

.datafactory-cardButton-default,
.datafactory-cardButton,
.datafactory-cardButton button {
    float: left;
    text-align: left;
    width: 114px;
    padding: 5px 10px;
    color: #1d679e;
    background-color: #ffffff;
    cursor: pointer;
    border-radius: 3px;
    border: 1px solid #1d678e;
    content: fit-content;
    font-size: 14px;
    font-family: 'Montserrat-Regular';

    // background-color: #ffffff;
}

.datafactory-vrButton-default,
.datafactory-vrButton {
    color: black;
    position: absolute;
    bottom: 20px;
    left: 30px;

    // background-color: #ffffff;
}

.datafactory-vrButton button {
    float: left;
    text-align: left;
    width: 140px;
    padding: 5px 10px;
    color: black;
    background-color: #ffffff;
    cursor: pointer;
    border-radius: 3px;
    border: none;
    content: fit-content;
    font-size: 14px;
    font-family: 'Montserrat-Regular';
}

.datafactory-vrButton-default button {
    width: fit-content;
    padding: 5px 10px;
    color: #1d679e;
    background-color: #ffffff;
    cursor: pointer;
    border: 1px solid #1d679e !important;
    border-radius: 3px;
    border: none;
    content: fit-content;
    font-size: 14px;
    font-family: 'Montserrat-Regular';
}

.datafactory-cardsList-vrButton {
    position: absolute;
    bottom: 15px;
    left: 15px;
    width: 100%;
    height: 30px;
    float: left;
    text-align: left;
    vertical-align: bottom;
}

.datafactory-vendorButton {
    text-align: center;
    position: absolute;
    width: 124px;
    height: 30px;
    float: left;
    border: 1px solid #1d679e !important;
    list-style-type: none !important;
}

.datafactory-viewButton {
    text-align: center;
    position: absolute;
    bottom: 15px;
    left: 15px;
    width: 124px;
    padding-top: 2px;
    height: 30px;
    float: left;
    border: 1px solid #1d679e !important;
    text-decoration: none !important;
}

.datafactory-viewButton-PR-listCard {
    text-align: center;
    position: absolute;
    bottom: 12px;
    left: 10px;
    width: 124px;
    padding-top: 2px;
    height: 30px;
    float: left;
    border-radius: 3px;
    border: 1px solid #1d679e !important;
    text-decoration: none !important;
}

.datafactory-viewButton-relative {
    text-align: center;
    position: initial;
    bottom: 4px;
    left: 4px;
    width: 124px;
    height: 30px;
    float: left;
    border-radius: 3px;
    border: 1px solid #1d679e !important;
    list-style-type: none !important;
    padding-top: 2px;
    text-decoration: none !important;
}

.datafactory-viewButton-vendor {
    text-align: center;
    position: absolute;
    width: 124px;
    padding-top: 2px;
    height: 30px;
    float: left;
    border-radius: 3px;
    border: 1px solid #1d679e !important;
    text-decoration: none !important;
}

.datafactory-cardsList-vrButton button {
    width: fit-content;
    padding: 5px 10px;
    color: #1d679e;
    background-color: #ffffff;
    cursor: pointer;
    border: 1px solid #1d679e !important;
    border-radius: 3px;
    border: none;
    content: fit-content;
    font-size: 14px;
    font-family: 'Montserrat-Regular';
}

.df-hover-pr {
    opacity: 1;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2), 0px 0px 1px rgba(0, 0, 0, 0.31);
    background-color: '#ffffff';
    width: fit-content;
    height: fit-content;
    align-items: 'center';
    align-self: center;
    align-content: center;
    padding: 1;
    border-radius: 5;
}

.df-hover-title {
    opacity: 1;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2), 0px 0px 1px rgba(0, 0, 0, 0.31);
    background-color: '#ffffff';
    width: fit-content;
    height: fit-content;

    padding: 1;
    border-radius: 5;
}

.df-hover-pr-List {
    opacity: 1;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2), 0px 0px 1px rgba(0, 0, 0, 0.31);
    background-color: '#ffffff';
    width: fit-content;
    height: fit-content;
    align-items: center;
    align-self: center;
    align-content: center;
    padding: 1;
    border-radius: 5;
}

.d-grid {
    display: grid;
}

.grid-col-3 {
    grid-template-columns: 1fr 1fr 1fr;
}

.grid-col-align {
    grid-template-columns: 1fr 1fr 1fr;
}

.row-align {
    margin-right: -15px;
    margin-left: -15px;
    width: 100%;
}
// .eShlAS,
// .ijrtgg {
//   min-width: 35px !important;
//   width: 35px !important;
//   background-color: rgba(255, 255, 255, 0.7) !important;
// }
// .dmKetv,
// .hUiCcF {
//   margin-right: 10px !important;
//   margin-left: 0px !important;
// }
// .rec-slider-container {
//   margin-right: 10px !important;
//   margin-left: 0px !important;
// }
// .dQqvnT {
//   overflow: hidden !important;
//   position: relative !important;
//   width: 100% !important;
//   margin-right: 10px !important;
//   margin-left: 0px !important;
// }
/* square buttons */
.d-grid {
    display: grid;
}

.grid-col-3 {
    grid-template-columns: 1fr 1fr 1fr;
}

.grid-col-align {
    grid-template-columns: 1fr 1fr 1fr;
}

.row-align {
    margin-right: -15px;
    margin-left: -15px;
    width: 100%;
}
.eShlAS,
.ijrtgg {
    min-width: 35px !important;
    width: 35px !important;
    background-color: rgba(255, 255, 255, 0.7) !important;
}
.dmKetv,
.hUiCcF {
    margin-right: 10px !important;
    margin-left: 0px !important;
}
.rec-slider-container {
    margin-right: 10px !important;
    margin-left: 0px !important;
}
.dQqvnT {
    overflow: hidden !important;
    position: relative !important;
    width: 100% !important;
    margin-right: 10px !important;
    margin-left: 0px !important;
}
.rec.rec-arrow {
    border-radius: 6px;
    background-color: rgba(255, 255, 255, 0.7);
}
.rec-arrow-left {
    left: 2.5rem !important;
    position: absolute;
    z-index: 100;
}
.rec-arrow-right {
    // position: absolute;
    // right: 45px;
    z-index: 100;
}
/* round buttons on hover */
.rec.rec-arrow:hover {
    border-radius: 6px;
    background-color: rgba(255, 255, 255, 0.7) !important;
}
/* hide disabled buttons */
.rec.rec-arrow:disabled {
    visibility: hidden;
}
/* disable default outline on focused items */
/* add custom outline on focused items */
.rec-carousel-item:focus {
    outline: none;
    box-shadow: inset 0 0 2px 2px lightgrey;
}

// -----------DataFactory Home Page--------------

.df-overview-container {
    height: 100%;
    .loader-div {
        position: absolute; //relative;
        top: 10;
        right: 20;
        bottom: 10;
        left: 20;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.nav-home-grid-container {
    background-color: #ffffff;
    border-radius: 10px;
    border: 1px solid lightgrey;
    padding: 25px;
    height: 100%;
    overflow: auto;
}

.nav-home-grid-container::-webkit-scrollbar {
    display: none;
}

.nav-home-grid-container1 {
    background: #fff;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    border: 1px solid lightgrey;
    display: grid;
    overflow: auto;
    //grid-template-columns: repeat(2, minmax(0, 1fr));
}
.nav-home-grid-container2 {
    background: #fff;
    border-right: 1px solid lightgrey;
    border-bottom: 1px solid lightgrey;
    border-top: 1px solid lightgrey;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    overflow: auto;
}
.nav-home-grid-container2::-webkit-scrollbar {
    display: none;
}

.nav-home-description {
    text-align: left;
    font-size: 14px;
    height: fit-content;
    padding: 20px;
}

.home-subtitle {
    display: flex;
    justify-content: flex-start;
    align-items: left;
    width: 100%;
    padding: 16px 0 14px 25px;
    background: #f9fdff;
    border-bottom: 1px solid lightgrey;
    border-top: 1px solid lightgrey;
    color: #000000;
    font-size: 16px;
    font-family: 'Montserrat-SemiBold';
    font-weight: 600;
}
.home-subtitle:first-child {
    border-top: none !important;
}
.home-subnav-link {
    display: flex;
    padding-bottom: 20px;
    color: #5199cd;
    a {
        text-align: left;
    }
    a:hover {
        text-decoration: none;
    }
}
.link-arrow {
    margin-right: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.subpage-links {
    color: #5199cd;
    font-family: 'Montserrat-Medium';
    font-size: 15px;
    font-weight: 500;
    // text-align: left;
    a {
        text-align: left;
    }
}

.df-home-sub-head {
    font-size: 14px;
    font-weight: 600;
    color: #000;
    font-family: 'Montserrat-SemiBold';
    line-height: normal;
    text-align: left;
}

.df-home-act-tra-txt {
    font-size: 15px;
    font-weight: 500;
    color: #363636;
    font-family: 'Montserrat-Regular';
    line-height: normal;
    text-align: left;
    margin-top: 6px;
    overflow: hidden;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.df-home-normal-text {
    margin-left: 6px;
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    font-family: 'Montserrat-Regular';
    line-height: normal;
}

.df-home-export-view-ic {
    margin-left: 19px; //20px
    cursor: pointer;
    padding: 1px;
}

.df-home-dt-col-head {
    font-size: 16px;
    font-weight: 600;
    color: #1d679e;
    font-family: 'Montserrat-SemiBold';
    line-height: normal;
    text-align: left;
}

.df-home-act-tra-head {
    margin-bottom: 5px;
    line-height: 24px;
    text-align: left;
}

.df-home-act-tra {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06);
    padding: 15px;
    background: #fff;
}

.tooltip {
    background-color: transparent !important;
    border: 0px !important;
}

.tooltip-inner {
    background-color: #fff !important;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    border: 1px solid #e4e4f1;
    padding: 12px 10px;
    max-width: 500px;
    max-height: 230px;
    overflow: auto;
}

.status-hover-desc::-webkit-scrollbar,
.tooltip-inner::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

.status-hover-desc::-webkit-scrollbar-track,
.tooltip-inner::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.status-hover-desc::-webkit-scrollbar-thumb,
.tooltip-inner::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #c0c0c0;
}

.tooltip .arrow {
    height: 15px;
    width: 12px;
    border-top-color: #e4e4f1;
}

.bs-tooltip-auto[x-placement^='top'] .arrow::before,
.bs-tooltip-top .arrow::before {
    border-width: 22px 12px 0;
    border-top-color: #fff;
}

.bs-tooltip-auto[x-placement^='bottom'] .arrow::before,
.bs-tooltip-bottom .arrow::before {
    border-width: 0 12px 22px;
    border-bottom-color: #fff;
    bottom: 5px;
}

.df-home-tooltip {
    color: #000;
    text-align: left;
    font-size: 15px;
    font-weight: 400;
    font-family: 'Montserrat-Regular';
}

.df-cardsList-vrButton {
    position: absolute;
    bottom: 10px;
    left: 10px;
    width: 100%;
    height: 30px;
    float: left;
    text-align: left;
    vertical-align: bottom;
}
/*Hide default sorting*/
table.mdb-dataTable thead .sorting:hover::before,
table thead.mdb-dataTable-head .sorting:hover::before {
    opacity: 0 !important;
    display: none !important;
}

table.mdb-dataTable thead .sorting_asc:before,
table.mdb-dataTable thead .sorting_desc:after,
table thead.mdb-dataTable-head .sorting_asc:before,
table thead.mdb-dataTable-head .sorting_desc:after {
    opacity: 0 !important;
    display: none !important;
}

.show.tooltip {
    z-index: 99999 !important;
}

.df-link {
    font-family: 'Montserrat-Medium' !important;
    font-size: 15px !important;
    color: #1d679e !important;
    cursor: pointer !important;
}

.bs-popover-auto[x-placement^='top'] > .arrow,
.bs-popover-top > .arrow {
    transform: translate(60px, 0px) !important;
}

.status-hover-desc {
    max-height: 165px;
    overflow: auto;
}

.vendor-cards-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 0.5rem 0.5rem 0.5rem;
    justify-content: space-between;
}

.vendor-cards-header-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.vendor-cards-header-title > text {
    font: normal normal normal 18px/22px 'Montserrat-Medium';
}

.production-card-header-separator {
    width: 2px;
    height: 15px;
    border-left: 1px solid #bbbbbb;
    background-color: #ccc;
    margin-left: 20px;
    margin-right: 20px;
}
.df-subsection-cardhead {
    position: relative;
    text-align: left;
    color: #000000;
    font-family: 'Montserrat-Medium';
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
    margin-bottom: 15px;
}
.df-subsection-cardhead {
    margin-left: 10px;
    margin-right: 10px;
}

.vendor-card-header-separator {
    width: 2px;
    height: 2.5vh;
    background-color: #ccc;
    margin: 0 1.2rem;
}

.vendor-card-file-status-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}

.vendor-actual-status {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    gap: 5px;
}

.vendor-card-file-status-circle-pass {
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 2px solid #fff;
    border-radius: 50%;
    background-color: #00cb5d;
}

.vendor-card-file-status-circle-fail {
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 2px solid #fff;
    border-radius: 50%;
    background-color: #ff0000;
}

.vendor-card-search-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    // justify-content: center;
}

.vendor-cards-search-input {
    height: 2.2rem;
    border-radius: 3px;
    border: 1px solid #ccc;
    padding: 5px 25px 5px 5px;
    position: relative;
    background-image: url('../../assets/images/search-icon.svg');
    background-size: 20px;
    background-position: right 5px center;
    background-repeat: no-repeat;
    font: normal normal normal 1.04vw/2vh 'Montserrat-Regular';
}

.vendor-cards-search-input::placeholder {
    opacity: 0.6;
}

.df-grid-cards-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1rem;
    float: left;
    width: 100%;
}

.df-prd-status {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 0 0 10px 0;
}

.df-prd-status-title {
    color: #000000;
    font-size: 17px;
    font-family: 'Montserrat-SemiBold';
    font-weight: 500;
    margin-left: 1rem;
    text-align: left;
}

.df-prd-status-info {
    color: #000000;
    font-size: 16px;
    font-family: 'Montserrat-Regular';
    font-weight: 100;
    margin-left: 1rem;
    text-align: left;
}

@media (max-width: 1040px) {
    .df-grid-cards-container {
        grid-template-columns: repeat(3, 1fr);
    }
    .datafactory-cardsList {
        min-width: 28vw;
    }
}

@media (max-width: 576px) {
    .df-grid-cards-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

/* SubLevel Card */
.subLevelCardWrapper {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding: 1.8rem;
    border-radius: 3px;
    box-shadow: 0px 0px 5px #00000029;
    gap: 1rem;
    position: relative;
   
}

.subLevelCardWrapper:focus{
    background-color: #1d679e;
}

.subLevelCardTitleWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.25rem;
    gap: 1rem;
    font-family: "Montserrat-SemiBold";
}

.subLevelDesc {
    font-family: "Montserrat-Regular";
    text-align: left;
    margin: 0 1rem;
    font-size: 1rem;
    padding-bottom: 5rem;
    line-height: 20px;
}

.subLevelBtn {
    position: absolute;
    bottom: 10%;
    color: #1d679e;
    border: 1px solid #1d679e;
    border-radius: 3px;
    padding: 0.25rem 0.8rem;
    background-color: #fff;
}

.rec .rec-item-wrapper{
    height: 100%;
}
