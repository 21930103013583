.reset-confirm {
    width: 31.25vw;
    height: 20vh;
    background: #fff;
    min-width: 400px;

}

.reset-confirm > .reset-message {
    padding: 0.8rem;
    display: flex;
    flex-direction: column;
}

.reset-confirm .reset-message > text {
    font: normal normal normal 1.14vw/2.22vh 'Montserrat-Regular';
    padding-top: 0.5rem;
    /* text-align: justify; */
}

.reset-confirm .reset-message > .options {
    display: flex;
    flex-direction: row;
    width: 35%;
    align-items: center;
    align-self: flex-end;
    justify-content: space-around;
    margin-top: 4.5rem;
}

.reset-confirm .reset-message .options > button:first-child {
    border: 1px solid #1d679e;
    background: #1d679e;
    color: #fff;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    border-radius: 3px;
    font-weight: bold;
}

.reset-confirm .reset-message .options > button:nth-child(2) {
    border: 1px solid #1d679e;
    color: #1d679e;
    background-color: #fff;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    border-radius: 3px;
    font-weight: bold;
}
