.profile-wrapper {
    margin: 20px;
    display: flex;
    flex-direction: row;
}

.profile-wrapper > .profile-left {
    display: flex;
    flex-direction: column;
    flex: 50rem 1 auto;
    align-items: flex-start;
}

.profile-wrapper .profile-left > .profile-info {
    display: flex;
    flex-direction: column;
    width: 28.12rem;
    background: #fff;
    padding: 10px;
    margin-right: 20px;
    box-shadow: 0px 2px 12px #0000000f;
    border-radius: 3px;
    margin-bottom: 10px;
}

.profile-wrapper .profile-left .profile-info > .user {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.profile-wrapper .profile-left .profile-info .user table {
    width: 100%;
    border-spacing: 0.8em;
    border-collapse: separate;
}

.profile-wrapper .profile-left .profile-info .user table tr > td {
    text-align: -webkit-center;
}

.profile-wrapper .profile-left .profile-info .user .initials {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.8rem;
    height: 2.8rem;
    background: #1d679e;
    border-radius: 50%;
    font: normal normal 600 14px/17px 'Monteserrat-Medium';
    color: #fff;
}

.profile-wrapper .profile-left .profile-info .user .initials-temp {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    background: #1d679e;
    font: normal normal 600 14px/17px 'Monteserrat-Medium';
    color: #fff;
}

.profile-wrapper > .profile-right {
    display: flex;
    flex-direction: column;
    flex: 3 1 auto;
    background-color: #edeef1;
    margin-bottom: 10px;
    padding: 20px;
    width: 100%;
    height: 89.5vh;
    overflow-y: auto;
}

.profile-wrapper .profile-right > .profile-title {
    text-align: left;
    margin-bottom: 15px;
    font-weight: bold;
}

.profile-wrapper .profile-right > .profile-card-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: min-content;
    /* display: flex; */
    /* flex-direction: row; */
    /* width: 100%; */
    gap: 1.25rem;
}

.profile-wrapper .profile-right .profile-card-wrapper > .card-col {
    display: flex;
    flex-direction: column;
}

.profile-wrapper .profile-right .profile-card-wrapper .card-col > .module-card {
    background: white;
    display: flex;
    flex-direction: column;
    min-height: 10rem;
    border-radius: 3px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
    padding: 15px 12px;
    margin-bottom: 0.8rem;
    max-height: 15rem;
    overflow-y: auto;
}

.profile-wrapper .profile-right .profile-card-wrapper .card-col .module-card > .module-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.profile-wrapper .profile-right .profile-card-wrapper .card-col .module-card .module-title > text {
    margin: 0 12px;
}

.profile-wrapper .profile-right .profile-card-wrapper .card-col .module-card > .module-grid-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: left;
    margin: 10px;
}

.profile-wrapper
    .profile-right
    .profile-card-wrapper
    .card-col
    .module-card
    .module-grid-list
    > .col-module {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.profile-wrapper
    .profile-right
    .profile-card-wrapper
    .card-col
    .module-card
    .module-grid-list
    .col-module
    > li {
    color: #1d679e;
    text-align: left;
}

.profile-wrapper .profile-right .profile-card-wrapper .card-col .module-card > .view-modules-btn {
    position: relative;
    bottom: 0;
    min-width: 5rem;
    width: auto;
    align-self: flex-end;
    border: 0;
    background: rgba(29, 103, 158, 0.1);
    font: normal normal normal 0.87rem/1.06rem 'Montserrat-Medium';
    color: #1d679e;
    border-radius: 3px;
    padding: 5px;
}

.profile-wrapper
    .profile-right
    .profile-card-wrapper
    .card-col
    .module-card
    > .view-modules-btn:hover {
    background: rgba(79, 152, 208, 0.4);
}

.text-style {
    font: normal normal normal 1.2rem/1.5rem 'Montserrat-Medium';
}

.text-style-small-bold {
    font: normal normal normal 0.8rem/0.9rem 'Montserrat-SemiBold';
}

.text-style-small-normal {
    font: normal normal normal 0.8rem/0.9rem 'Montserrat-Regular';
}

.text-style-two {
    font: normal normal normal 0.9rem/1.06rem 'Monteserrat-Medium';
}

.text-style-three {
    font: normal normal normal 1rem/1.2rem 'Montserrat-Medium';
}

.text-style-four {
    font: normal normal normal 1.06rem/1.25rem 'Montserrat-Regular';
}

.text-style-four-bold {
    font: normal normal normal 1.06rem/1.25rem 'Montserrat-Medium';
}
