.view-analysis-reports-table-alignment {
    width: 100%;
    overflow-x: auto;
    overflow-y: auto;
    height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.view-analysis-reports-table-alignment::-webkit-scrollbar {
    width: 3px !important;
    height: 6px !important;
    background-color: #f2f0f0;
}
.view-analysis-reports-table-alignment::-webkit-scrollbar-thumb {
    height: 6px !important;
    background-color: #ccc;
}
.view-analysis-reports-table {
    background: #ffffff 0% 0% no-repeat padding-box;
    opacity: 1;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    overflow-y: auto;
}
.view-analysis-reports-table::-webkit-scrollbar {
    width: 5px;
    background-color: #f2f0f0;
}
.view-analysis-reports-table::-webkit-scrollbar-thumb {
    background-color: #ccc;
}
.view-analysis-reports-table thead {
    /* border: 1px solid #1d679e; */
    box-shadow: 1px 1px 1px #1d679e;
    background: white;
    width: 100vw;
    position: sticky;
    /* display: block; */
    top: 0px;
    /* z-index: 10; */
}
.view-analysis-reports-table tbody {
    border: 1px solid #e0e0e0;
    /* height: 60vh; */
    /* height: 40vh;*/
    /* overflow-y: auto; */
    /* display: block; */
}
.view-analysis-reports-table table th {
    max-width: 100px;
    /* display: block; */
    text-align: left;
    box-shadow: inset 1px 1px 0px 0px #1d679e;
}
.view-analysis-reports-table .dataModules {
    color: #1d679e;
    font-size: 2.32vh;
    box-shadow: inset 1px 1px 0px 0px #1d679e;
    opacity: 1;
    word-break: break-all;
}
.view-analysis-reports-table .dataModules th {
    padding: 5px 0px;
    text-align: center;
    color: #1d679e;
    box-shadow: inset 1px 1px 0px 0px #1d679e;
    font-size: 2.32vh;
    /* border: 1px solid #1d679e; */
    /* display: block; */
    opacity: 1;
    word-break: break-all;
}
.view-analysis-reports-table .dataHeading {
    background: #f5fbff 0% 0% no-repeat padding-box;
    /* box-shadow: 0px 1px 3px #00000029;
     */
    box-shadow: inset 1px 1px 0px 0px #1d679e;
    /* font: normal normal medium 20px/24px Montserrat; */
    letter-spacing: 0.6px;
    color: #1d679e;
    opacity: 1;
    word-break: break-all;
}
.view-analysis-reports-table .dataHeading th {
    font-size: 2.32vh;
    text-align: center;
    box-shadow: inset 1px 1px 0px 0px #1d679e;
    word-break: normal;
}

.annoying-th{
    /* min-width: 200px;
    max-width: 300px; */
    width: 300px;
    min-width: 200px;
}

.view-analysis-reports-table tbody td {
    font-size: 2.03vh;
    text-align: center;
    border-right: 1px solid #e0e0e0;
    border-top: 1px solid #e0e0e0;
    color: #707070;
    padding: 4px 10px;
}

.view-analysis-reports-table .table-footer {
    float: left;
    width: 100%;
    height: 5vh;
    background-color: #f5fbff;
}
.view-analysis-reports-table .table-footer .analysis-id-name {
    float: left !important;
    text-align: left !important;
    width: fit-content;
    font-size: 13px;
    color: #707070;
    padding: 10px 5px;
    margin-left: 10px;
}
.view-analysis-reports-table .table-pagination {
    float: right;
    width: fit-content;
}
.view-analysis-reports-table .pagination-analysis-table {
    float: left;
    width: fit-content;
}

.view-analysis-reports-table .pagination-analysis-table .pagination-start {
    float: left;
    width: fit-content !important;
}
.view-analysis-reports-table .pagination-analysis-table {
    float: right;
}
.view-analysis-reports-table .pagination-analysis-table .pagination-previous {
    float: left;
    width: fit-content;
}
.active {
    color: blue;
}
.inactive {
    color: gray;
}

.totalRowData {
    font-weight: bold;
    background-color: #f1f3f5;
}

.totalLabelCell {
    padding-left: 1.2vw !important;
}

.hideHead {
    /* opacity: 0; */
    visibility: collapse;
}
