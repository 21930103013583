.react-datepicker-popper {
    z-index: 9999 !important;
}

.datepicker-wrapper {
    width: 13.02vw;
    /* margin: 0 1.5vw; */
}

.datepicker-wrapper > div.react-datepicker-wrapper,
.datepicker-wrapper > div > div.react-datepicker__input-container,
.datepicker-wrapper > div > div.react-datepicker__input-container input {
    margin: 0;
    width: 100%;
    font: normal normal normal 0.83vw/1.75vh 'Montserrat-Regular';
}

.datepicker-wrapper > div > div.react-datepicker__input-container input {
    border: 1px solid #bbbbbb;
    min-height: 5vh;
    border-radius: 5px;
    padding-left: 0.52vw;
}

.custom-date {
    border: 1px solid #bbbbbb;
    min-height: 5vh;
    border-radius: 5px;
    padding-left: 0.52vw;
    font: normal normal normal 0.83vw/1.75vh 'Montserrat-Regular';
}

.datepicker-wrapper
    > div
    > div.react-datepicker__input-container
    input::placeholder {
    font: normal normal normal 0.83vw/1.75vh 'Montserrat-Regular';
}

.paid-date-run-off {
    width: 50%;
    display: block;
    margin-left: 10px;
    float: left;
}
.paid-date-run-off div {
    float: left;
    width: fit-content !important;
}
.paid-date-run-off select {
    width: 80px;
    padding: 5px 7px;
    border-radius: 5px;
    border: 1px solid #bbbbbb;
    color: #bbbbbb;
}
.paid-date-run-off option {
    border: 1px solid #bbbbbb;
    color: #bbbbbb;
    background-color: #e6f5ff;
}
.paid-date-run-off span {
    float: left;
    width: 20%;
    margin-top: 10px;
    margin-left: 15px;
    font-size: 12px;
}
