.popup-user-table-wrapper {
    display: flex;
    flex-direction: column;
    /* width: 100%; */
    height: 100%;

    max-height: 48vh;
    overflow-y: auto;
    /* position: relative; */
}

.popup-user-table-wrapper::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.popup-user-table-wrapper table {
    border-collapse: collapse;
    width: 100%;
    margin-top: 0;
}

.popup-user-table-wrapper table thead tr th {
    position: sticky;
    height: 3.7vh;
    z-index: 100;
    margin-top: 0;
    width: auto;
    top: 0;
}

.popup-user-table-wrapper .popup-user-table tr th {
    background: #1d679e;
    color: #fff;
    font: normal normal normal 0.8vw/1.7vh 'Montserrat-Medium';
    text-align: left;
    padding: 10px 20px;
}

.popup-user-table-wrapper .popup-user-table tbody tr td {
    text-align: left;
    font: normal normal normal 0.8vw/1.7vh 'Montserrat-Regular';
    color: #000;
    padding: 10px 20px;
}

.popup-user-table-wrapper .popup-user-table tbody tr:nth-child(odd) {
    background: #fff;
}

.popup-user-table-wrapper .popup-user-table tbody tr:nth-child(even) {
    background: #f6f7fb;
}

.popup-user-table-icon-sort {
    display: inline-block;
    position: relative;
    left: 0;
    top: 2px;
    vertical-align: 2px;
    padding: 0 2px;
}

/* Row selection checkbox styles */

.row-selection-checkbox {
    opacity: 0;
    position: relative;
    left: 10px;
    width: 1rem;
    height: 1rem;
    z-index: 5;
}

.row-selection-checkbox + label {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.row-selection-checkbox + label::before {
    content: '';
    display: inline-block;
    position: absolute;
    width: 0.7rem;
    height: 0.7rem;
    outline: 1px solid #707070;
    background: #fff;
    cursor: pointer;
    top: 0;
    left: 0;
}

.row-selection-checkbox:checked + label::after {
    content: '';
    display: inline-block;
    position: absolute;
    height: 0.7rem;
    left: 2px;
    width: 0.4rem;
    border: solid #707070;
    border-width: 0 2px 2px 0;
    transform: rotate(42deg);
    cursor: pointer;
}

.popup-search-table {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1vw;
    border: 1px solid #1d679e;
    padding: 0.4rem 0.3rem;
    border-radius: 5px;
    width: 90%;
}

.popup-search-input {
    width: 100%;
    border: none;
    font: normal normal normal 0.9vw/2vh 'Montserrat-Medium';
}

.popup-search-input::placeholder {
    font: normal normal normal 0.9vw/2vh 'Montserrat-Medium';
    color: #bbb;
}

.icon-search-popup {
    display: flex;
    flex-direction: column;
    position: relative;
}

.icon-search-popup:hover .hover-file-export {
    opacity: 1;
    visibility: visible;
}

.hover-file-export {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    bottom: -22px;
    left: 40%;
    transform: translateX(-50%);
    color: #1d679e;
    padding: 5px;
    border-radius: 5px;
    font: normal normal normal 0.8vw/2vh 'Montserrat-Regular';
    transition: visibility 0s, opacity 0.5s ease-in-out;
}
