.report-style-class {
  // height: 100%;
  // height: calc(100vh - 154px); //PROD
  height: calc(100vh - 190px); //DEV
  width: 100%;
  padding-bottom: 3vh;
}

.report-style-class-usage{
  height: calc(100vh - 0px); //DEV
  width: 100%;

}

.action-btn-wrapper{
  width: 100%;
  display: flex;
  padding: 3px 15px;
  .export-btn {
    border: none;
    border-radius: 5px;
    padding: 3px 10px;
    background: #5199CD;
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    &:not(:first-child) {
      margin-left: 20px;
    }
  }
}


.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background: #3b8bc6;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}
