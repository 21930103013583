.standard-rpt-nav-home-grid-container {
  // padding: 25px;
  min-height: 50px;
  width: 101%;
  overflow: auto;
  // margin-bottom: 20px;
}

// .dQqvnT,
// .dmKetv,
// .hUiCcF {
//   margin: 0px 0px !important;
// }

.cardsListContainer {
  border: 1px solid #dfe0e3;
  background-color: #edeef1;
  color: #000000;
  min-height: 50px;
  overflow: auto;
  padding: 10px;
  overflow: hidden !important;
}
.standard-rpt-nav-home-grid-container::-webkit-scrollbar {
  display: none;
}

.standard-rpt-subsection-div {
  padding-bottom: 20px;
  border-bottom: 1px solid #e4e4f1;
  margin-bottom: 20px;
}
.standard-rpt-subsection-head {
  text-align: left;
  color: #000000;
  font-family: "Montserrat-Medium";
  font-size: 18px;

  font-weight: 500;
  // margin-bottom: 15px;
}

.standard-rpt-subsection-cardhead {
  text-align: left;
  color: #000000;
  font-family: "Montserrat-Medium";
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  margin-bottom: 15px;
}


.standard-rpt-subsection-desc {
  color: #000000;
  font-family: "Montserrat-Regular";
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

.standard-rpt-subsection-nav {
  font-family: "Montserrat-SemiBold";
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  a {
    color: #3b8bc6;
  }
  a:hover {
    text-decoration: none;
  }
}

.standard-rpt-subsection-last-refresh {
  color: #000;
  font-family: "Montserrat-Medium";
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  float: right;
}

.prd-rpt-subdiv {
  border-radius: 3px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  height: 340px;
  width: 4000px;
  padding: 30px;
  margin-right: 20px;
  margin-bottom: 20px;
  // align-items: center;
  // cursor: pointer;
  position: relative;
}
.prd-rpt-subdiv-lastcard {
  border-radius: 3px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  height: 340px;
  width: 3650px;
  padding: 30px;
  position: relative;
  margin-right: 0px;
  margin-bottom: 0px;
}
.prd-rpt-cardsList {
  border-radius: 3px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  height: 140px;
  width: 95%;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06);
  border-radius: 3px;
  background-color: #ffffff;
  float: left;
  padding: 10px;
  margin-bottom: 15px;
  position: relative;
}
.new-card-icon {
  position: absolute;
  top: 10px;
  right: -15px;
}
.favroites-bookmark {
  position: absolute;
  bottom: 15px;
  right: 15px;
  cursor: pointer;
}
@media only screen and (min-width: 1134px) {
  .prd-rpt-subdiv {
      height: 370px;
  }
}
@media only screen and (min-width: 1280px) {
  .prd-rpt-subdiv {
      height: 320px;
  }
  .prd-rpt-cardsList {
      height: 140px;
  }
  .grid-col-align {
      grid-template-columns: 1fr 1fr 1fr 1fr !important;
  }
}
@media only screen and (min-width: 1380px) {
  .prd-rpt-subdiv {
      height: 360px;
  }
  .prd-rpt-cardsList {
      height: 130px;
  }
}
@media only screen and (min-width: 1420px) {
  .prd-rpt-subdiv {
      height: 310px;
  }
  .prd-rpt-cardsList {
      height: 120px;
  }
}
@media only screen and (min-width: 1470px) {
  .prd-rpt-subdiv {
      height: 340px;
  }
  .prd-rpt-cardsList {
      height: 120px;
  }
}
.prd-rpt-subdiv-txt {
  // color: #fff;
  font-size: 1.2em;
  font-family: "Montserrat-SemiBold";
  font-weight: 500;
  margin-left: 15px;
  margin-top: 10px;
}
.prd-rpt-subdiv-txt-cardsList {
  margin-left: 10px;
  width: 75%;
  float: left;
  text-align: left;
  vertical-align: middle;
  color: #000000;
  font-size: 0.9em;
  font-family: "Montserrat-SemiBold";
  font-weight: 500;
}
.top-margin-10 {
  margin-top: 5px;
}
.prd-rpt-subdiv-desc {
  // color: #fff;
  font-size: 14px;
  font-family: "Montserrat-Regular";
  margin-top: 15px;
  margin-bottom: 15px;
}
.prd-rpt-card-icon {
  vertical-align: top;
  width: 50px;
  height: 50px;
}
.prd-rpt-card-icon-cardsList {
  text-align: left !important;
  float: left;
  vertical-align: top;
  width: 35px;
  height: 35px;
}
.prd-rpt-new-card-icon-cardsList {
  width: 65px;
  height: 30px;
}
.prd-rpt-vrButton-default,
.prd-rpt-vrButton {
  color: black;
  position: absolute;
  bottom: 20px;
  left: 30px;
  // background-color: #ffffff;
}
.prd-rpt-vrButton button {
  width: fit-content;
  padding: 5px 10px;
  color: black;
  background-color: #ffffff;
  cursor: pointer;
  border-radius: 3px;
  border: none;
  content: fit-content;
  font-size: 14px;
  font-family: "Montserrat-Regular";
}
.prd-rpt-vrButton-default button {
  width: fit-content;
  padding: 5px 10px;
  color: #1d679e;
  background-color: #ffffff;
  cursor: pointer;
  border: 1px solid #1d679e !important;
  border-radius: 3px;
  border: none;
  content: fit-content;
  font-size: 14px;
  font-family: "Montserrat-Regular";
}
.prd-rpt-cardsList-vrButton {
  position: absolute;
  bottom: 15px;
  left: 15px;
  width: 100%;
  height: 30px;
  float: left;
  text-align: left;
  vertical-align: bottom;
}
.prd-rpt-cardsList-vrButton button {
  width: fit-content;
  padding: 5px 10px;
  color: #1d679e;
  background-color: #ffffff;
  cursor: pointer;
  border: 1px solid #1d679e;
  border-radius: 3px;
  border: none;
  content: fit-content;
  font-size: 14px;
  font-family: "Montserrat-Regular";
}
.d-grid {
  display: grid;
}

.grid-col-3 {
  grid-template-columns: 1fr 1fr 1fr;
}

.grid-col-align {
  grid-template-columns: 1fr 1fr 1fr;
}

.row-align {
  margin-right: -15px;
  margin-left: -15px;
  width: 100%;
}
.eShlAS,
.ijrtgg {
  min-width: 35px !important;
  width: 35px !important;
  background-color: rgba(255, 255, 255, 0.7) !important;
}
.dmKetv,
.hUiCcF {
  margin-right: 10px !important;
  margin-left: 0px !important;
}
.rec-slider-container {
  margin-right: 10px !important;
  margin-left: 0px !important;
}
.dQqvnT {
  overflow: hidden !important;
  position: relative !important;
  width: 100% !important;
  margin-right: 10px !important;
  margin-left: 0px !important;
}
/* square buttons */
.rec.rec-arrow {
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 0.7);
}
.rec-arrow-left {
  position: absolute;
  // left: 60px;
  left: 2.5rem !important;
  z-index: 100;
}
.rec-arrow-right {
  position: absolute;
  right: 45px;
}
/* round buttons on hover */
.rec.rec-arrow:hover {
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 0.7) !important;
}
/* hide disabled buttons */
.rec.rec-arrow:disabled {
  visibility: hidden;
}
/* disable default outline on focused items */
/* add custom outline on focused items */
.rec-carousel-item:focus {
  outline: none;
  box-shadow: inset 0 0 1px 1px lightgrey;
}
