.confirm-msg-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    top: -2.7vh;
    gap: 3vh;
}

.confirm-msg-title > text:first-child {
    color: #707070;
    font: normal normal normal 1.25vw/2.6vh 'Montserrat-Medium';
}

.confirm-msg-title > text:nth-child(2) {
    color: #707070;
    font: normal normal normal 0.8vw/1.7vh 'Montserrat-Regular';
    padding: 0 12px;
}

.options-yes-no {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 30px;
    position: relative;
    bottom: -1vh;
}

.options-yes-no > button {
    margin: 0 15px 18px 15px;
    border-radius: 3px;
    padding: 5px;
    width: 5.8vw;
}

.options-yes-no > button:first-child {
    border: 1px solid #1d679e;
    background: #1d679e;
    color: #fff;
    
}

.options-yes-no > button:last-child {
    border: 1px solid #1d679e;
    color: #1d679e;
    background-color: #fff;
}
