.layout-sub-header {
    display: flex;
    height: 36px;
    background-color: #ffffff;
    border: 1px solid #e4e4ee;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;
    padding-left: 20px;
    overflow: hidden;
    width: 100%;
}

.layout-sub-header-1 {
    display: flex;
    height: 36px;
    background-color: #ffffff;
    border: 1px solid #e4e4ee;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;
    padding-left: 20px;
    overflow: hidden;
    width: 100%;
}

.title-contaner {
    display: flex;
    flex: 0.55;
    justify-content: flex-end;
}

.header-title {
    color: #000000;
    font-size: 15px;
    font-weight: 600;
    font-family: 'Montserrat-SemiBold';
    margin-bottom: 0px;
}

.info-container {
    display: flex;
    height: 49px;
    background-color: #ffffff;
    border: 10x solid #e8e9ee;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
}

.info-label {
    font-size: 14px;
    color: #000000;
    font-weight: 600;
    font-family: 'Montserrat-SemiBold';
    margin-right: 5px;
    margin-bottom: 0px;
}

.info-value {
    font-size: 14px;
    color: #000000;
    font-weight: 400;
    font-family: 'Montserrat-Regular';
    margin-bottom: 0px;
}

.filter-container {
    display: flex;
    margin-top: 17px;
    // padding-left: 20px;
    // padding-right: 20px;
}

.export-icon {
    margin-right: 20px;
}

.df-report-name-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 12px 13px;
}

.df-report-name-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.df-report-name {
    font: normal normal normal 1.45vw/3.1vh 'Montserrat-SemiBold';
    color: #000;
}

.df-tab-separator {
    width: 1px;
    background-color: #bbb;
    height: 3.4vh;
    margin: 0 1vw;
}
.df-tab-separator-small {
    width: 1px;
    background-color: #bbb;
    height: 2.3vh;
    margin: 0 1vw;
}

.df-table-name-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.df-table-name-style {
    font: normal normal normal 1.04vw/2.2vh 'Montserrat-Medium';
}

.df-table-name-style-actual {
    font: normal normal normal 1.04vw/2.2vh 'Montserrat-Regular';
    padding-left: 5px;
}

.df-report-extra-info-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.df-report-date-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
}

.df-report-date-text {
    font: normal normal normal 0.8vw/1.7vh 'Montserrat-Regular';
}

.df-report-date-text-two {
    font: normal normal normal 1.04vw/1.7vh 'Montserrat-Regular';
}

// .df-member-text {
//     font: normal normal 600 20px 'Montserrat-SemiBold';
// }

.df-report-date-last-refreshed {
    font: normal normal normal 0.8vw/1.7vh 'Montserrat-Medium';
    padding: 5px 0;
    border-radius: 5px;
}

.df-report-member-count {
    font: normal normal normal 0.8vw/1.7vh 'Montserrat-Medium';
    background: #fff;
    padding: 5px;
    border: 1px solid #e6e6e6;
    border-radius: 5px;
}
