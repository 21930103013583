.overlay-loader {
    height: 100%;
    width: 100%;
    background: #ccc;
    opacity: 0.5;
    z-index: 99;
    position: relative;
}

.group-details-wrapper {
    z-index: 99;
    width: 30vw;
    min-height: 66.2vh;
    background: #fff;
    box-shadow: 0px 0px 30px #00000029;
    border-radius: 5px 0 5px 5px;
    display: flex;
    flex-direction: column;
    padding: 12px;
    gap: 1vh;
    min-width: 400px;
}

.grouplist-header {
    display: flex;
    flex-direction: row;
    box-shadow: 0px 0px 5px #00000029;
    background: #e6f5ff;
    /* min-height: ; */
    padding: 1.2vh 1.04vw;
}

.group-table-wrapper {
    position: relative;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    border: 1px solid #1d679e;
    height: 50vh;
    overflow: auto;
    padding-bottom: 4.5vh;
    /* align-items: center;
    justify-content: center; */
}

.group-details-wrapper > .close-group-details-btn {
    margin-top: 1rem;
    min-width: 80px;
    align-self: center;
    border: none;
    background: #1d679e;
    padding: 1vh 1vw;
    color: #fff;
    font: normal normal normal 1.04vw/2.2vh 'Montserrat-Regular';
    border-radius: 5px;
}
