.vertical-stack {
    padding: 16px;
}
#icon-display{
display: flex;
align-items: baseline;
}
#border {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #00000029;
    border: 1px solid #5199CD;
    border-radius: 5px;
    padding: 1vw;
    opacity: 1;
    display: flex;
    max-width: flex;
}
.demoarrow{
    position: relative;
    max-width: 1.8vw;
}
.arrow{
    max-width: 1.8vw;

    position: relative;
}
#noborder {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #00000029;
    border: 1px solid #fff;
    border-radius: 5px;
    padding: 1vw;
    opacity: 1;
    display: flex;
    max-width: flex;
    }

.vertical-first-title {
    font: normal normal normal .9vw/1.5vh 'Montserrat-Medium';
    margin-bottom: 0.92vh;
}

.vertical-last-title {
    font: normal normal normal 0.6vw/1vh 'Montserrat-Medium';
    color: #707070;
    margin-top:5px;
}

.procedure-first-title {
    font: normal normal normal 1vw/2vh 'Montserrat-Medium';
    margin-bottom: 0.92vh;
}

.procedure-last-title {
    font: normal normal normal 0.62vw/1.38vh 'Montserrat-Medium';
    color: #707070;
    margin-top:5px;
}

.ageBandContainer {
    display: flex;
    gap: 1rem;
    width: 18.75vw;
    min-height: 38px;
}

.ageInputWrapper {
    flex: 1;
    border: 1px solid #bbb;
    border-radius: 5px;
    padding: 2px 8px;
    position: relative;
    height: 4.5vh;
}

.ageInput {
    width: 100%;
    height: 100%;
    border: none;
    font-size: 12px;
}

.ageInput::placeholder {
    color: #aaa;
    font-size: 12px;
}

/* Remove increase and decrease buttons fron the age input fields */
.ageInput::-webkit-inner-spin-button,
.ageInput::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

 .ageInput::-webkit-inner-spin-button,
.ageInput::-webkit-outer-spin-button,
.ageInput::-moz-inner-spin-button,
.ageInput::-moz-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    margin: 0;
}

.numberChangeConsole {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 5%;
}

.numberBtn {
    height: 10px;
    width: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.numberBtnIcon {
    height: 100%;
    width: 100%;
    color: #aaa;
}

.hintAgeBand {
    font: normal normal normal 0.62vw/1.38vh 'Montserrat-Medium';
    color: #707070;
    margin-top: 0.25vh;
}


