.accordian-item {
    height: 100%;
    width: 100%;
    position: relative;
}
.accordian-item button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50%;
    width: 100%;
    padding: 5px 10px;
    text-align: left;
    cursor: pointer;
    background-color: #5199cd;
    color: white;
    border-top: 2px solid white;
    border-left: 0px solid white;
    border-right: 0px solid white;
    border-bottom: 0px solid white;
    font-size: 0.83vw;
}

.accordian-item div {
    /* display: inline; */
    font-size: 18px;
    background-color: #e6f5ff;
}


/* .accordian-item > div { */
    /* text-align: left !important;
    display: block !important;
    height: 100% !important; */
    /* box-sizing: border-box !important;
    overflow: auto !important; */
    /* will-change: height;
    transition: height 224ms cubic-bezier(0.4, 0, 0.2, 1) 0s; */
    /* height: 100% !important; */
/* } */

.accordian-item .accordian-details {
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: auto;
}
.accordian-item .height-of-expanded-block {
    /* height: 200px !important; */
    max-height: 200px !important;
}
.accordian-item .height-of-collapsed-block {
    height: 0px !important;
}
.accordian-item .accordian-details::-webkit-scrollbar {
    width: 4px;
    background-color: #f2f0f0;
}
.accordian-item .accordian-details::-webkit-scrollbar-thumb {
    background-color: #ccc;
}
.accordian-heading {
    margin: 0px;
    height: fit-content;
    text-align: left;
    font-size: 14px !important;
    /* background-color: #fff ; */
}
.accordian-heading .count {
    padding: 0px;
    /* border: 1px solid #5199cd; */
    /* border-radius: 150px; */
    /* float: right; */
    margin: 0px;
    /* height: 18px; */
    /* width: 18px; */
    text-align: center !important;
    font-size: 11px !important;
    font-weight: 600 !important;
    /* background-color: white; */
}
.accordian-heading > p {
    margin: 0px ;
    height: fit-content;
    padding: 5px 10px;
    color: black;
    font-size: 13px;
}
.accordian-heading > p > span {
    margin: 0px !important;
    height: fit-content;
    padding: 5px 10px;
    color: #5199cd;
}
.accordian-heading .accordian-desc {
    padding: 5px 10px;
    background-color: white !important;
    font-size: 12px !important;
}
.accordian-heading .accordian-desc-count {
    /* padding: 5px 10px; */
    font-size: 12px !important;
}
.accordian-heading .accordian-desc-v2 {
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: white !important;
    font-size: 12px !important;
    margin-bottom: 0px;
}
.accordian-heading .accordian-desc p {
    margin: 0px !important;
    font-size: 12px !important;
    color: #707070;
}
