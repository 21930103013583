.insides-studio-view-reports {
    font-family: 'Montserrat-Regular';
    padding: 40px 50px;
}
.is-header-section {
    display: flex;
    /* float: left; */
    /* flex-grow: 3; */
    justify-content: space-between;
    /* align-content: left; */
    /* align-items: left; */
    /* width: 100%; */
    /* margin-bottom: 20px; */
}
.header-information-bar {
    /* display: flex; */
    float: left;
    width: 100%;
    margin-bottom: 20px;
    font: normal normal 600 1.25rem/2.5vh 'Montserrat-Regular';
}
.is-header-content {
    display: flex;
    padding-bottom: 100px;
    gap: 1rem;
    /* float: left; */
    width: 100%;
}
.is-header-footer {
    width: 100%;
}
.align-left {
    /* align-items: flex-start; */
    /* width: 18vh; */
    /* float: left;  */
    width: 50%;
}
.align-right {
    /* align-items: flex-end;
    align-content: flex-end;
    justify-content: flex-end;
    justify-items: flex-end; */
    float: right;
    /* width: 50%; */
    /* vertical-align: middle; */
    text-align: right;
}
.is-header-section .align-left .is-title {
    font: normal normal normal 1.56vw/3.18vh 'Montserrat-Medium';
    /* font-size: 3.18vh; */
    font-weight: 600;
    color: black;
}
.is-header-section .align-left .seperator {
    font: normal normal normal 1.56vw/3.18vh 'Montserrat-Medium';

    /* font-size: 25px; */
    color: rgb(87, 87, 87);
    margin-left: 10px;
    margin-right: 10px;
}
.is-header-section .align-left .desc {
    font: normal normal normal 1.56vw/3.18vh 'Montserrat-Medium';

    /* font-size: 20px; */
    color: black;
}
.is-header-section .align-left .desc .dateHighlighter {
    /* font-weight: 600;
    color: #000000;
    padding: 6px 10px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #e6e6e6; */
}

.is-header-actions {
    float: right;
    width: 100%;
}
.align-left-actions {
    /* display: flex; */
    float: left;
    width: fit-content;
    /* margin-right: 30px; */
    font-size: 1.85vh;
}
.align-left-actions button {
    box-shadow: 0px 0px 10px #00000029;
    font-weight: 600;
    letter-spacing: 0.6px;
    border-radius: 5px;
    cursor: pointer;
}
.align-left-actions .action1 {
    width: 5vw;
    height: 5vh;
    color: #1d679e;
    background-color: white;
    border: 2px solid #216ba1;
}
.align-left-actions .action2 {
    width: 22.5vh;
    height: 4.64vh;
    color: white;
    background-color: #1d679e;
    border: none;
}
.is-reports-filters {
    /* display: flex;
    flex-direction: column; */
    /* float: left; */
    border: 1px solid#1d679e;
    width: 100%;
    /* min-height: 65vh; */
    height: 100%;
    opacity: 1;
    margin-right: 2%;
    overflow-y: scroll;
}
.is-reports-filters::-webkit-scrollbar {
    width: 0px;
    height: 10px;
}
.is-reports-filters::-webkit-scrollbar-track {
    background: #f1f1f1;
}
.is-reports-filters::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #c0c0c0;
}
.is-reports-filters-title {
    flex-direction: column;
    /* padding-left: 5px; */
    height: fit-content;
    width: 100%;
    border-bottom: 1px solid #1d679e;
    background-color: white;
    border-collapse: collapse;
    color: #1d679e;
    position: sticky;
    top: 0px;
    display: block;
    z-index: 1;
}
.is-reports-filters-title p {
    height: fit-content;
    width: 100%;
    padding: 5px;
    text-align: left;
    margin: 0px;
}
.is-reports-filters-title p span {
    margin-left: 7px;
    font-weight: 600;
    /* font: normal normal 600 1.25rem/2.5vh "Montserrat-Regular"; */
}
.is-reports-filters-description {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
}

.is-analysis-table {
    box-shadow: 0px 0px 10px #00000029;
    background-color: white;
    /* float: right; */
    border-radius: 10px;
    width: 78%;
    min-height: 55vh;
    opacity: 1;
    /* flex: 3; */
}
.is-analysis-table-name {
    display: flex;
    width: fit-content;
    float: left;
    font-weight: 600;
    position: relative;
    color: #2c333e;
    margin-top: 16px;
    margin-left: 20px;
    /* background-color:rgb(20, 95, 95); */
}
.is-analysis-table-name p {
    margin-bottom: 0px !important;
    font: normal normal 600 1.25rem/2.5vh 'Montserrat-Regular';
    /* background-color:aqua; */
}
.is-analysis-table-name span {
    position: absolute;
    right: -25px;
    top: -15px;
    /* background-color:aquamarine; */
}
.is-table-attributes {
    float: left;
    /* width: fit-content; */
    /* margin-right: 30px; */
    font-size: 1.85vh;

    /* height: 8vh; */
    width: 100%;
    padding: 10px;
    /* float: left; */
}
.is-table-dropdown-option,
.is-table-exports-dropdown {
    width: fit-content;
    float: right;
    vertical-align: center;
    position: relative;
    font-size: 1.85vh;
}
.is-table-exports-dropdown {
    margin-top: 6px;
    margin-right: 0px;
}
.is-table-dropdown-option {
    margin-top: 0px;
    margin-right: 30px;
}
.is-table-exports-dropdown .arrow {
    padding: 15px;
    position: absolute;
    background-color: #1d679e;
    -ms-transform: rotate(45deg); /* IE 9 */
    -ms-transform-origin: 20% 40%; /* IE 9 */
    transform: rotate(45deg);
    transform-origin: 20% 40%;
    right: -5px;
}
.is-table-exports-dropdown .dropdown-list {
    position: absolute;
    z-index: 100;
    top: 40px;
    right: -30px;
    width: 180px;
    height: fit-content;
    text-align: left;
    border: 1px solid #1d679e;
    border-radius: 10px;
    background-color: white;
}
.is-table-exports-dropdown .dropdown-list p,
.is-table-exports-dropdown .dropdown-list a {
    width: 180px;
    height: fit-content;
    margin: 0px;
    padding: 5px;
    font-size: 15px;
    font-weight: 500;
    color: #1d679e;
    cursor: pointer;
}
.is-table-exports-dropdown .dropdown-list p:hover,
.is-table-exports-dropdown .dropdown-list a:hover {
    background-color: #e6f5ff;
    width: 99%;
}
.is-table-exports-dropdown .display-block {
    display: block;
}
.is-table-exports-dropdown .display-none {
    display: none;
}
.is-table-exports-dropdown .dropdown-list span {
    margin-right: 15px;
    margin-left: 10px;
}
.is-table-exports-dropdown span {
    cursor: pointer;
}
.is-table-exports-dropdown .dropbtn {
    cursor: pointer;
    width: 30px;
    font-size: 1.85vh;
}
.is-table-dropdown-option label {
    margin-right: 10px;
    font-size: 1.8vh/1.2vw;
}
.is-table-dropdown-option select {
    text-align: left;
    padding: 5px 15px;
    font-size: 1.8vh/1.2vw;
    padding-right: 25px;
    border: none;
    cursor: pointer;
    border: 1px solid #bbbbbb;
    border-radius: 5px;
}
.is-table-dropdown-option select option {
    background-color: #f6f7fb;
    border-bottom: 1px solid black;
}
.is-header-footer {
    float: left;
    width: 100%;
    margin-top: 20px;
    border: 1px solid #1d679e;
    border-radius: 5px;
    background-color: white;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    text-align: center;
    position: relative;
}
.is-header-footer > div {
    float: left;
    text-align: center;
    vertical-align: middle;
    font-size: 13px;
    color: #707070;
    margin: 10px auto;
}
.is-header-footer > div > div {
    float: left;
    width: fit-content;
    margin-right: 10px;
}
.is-header-footer div p {
    margin: 0px;
    float: left;
}
.is-header-footer .content-seprator {
    position: absolute;
    color: #1d679e;
    font-size: 22px;
}
/* .is-table-dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    width: 100%;
    overflow: auto;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}
.is-table-dropdown-content option {
    color: black;
    padding: 6px 10px;
    text-align: center;
    text-decoration: none;
    display: block;
} */

.edit-analysis-name-background {
    position: 'fixed';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    padding: auto;
    height: 100%;
    width: 100%;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.6);
}
.edit-analysis-name-background > div {
    z-index: 999;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 45%;
    height: fit-content;
    padding: 1.2rem;
    background-color: white;
    border-radius: 7px;
}
.edit-analysis-name-background > div > .edit-analysis-cancel-button {
    z-index: 999;
    position: absolute;
    top: 0px;
    right: 100px;
    /* width: 40%; */
    height: fit-content;
    background-color: white;
    border-radius: 10px;
}
.edit-analysis-name-background > div > p {
    float: left;
    text-align: left;
    margin: 0px;
    width: fit-content;
    /* font-size: 1rem;
    font-weight: 600; */

    letter-spacing: 0.72px;
}
.edit-analysis-name-background > div > p > span {
    float: left;
    width: fit-content;
    text-align: left;
    margin-top: 2vh;
    /* font-size: 1rem;
    font-weight: 600;
    letter-spacing: 0.72px; */
    color: #000000;
    opacity: 1;
    margin-right: 30px;
    font: normal normal 600 1.25vw/2.5vh 'Montserrat-Regular';
}
.edit-analysis-name-background > div input {
    float: left;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #bbbbbb;
    border-radius: 10px;
    opacity: 1;
    margin-right: 30px;
    margin-top: 1vh;
    padding: 5px;
    /* width: 20vw; */
}
.edit-analysis-name-background > div button {
    float: left;
    padding: 5px 32px;
    /* font-weight: 500;
    font-size: 20px; */
    background-color: #1d679e;
    color: white;
    box-shadow: 0px 0px 10px #00000029;
    border: 0px;
    border-radius: 5px;
    margin-top: 1vh;
    cursor: pointer;
}
.edit-analysis-name-background .edit-analysis-cancel-button {
    position: absolute;
    right: 0px;
    top: 0px;
    width: fit-content;
    cursor: pointer;
}

.res-analysis-desc {
    text-overflow: ellipsis;
    /* Needed to make it work */
    overflow: hidden;
    white-space: nowrap;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.popup-heading {
    text-align: left;
    font: normal normal 600 20px/24px 'Montserrat-Regular';
    letter-spacing: 0.6px;
    opacity: 1;
    margin-bottom: 15px;
    margin-top: 2vh;
}

.res-input-box-analysis {
    font: normal normal normal 20px/24px 'Montserrat-Regular';
}

.rte-style {
    max-height: 45vh;
    word-wrap: break-word;
    word-break: break-all;
    font-style: 'Montserrat-Regular';
    /* display: flex; */
    /* flex-direction: row; */
    width: 100%;
    white-space: normal;
    display: 'flex';
    align-items: 'flex-start';
    /* flex-grow: 1; */
    /* width: '100%'; */
    /* border-top-color: #bbbbbb;
    border-right-color: #bbbbbb;
    border-left-color: #bbbbbb;
    border-radius: 5px;
    border-bottom-width: 0px;
    border-top-width: 1px;
    border-right-width: 1px;
    border-left-width: 1px;
    border-top-style: solid;
    border-right-style: solid;
    border-left-style: solid;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px; */
    /* padding: 8px; */
}
/* .RichTextEditor__root___2QXK- div { */
/* border-bottom-width:0px ; */
/* background-color: bisque; */
/* width: 100%; */
/* min-width: 1.7vw;
    max-width: 60vw; */
/* display: flex; */
/* flex-grow: 1; */

/* justify-content: center; */
/* } */
.desc-display {
    /* font-family: 'Montserrat-Regular'; */
    font-family: 'Montserrat-Regular';
    text-align: left;
    /* text-overflow: ellipsis; */
    /* word-break: break-all; */
    /* word-wrap: break-word; */
    overflow: hidden;
    /* margin-left: 1.5vw; */
    color: #707070;
    /* height: fit-content; */
    /* position: relative; */
    /* max-width: 65vw; */
    /* white-space: pre-wrap; */
}

.desc-vertical-orient {
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.desc-line-clamp {
    -webkit-line-clamp: 2;
}
#desc-font {
    font-family: 'Montserrat-Regular';
}

.view-more {
    font-family: 'Montserrat-Regular';
    font-size: 1vw;
    /* margin-top: 20vw; */
    /* text-align: right; */
    /* margin-left: -10vw; */
    margin-top: 1.5vw;
    display: inline-block;
    background-color: #fff;
    white-space: nowrap;
    margin-right: 2vw;
}

.paragraph-editor {
    margin: 0;
    padding: 0;
    white-space: pre-wrap;
    word-wrap: break-word;
}

/* .desc-display::after {
    content: 'View More';
} */
