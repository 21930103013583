.clickable-link-cell {
    color: #3b8bc6;
}

.clickable-link-cell:hover {
    font-weight: 600;
    color: #3b8bc6;
    text-decoration: none;
}

.row-tooltip-wrapper {
    position: relative;
    z-index: 99999;
}

.row-tooltip {
    position: absolute;
    bottom: 135%;
    background: #3d4551;
    color: #fff;
    border-radius: 3px;
    width: 534px;
    right: -50px;
    padding: 15px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    border: 1px solid #e4e4f1;

    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
}

.row-tooltip:after {
    position: absolute;
    content: "";

    top: 100%;
    right: 47px;
    margin-bottom: 15px;
    border-top: 18px solid #3d4551;
    border-bottom: 12px solid transparent;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
}
