.main-container {
    display: flex;
    overflow-y: hidden;
}

.vendor-logo {
    background-color: #ffffff;
    margin-left: 0.6em;
    margin-right: 0.5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-radius: 0.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    /* flex: 1; */
    min-width: 24.5rem;
    /* width:1rem; */
}
.company-sub-logo {
    height: 47px;
    /* background-color: #00ff00; */
}
.second-container {
    display: flex;
    flex-direction: row;
    min-width: 60%;
    margin-left: 0.6rem;
    border-radius: 0.3rem;
}
.contact-details {
    background-color: #edeef1;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    padding-bottom: 1rem;
    margin-bottom: 2rem;
    margin-right: 0.6rem;
    flex: 0.94;
    min-width: 24.5rem;
}
.sub-header-custom {
    font-weight: bold;
    display: flex;
    align-self: flex-start;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    border-radius: 0.3rem;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    margin-left: 0.5rem;
    /* padding-left: 0.5rem; */
}
.sub-header-custom-name {
    font-weight: bold;
    display: flex;
    align-self: flex-start;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    border-radius: 0.3rem;
}
.second-right-container {
    flex: 3;
    border-radius: 0.3rem;
    margin-right: 0.5rem;
}

.accordion-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    background-color: #ffffff;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    border-radius: 0.3rem;
    /* margin-left: 0.5rem; */
}

.accordion-title:hover {
    background-color: #ffffff;
}

.accordion-content {
    background-color: #ffffff;
    /* background-color: #743737; */
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    overflow-wrap: anywhere;
    text-align: left;
}

.accordion-sub-content {
    display: flex;
    justify-content: left;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    padding-bottom: 0.5rem;
}

.vendor-contact-txt {
    margin-left: 0.5rem;
    padding-left: 0.5rem;
    background-color: #ffffff;
    /* overflow-wrap:; */
}

.accordian-arrow {
    margin-top: 0.5rem;
    color: #000;
}

.layout-container {
    background-color: #edeef1;
    border-radius: 0.3rem;
    display: flex;
    flex-direction: column;
    margin-bottom: 5vh;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.5rem;
}

.main-body {
    display: flex;
    flex-direction: row;
    padding: 0.5rem;
    /* width: 90%; */
}

.header-status {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    margin-right: 1rem;
    margin-left: 1rem;
}

.layout-card-container {
    background-color: #edeef1;
    /* background-color: #194ff0; */
    border-radius: 0.3rem;
    display: flex;
    flex-direction: column;
    width: 100%;
}
.layout-sub-container {
    /* position: relative; */
    /* background-color: #aa5454; */
    background-color: #ffffff;
    border-radius: 0.3rem;
    padding: 0.5rem;
    margin: 0.5rem;
    /* height:11rem */
}
.layout-sub-container-header {
    height: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 1rem;
}
.layout-sub-container-header-content1 {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0.5rem;
    margin-top: 0.5rem;
    /* margin-bottom: 0.5rem; */
    background: #1d679e;
}
.layout-sub-container-header-content2 {
    width: 0.7rem;
    height: 0.7rem;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
}
.red {
    background-color: #f00;
}
.green {
    background-color: #00ff00;
}
.yellow {
    background-color: yellow;
}

.layout-sub-container-header-content3 {
    padding-left: 0.6rem;
    font-weight: bold;
    padding-top: 0.5rem;
    color: #1d679e;
    font: normal normal normal 17px 'Montserrat-Medium';
}

.layout-sub-container-keys {
    font-weight: bold;
    margin-bottom: 15%;
    text-align: left;
}

.status {
    width: 0.7rem;
    height: 0.7rem;
    border-radius: 50%;
    margin-right: 0.5rem;
}

.processed {
    background-color: #00ff00;
}
.awaited {
    background-color: lightskyblue;
}
.delayed {
    background-color: #ffa800;
}
.process-pending {
    background-color: #000;
}
.QA {
    background-color: #ff0000;
}
.layout-sub-container-body {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    color: #000;
    /* background-color: khaki; */
    padding-bottom: 0;
}

.layout-sub-container-body-content1 {
    list-style: none;
    text-align: 'left';
    padding-left: 1.3rem;
    padding-right: 1rem;
    margin-top: 1.5rem;
    /* background-color: #ffa800; */
    /* display: flex; */
}
.layout-sub-container-body-row {
    display: flex;
    /* justify-content:flex-start; */
    /* background-color: #dbb568; */
    /* margin-left: -0.5rem; */
    margin-top: 0.5rem;
}

/* 

.layout-sub-container-body-content1 {
    list-style: none;
    text-align: 'left';
    padding-left: 0.3rem;
    margin-top: 1.5rem;

} */

.layout-sub-container-body-content1 .layout-sub-container-body-list::before {
    content: '\00b7';
    /* color: #1d679e; */
    font-weight: bold;
    scale: 2.5;
    /* font-size: 1rem; */
    display: inline-block;
    width: 0.5em;
    /* height:-1.5rem */
    padding-bottom: 0.3rem;
}
.layout-sub-container-body-list {
    text-align: left;
    position: relative;
    padding-left: -15rem;
}

.layout-sub-container-body-content2 {
    /* text-align: left; */
    font-size: 13px;
    /* padding-right: 1rem; */
    color: #000;
    padding-left: 0.5rem;
    /* padding-bottom: 0.5rem; */
    /* background-color: bisque; */
    overflow-wrap: break-word;
}

.view-modules-btn {
    /* position: relative; */
    bottom: 0;
    min-width: 5rem;
    /* align-self: flex-end; */
    border: 0;
    background: rgba(29, 103, 158, 0.1);
    font: normal normal normal 0.87rem/1.06rem 'Montserrat-Medium';
    color: #1d679e;
    font-weight: bold;
    border-radius: 3px;
    padding: 5px;
}
.view-modules-btn-div {
    display: flex;
    justify-content: flex-end;
    /* align-items: flex-end; */
    /* margin-top: 1rem; */
}

.about-header {
    color: #000000;
    font-family: 'Montserrat-Medium';
    font-size: 17px;
    line-height: 22px;
    font-weight: 500;
    padding-bottom: 14px;
    text-align: left;
    /* padding-left: 1rem; */
}
.about-desc {
    color: #000000;
    font-family: 'Montserrat-Regular';
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    text-align: left;
    word-wrap: normal;
    /* padding-left: 1rem; */
}
